
.navbar-brand img {
	max-height: 3.375rem;
}

p {
	color:#000;
}


.home-banner {
	background-position: left;
    background-size: cover;
    height: 650px;
    margin: 0;
    padding: 0;
    border: 0;
    align-items: center;
	display: flex;
	justify-content: center;
	align-self: center;
	overflow: hidden;

	h1 {
		font-family: 'Poppins', sans-serif;
		font-weight: 900 !important;
		text-shadow: 0px 14px 10px rgba(0,0,0,0.15);
		line-height: 75px;
		font-size: 4.5em;
	}

	.fintech {
		color: green;
	}

	.lead {
		color: #fff;
		font-weight: 400;
		font-size: 18px;
	}

	a.banner-cta {
		background-image: linear-gradi;
		background-image: linear-gradient(90deg,#0a9b0a 0%,#0ad30a 100%)!important;
		border-radius: 30px;
		padding: 15px 41px;
		font-family: 'Poppins', sans-serif;
	}		
}

a.cta {
  background-image: linear-gradi;
  background-image: linear-gradient(90deg,#0a9b0a 0%,#0ad30a 100%)!important;
  border-radius: 30px;
  padding: 15px 41px;
  font-family: 'Poppins', sans-serif;
}

.circle-image {
	width: 100%;
    border-radius: 50%;
    min-width: 300px;
    margin-left: 14px;
    margin-right: 15px;
	align-self: center;

	img {
		border-radius: 50%;
		height: 300px;
		width: 300px;
	}
}

.home-content {
	h2 {
		font-family: 'Poppins', sans-serif;
		font-weight: 900;
		font-size: 4em;
	}

	p {
		font-family: 'Poppins', sans-serif;
		font-weight: 400;
		font-size: 18px;
		line-height: 40px;
		margin-right: 32px;
	}
}

.improve-section {
	background-image: url(../images/improve-banner.jpg);
    background-position: center center !important;
    background-size: cover !important;
    height: 50vh;
    margin: 0 auto;
    border: 0;
    align-items: center;
    text-align: center;
    background-blend-mode: color-dodge;
	background-attachment: fixed;

	h2 {
		color:#fff;
		font-family: 'Poppins', sans-serif;
    	font-weight: 400;
	}

	.button.alt  {
		margin-top: 31px;

		 a {
			background: green;
			border: 0;
			border-radius: 30px;
			color: #fff;
			font-family: 'Poppins', sans-serif;
		 }
	}
}


/* Navigation */
.main-navigation {
	
	.nav-link {
		border-top: 0;
		border-bottom: 0;	
		text-align: left;
	}


}
.header + section {
    padding-top: 12.5rem;
}

.image {
	background-size: cover;
    background-position: center center;
	z-index: initial;

	[class*="-overlay"]::after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		content: "";
		background: rgba(0, 0, 0, 0.55);
	}
}

.page-title {
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
}


body > section, body > footer {
    position: relative;
    margin: 0 auto;
    padding: 6.25rem 0;
    overflow: hidden;
}

#mainNav.alt {
    position: fixed;
    top: 0;
    padding: 0.5em 1em;
    background-color:#151515;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    z-index: 10000;
}

.main-nav {
	 li {
		margin:0 32px;

		a {
			padding: 0px 22px !important;
		}

		a:hover {
			border-radius: 30px !important;
			border:0 !important;
		}

		a.active {
			background:green;
			border:0;
		}
	 }
}

/* About Us */
.our-approach {
	h1 {
		font-family: 'Poppins', sans-serif;
    	font-weight: 900;
		font-size: 2em;
		color:#000;
	}
	p{
		font-family: 'Poppins', sans-serif;
		font-weight: 400;
		font-size: 18px;
		line-height: 40px;
		margin-right: 32px;
	}
}

/* Our Team */
.our-team-bg {
	background-image: url(../images/our-team-bg.jpg);
    background-position: center !important;
    background-size: cover !important;
	display: flex;
    margin: 0 auto;
    padding: 70px;
    border: 0;
    align-items: center;
    text-align: center;
    background-blend-mode: color-dodge;
	background-attachment: fixed;
	position: relative;
}

.our-team-bg:after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	content: "";
	background: rgba(0, 0, 0, 0.55);
}

.our-team-section {
	z-index: 2;
	h2 {
		font-family: 'Poppins', sans-serif;
		color:#fff;
		font-weight: 900;
		font-size: 3.5em;
	}
	h4 {
		color:#fff;
		font-family: 'Poppins', sans-serif;
		font-weight: 600;
	}

	p {
		color:#fff;
		font-family: 'Poppins', sans-serif;
		font-weight: 400;
		font-size: 18px;
		line-height: 40px;
	}
	
}

.our-mission {
	 h1 {
		font-family: 'Poppins', sans-serif;
		font-weight: 900;
		font-size: 2em;
	 }

	 p {
		font-family: 'Poppins', sans-serif;
		font-weight: 400;
		font-size: 18px;
		line-height: 40px;
	 }
}

/* Our Values */
.our-values {

	.card {
		min-height: 477px;
	}

	.card-title {
		font-family: 'Poppins', sans-serif;
		font-weight: 600;
		font-size: 1.5em;
	}

	p {
		font-family: 'Poppins', sans-serif;
		font-weight: 400;
		font-size: 16px;
		text-align: left;
	}

	.material-symbols-outlined {
		font-size: 37px;
	}
}

.our-values-heading {
	font-family: 'Poppins', sans-serif;
    font-size: 2.5em;
    font-weight: 900;
    margin-bottom: 28px;
}

/* Service */
.price-card {
	border:0;

	.card-header {
		background:#064CDB;
		color:#fff;
		 
		.card-title {
			color: #fff;
			font-family: 'Poppins', sans-serif;
			font-size: 1.3em;
			font-weight: 600;
		}

		.card-sub-heading {
			color: #fff;
			font-family: 'Poppins', sans-serif;
			font-size: 1em;
			font-weight: 400;
		}
	}

	.card-price {
		font-size: 22px;
		font-family: 'Poppins', sans-serif;
		font-weight: 600;
	}

}

/* Contact Us */
.contact-us-section {
	 h1 {
		font-family: 'Poppins', sans-serif;
		font-weight: 600;
	 }
}

.contact-support {
    margin-bottom: -81px;
}

.contact-us-section {
	 .card-text {
		font-size: 1em;
		font-weight: 400;
		font-family: 'Poppins', sans-serif;
	 }
}

$color_1: white;
$font-family_1: inherit;

@keyframes fly-1 {
	from {
		transform: translateY(0.1em);
	}
	to {
		transform: translateY(-0.1em);
	}
}
.send-message {
	font-family: $font-family_1;
	font-size: 20px;
	background: royalblue;
	color: $color_1;
	padding: 0.7em 1em;
	padding-left: 0.9em;
	display: flex;
	align-items: center;
	border: none;
	border-radius: px;
	overflow: hidden;
	transition: all 0.2s;
	span {
		display: block;
		margin-left: 0.3em;
		transition: all 0.3s ease-in-out;
	}
	svg {
		display: block;
		transform-origin: center center;
		transition: transform 0.3s ease-in-out;
	}
	&:hover {
		.svg-wrapper {
			animation: fly-1 0.6s ease-in-out infinite alternate;
		}
		svg {
			transform: translateX(1.2em) rotate(45deg) scale(1.1);
		}
		span {
			transform: translateX(5em);
		}
	}
	&:active {
		transform: scale(0.95);
	}
}


.accordion-stack {
	-webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    transition-delay: 0s;
    position: relative;
    background: #fff;
	.card {
		-webkit-transition: all 0.2s;
		-moz-transition: all 0.2s;
		transition: all 0.2s;
		-webkit-transition-delay: 0s;
		-moz-transition-delay: 0s;
		transition-delay: 0s;
		position: relative;
		background: #fff;
		
	}
}

.stackede {
	-webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    transition-delay: 0s;
    position: relative;
    background: #fff;

	:before {
		-webkit-transition: all 0.2s;
		-moz-transition: all 0.2s;
		transition: all 0.2s;
		border-radius: 0.25rem;
		opacity: .75;
		content: "";
		position: absolute;
		left: 4px;
		right: 4px;
		bottom: -8px;
		display: block;
		height: 100%;
		background: #fff;
		border: 1px solid #e5e5e5;
		z-index: -1;
	}
    
}

.stacked, .accordion-stack .card {
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    transition-delay: 0s;
    position: relative;
    background: #fff;
    border-radius: 0.25rem;
    border: 1px solid 0;
    background: white;
}

.list-group-minimal {
	.list-group-item {
		margin-bottom: 15px;
		padding: 0;
		background: transparent;
		border: 0;
	}
}

.list-group-item {
	
	:first-child {
		border-top-left-radius: 0.25rem;
    	border-top-right-radius: 0.25rem;
	}
}

.list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
}

.faq-section {

	h2 {
		font-family: 'Poppins', sans-serif;
		font-weight: 600;
	}

	button {
		font-family: 'Poppins', sans-serif;
	}

	.card-body {
		font-family: 'Poppins', sans-serif;
		font-weight: 400;
	}
}


.footer-section {
	a {
		color: rgba(255, 255, 255, 0.7);
		font-size: 13px;
		font-family: 'Poppins', sans-serif;
		font-weight: 400;
	}
}

footer.footer-section {
    padding-top: 6.25rem !important;
    padding-bottom: 0 !important;
}

.bottom-footer {
	background: #181828;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding: 15px;
    color: #fff;
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
}



@keyframes gradient {
	0% {
	  background-position: 0% 50%;
	}
	50% {
	  background-position: 100% 50%;
	}
	100% {
	  background-position: 0% 50%;
	}
  }

@media(max-width:767px){
	.flex-mobile {
		flex-direction: column;
		align-items: center;
		text-align: center;
	}

	.navbar {
		padding:0 15px !important;
	}

	.home-content {
		p {
			text-align: center;
			font-size: 16px;
			line-height: 33px;
			margin-right: 32px;
			margin-bottom: 15px;
		}
	}
	
}

@media(min-width:768px){
	.header-sticky {
		padding:10px;
	}
	
}

@media(min-width:992px){
	.home-banner {
		 h1 {
			line-height: 90px;
			font-size: 5.5em;
		 }
	}

	.navbar-brand img {
		max-height: 4.375rem;
	}

	.nav-link.active {
		background: green !important;
		border-radius: 30px;
	}

	.nav-link {
		padding:0 25px !important;
		font-family: 'Poppins', sans-serif;
		font-weight: 600;
	}

	.nav-link:hover {
		background: green !important;
		border-radius: 30px;
	}

	.price-card {
		
		.card-body {
			ul.list-group.list-group-minimal {
				min-height: 293px;
			}
		}
	}	

	.fq-answer {
		margin-left: 70px;
	}

}

@media(min-width:1600px){

	.navbar-brand img {
		max-height: 5.375rem;
	}
}

@media(min-width:1920px){
	.improve-section {
		height: 30vh;
	}

	.home-banner {
		height: 850px;
	}
}

/* Portrait */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1) {
	.price-card {
		
		.card-body {
			ul.list-group.list-group-minimal {
				min-height: 293px;
			}
		}
	}

	.nav {
		top: 0 !important;
	}

	.circle-image  {
		 img {
			border-radius: 50%;
			height: 300px;
			width: 300px;
		 }
	}
}

/* Portrait */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) { 

	.home-banner {
		  h1 {
			line-height: 44px;
    		font-size: 2.5em;
		  }
	} 


}


/* Portrait */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) { 
	.home-banner {
		h1 {
		  line-height: 44px;
		  font-size: 2.5em;
		}
  } 
}

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) { 
	.home-banner {
		h1 {
		  line-height: 44px;
		  font-size: 2.5em;
		}
  } 
}


/* Portrait */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
	.home-banner {
		h1 {
		  line-height: 44px;
		  font-size: 2.5em;
		}
  } 
}


/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2) {

	.home-banner {
		h1 {
		  line-height: 44px;
		  font-size: 2.5em;
		}
  	} 

}

@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2) {

	.home-banner {
		h1 {
		  line-height: 44px;
		  font-size: 2.5em;
		}
  	} 

}



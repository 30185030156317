@import url("https://fonts.googleapis.com/css?family=Muli:300,400,700|Open+Sans:300,400,600,700|Roboto+Mono");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');


/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #064CDB;
  --indigo: #6610f2;
  --purple: #8A3FF6;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #999999;
  --gray-dark: #151515;
  --gray-light: #f5f5f5;
  --primary: #064CDB;
  --secondary: #999999;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f8f8;
  --dark: #151515;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: 'Poppins', sans-serif;
  --font-family-monospace: "Roboto Mono", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #555555;
  text-align: left;
  background-color: #f8f8f8; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #064CDB;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #043291;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: "Roboto Mono", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
  color: #999999;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 0px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: "Muli", sans-serif;
  font-weight: 400;
  line-height: 1.4;
  color: #000; }

h1, .h1 {
  font-size: 3.125rem; }

h2, .h2 {
  font-size: 2.5rem; }

h3, .h3 {
  font-size: 1.875rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.4; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.4; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.4; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.4; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 0.875rem;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 0.875rem;
  color: #999999; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #f8f8f8;
  border: 1px solid #e5e5e5;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #999999; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #111111;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #111111; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #555555;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 1.875rem;
    vertical-align: top;
    border-top: 1px solid #e5e5e5; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #e5e5e5; }
  .table tbody + tbody {
    border-top: 2px solid #e5e5e5; }

.table-sm th,
.table-sm td {
  padding: 1.25rem; }

.table-bordered {
  border: 1px solid #e5e5e5; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #e5e5e5; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #555555;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b9cdf5; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7ea2ec; }

.table-hover .table-primary:hover {
  background-color: #a3bdf2; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #a3bdf2; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #e2e2e2; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #cacaca; }

.table-hover .table-secondary:hover {
  background-color: #d5d5d5; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #d5d5d5; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfd; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfbfb; }

.table-hover .table-light:hover {
  background-color: #f0f0f0; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #f0f0f0; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #bdbdbd; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #858585; }

.table-hover .table-dark:hover {
  background-color: #b0b0b0; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b0b0b0; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #f8f8f8;
  background-color: #111111;
  border-color: #242424; }

.table .thead-light th {
  color: #555555;
  background-color: #f5f5f5;
  border-color: #e5e5e5; }

.table-dark {
  color: #f8f8f8;
  background-color: #111111; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #242424; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #f8f8f8;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(3.375rem + 2px);
  padding: 0.9375rem 0.9375rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #999999;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e5e5e5;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #999999;
    background-color: #fff;
    border-color: #6697fb;
    outline: 0;
    box-shadow: 0; }
  .form-control::placeholder {
    color: #999999;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #f5f5f5;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #999999;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.9375rem + 1px);
  padding-bottom: calc(0.9375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(1.25rem + 1px);
  padding-bottom: calc(1.25rem + 1px);
  font-size: 1.125rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.625rem + 1px);
  padding-bottom: calc(0.625rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #555555;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(2.5625rem + 2px);
  padding: 0.625rem 0.625rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(4.1875rem + 2px);
  padding: 1.25rem 1.25rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #999999; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: 3.375rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.46875rem);
  background-size: calc(0.75em + 0.9375rem) calc(0.75em + 0.9375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: 3.375rem;
  background-position: top calc(0.375em + 0.46875rem) right calc(0.375em + 0.46875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23151515' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.9375rem) calc(0.75em + 0.9375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: 3.375rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.46875rem);
  background-size: calc(0.75em + 0.9375rem) calc(0.75em + 0.9375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: 3.375rem;
  background-position: top calc(0.375em + 0.46875rem) right calc(0.375em + 0.46875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23151515' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.9375rem) calc(0.75em + 0.9375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #555555;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.9375rem 1.875rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #555555;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0; }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #064CDB;
  border-color: #064CDB; }
  .btn-primary:hover {
    color: #fff;
    background-color: #053fb6;
    border-color: #053ba9; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0 rgba(43, 103, 224, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #064CDB;
    border-color: #064CDB; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #053ba9;
    border-color: #04369d; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(43, 103, 224, 0.5); }

.btn-secondary {
  color: #111111;
  background-color: #999999;
  border-color: #999999; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #868686;
    border-color: gray; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0 rgba(133, 133, 133, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #111111;
    background-color: #999999;
    border-color: #999999; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: gray;
    border-color: #797979; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(133, 133, 133, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0 rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0 rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #111111;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #111111;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0 rgba(219, 167, 9, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #111111;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #111111;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(219, 167, 9, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0 rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #111111;
  background-color: #f8f8f8;
  border-color: #f8f8f8; }
  .btn-light:hover {
    color: #111111;
    background-color: #e5e5e5;
    border-color: #dfdfdf; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0 rgba(213, 213, 213, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #111111;
    background-color: #f8f8f8;
    border-color: #f8f8f8; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #111111;
    background-color: #dfdfdf;
    border-color: #d8d8d8; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(213, 213, 213, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #151515;
  border-color: #151515; }
  .btn-dark:hover {
    color: #fff;
    background-color: #020202;
    border-color: black; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0 rgba(56, 56, 56, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #151515;
    border-color: #151515; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: black;
    border-color: black; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(56, 56, 56, 0.5); }

.btn-outline-primary {
  color: #064CDB;
  border-color: #064CDB; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #064CDB;
    border-color: #064CDB; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0 rgba(6, 76, 219, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #064CDB;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #064CDB;
    border-color: #064CDB; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(6, 76, 219, 0.5); }

.btn-outline-secondary {
  color: #999999;
  border-color: #999999; }
  .btn-outline-secondary:hover {
    color: #111111;
    background-color: #999999;
    border-color: #999999; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0 rgba(153, 153, 153, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #999999;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #111111;
    background-color: #999999;
    border-color: #999999; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(153, 153, 153, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #111111;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #111111;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f8f8;
  border-color: #f8f8f8; }
  .btn-outline-light:hover {
    color: #111111;
    background-color: #f8f8f8;
    border-color: #f8f8f8; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0 rgba(248, 248, 248, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f8f8;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #111111;
    background-color: #f8f8f8;
    border-color: #f8f8f8; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(248, 248, 248, 0.5); }

.btn-outline-dark {
  color: #151515;
  border-color: #151515; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #151515;
    border-color: #151515; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0 rgba(21, 21, 21, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #151515;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #151515;
    border-color: #151515; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(21, 21, 21, 0.5); }

.btn-link {
  font-weight: 400;
  color: #064CDB;
  text-decoration: none; }
  .btn-link:hover {
    color: #043291;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #999999;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 1.25rem 2.5rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.625rem 1.25rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #555555;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #f5f5f5; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #111111;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #040404;
    text-decoration: none;
    background-color: #f8f8f8; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #064CDB; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #999999;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #999999;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #111111; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 1.40625rem;
  padding-left: 1.40625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.875rem;
  padding-left: 1.875rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.9375rem 0.9375rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(4.1875rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 1.25rem 1.25rem;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(2.5625rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.625rem 0.625rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 2.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #064CDB;
    background-color: #064CDB; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #f8f8f8, 0; }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #6697fb; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #97b8fc;
    border-color: #97b8fc; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #999999; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #f5f5f5; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -2.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #e5e5e5;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -2.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #064CDB;
  background-color: #064CDB; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(6, 76, 219, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(6, 76, 219, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(6, 76, 219, 0.5); }

.custom-switch {
  padding-left: 3.25rem; }
  .custom-switch .custom-control-label::before {
    left: -3.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-3.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #e5e5e5;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(6, 76, 219, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(3.375rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #999999;
  vertical-align: middle;
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23151515' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #6697fb;
    outline: 0;
    box-shadow: 0 0 0 0 rgba(102, 151, 251, 0.5); }
    .custom-select:focus::-ms-value {
      color: #999999;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #999999;
    background-color: #f5f5f5; }
  .custom-select::-ms-expand {
    display: none; }

.custom-select-sm {
  height: calc(2.5625rem + 2px);
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  padding-left: 0.625rem;
  font-size: 75%; }

.custom-select-lg {
  height: calc(4.1875rem + 2px);
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  padding-left: 1.25rem;
  font-size: 125%; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(3.375rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(3.375rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #6697fb;
    box-shadow: 0; }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #f5f5f5; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(3.375rem + 2px);
  padding: 0.9375rem 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #999999;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: 3.375rem;
    padding: 0.9375rem 0.9375rem;
    line-height: 1.5;
    color: #999999;
    content: "Browse";
    background-color: #fff;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0);
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #f8f8f8, 0; }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #f8f8f8, 0; }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #f8f8f8, 0; }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #064CDB;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #97b8fc; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #e5e5e5;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #064CDB;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #97b8fc; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #e5e5e5;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
    background-color: #064CDB;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #97b8fc; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #e5e5e5;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #e5e5e5;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #999999;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #e5e5e5; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; 
  }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #f5f5f5 #f5f5f5 #e5e5e5; }
    .nav-tabs .nav-link.disabled {
      color: #999999;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #555555;
    background-color: #f8f8f8;
    border-color: #e5e5e5 #e5e5e5 #f8f8f8; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #064CDB; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.40625rem;
  padding-bottom: 0.40625rem;
  margin-right: 15px;
  font-size: 1.125rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.125rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
	  justify-content: end;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: #fff; }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.75);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.75)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #e5e5e5;
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 30px; }

.card-title {
  margin-bottom: 20px; }

.card-subtitle {
  margin-top: -10px;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 30px; }

.card-header {
  padding: 20px 30px;
  margin-bottom: 0;
  background-color: #fafafa;
  border-bottom: 1px solid #e5e5e5; }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 20px 30px;
  background-color: #fafafa;
  border-top: 1px solid #e5e5e5; }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -15px;
  margin-bottom: -20px;
  margin-left: -15px;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -15px;
  margin-left: -15px; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-top,
          .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-bottom,
          .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-top,
          .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-bottom,
          .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 20px; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion > .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card .card-header {
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #f5f5f5;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #999999;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #999999; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.625rem 0.625rem;
  margin-left: 0;
  line-height: 1.875rem;
  color: #555555;
  background-color: transparent;
  border: 0 solid #e5e5e5; }
  .page-link:hover {
    z-index: 2;
    color: #043291;
    text-decoration: none;
    background-color: #f5f5f5;
    border-color: #e5e5e5; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #064CDB;
  border-color: #064CDB; }

.page-item.disabled .page-link {
  color: #999999;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #e5e5e5; }

.pagination-lg .page-link {
  padding: 0.9375rem 0.9375rem;
  font-size: 1.125rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.3125rem 0.3125rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #064CDB; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #053ba9; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(6, 76, 219, 0.5); }

.badge-secondary {
  color: #111111;
  background-color: #999999; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #111111;
    background-color: gray; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(153, 153, 153, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #111111;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #111111;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #111111;
  background-color: #f8f8f8; }
  a.badge-light:hover, a.badge-light:focus {
    color: #111111;
    background-color: #dfdfdf; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(248, 248, 248, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #151515; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: black; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(21, 21, 21, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #f5f5f5;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 1.875rem 1.875rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 5.25rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1.875rem 1.875rem;
    color: inherit; }

.alert-primary {
  color: #021b4f;
  background-color: #b9cdf5;
  border-color: #91b0ef; }
  .alert-primary hr {
    border-top-color: #7ba0ec; }
  .alert-primary .alert-link {
    color: #010a1d; }

.alert-secondary {
  color: #373737;
  background-color: #e2e2e2;
  border-color: #d2d2d2; }
  .alert-secondary hr {
    border-top-color: #c5c5c5; }
  .alert-secondary .alert-link {
    color: #1e1e1e; }

.alert-success {
  color: #0e3c19;
  background-color: #c3e6cb;
  border-color: #a0d8ad; }
  .alert-success hr {
    border-top-color: #8ed19d; }
  .alert-success .alert-link {
    color: #041308; }

.alert-info {
  color: #083a42;
  background-color: #bee5eb;
  border-color: #99d6e0; }
  .alert-info hr {
    border-top-color: #85ceda; }
  .alert-info .alert-link {
    color: #021215; }

.alert-warning {
  color: #5c4503;
  background-color: #ffeeba;
  border-color: #ffe492; }
  .alert-warning hr {
    border-top-color: #ffde79; }
  .alert-warning .alert-link {
    color: #2b2001; }

.alert-danger {
  color: #4f1319;
  background-color: #f5c6cb;
  border-color: #f0a6ad; }
  .alert-danger hr {
    border-top-color: #ec9099; }
  .alert-danger .alert-link {
    color: #26090c; }

.alert-light {
  color: #595959;
  background-color: #fdfdfd;
  border-color: #fcfcfc; }
  .alert-light hr {
    border-top-color: #efefef; }
  .alert-light .alert-link {
    color: #404040; }

.alert-dark {
  color: #080808;
  background-color: #bdbdbd;
  border-color: #989898; }
  .alert-dark hr {
    border-top-color: #8b8b8b; }
  .alert-dark .alert-link {
    color: black; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #f5f5f5;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #064CDB;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #555555;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #555555;
    text-decoration: none;
    background-color: #f8f8f8; }
  .list-group-item-action:active {
    color: #555555;
    background-color: #f5f5f5; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid #e5e5e5; }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #999999;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #064CDB;
    border-color: #064CDB; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0; }
    .list-group-horizontal .list-group-item:first-child {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal .list-group-item:last-child {
      margin-right: 0;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-sm .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-md .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-lg .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xl .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #032872;
  background-color: #b9cdf5; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #032872;
    background-color: #a3bdf2; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #032872;
    border-color: #032872; }

.list-group-item-secondary {
  color: #505050;
  background-color: #e2e2e2; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #505050;
    background-color: #d5d5d5; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #505050;
    border-color: #505050; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818181;
  background-color: #fdfdfd; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818181;
    background-color: #f0f0f0; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818181;
    border-color: #818181; }

.list-group-item-dark {
  color: #0b0b0b;
  background-color: #bdbdbd; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #0b0b0b;
    background-color: #b0b0b0; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #0b0b0b;
    border-color: #0b0b0b; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #999999;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #f5f5f5;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #f5f5f5;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc((0.5rem + 1px) * -1); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #000;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #555555; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #064CDB !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #053ba9 !important; }

.bg-secondary {
  background-color: #999999 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: gray !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f8f8 !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dfdfdf !important; }

.bg-dark {
  background-color: rgb(12, 18, 28) !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: black !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #e5e5e5 !important; }

.border-top {
  border-top: 1px solid #e5e5e5 !important; }

.border-right {
  border-right: 1px solid #e5e5e5 !important; }

.border-bottom {
  border-bottom: 1px solid #e5e5e5 !important; }

.border-left {
  border-left: 1px solid #e5e5e5 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #064CDB !important; }

.border-secondary {
  border-color: #999999 !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f8f8 !important; }

.border-dark {
  border-color: #151515 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.625rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.625rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.625rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.625rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.625rem !important; }

.m-2 {
  margin: 1.25rem !important; }

.mt-2,
.my-2 {
  margin-top: 1.25rem !important; }

.mr-2,
.mx-2 {
  margin-right: 1.25rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 1.25rem !important; }

.ml-2,
.mx-2 {
  margin-left: 1.25rem !important; }

.m-3 {
  margin: 1.875rem !important; }

.mt-3,
.my-3 {
  margin-top: 1.875rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1.875rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1.875rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1.875rem !important; }

.m-4 {
  margin: 2.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 2.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 2.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 2.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 2.5rem !important; }

.m-5 {
  margin: 3.125rem !important; }

.mt-5,
.my-5 {
  margin-top: 3.125rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3.125rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3.125rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3.125rem !important; }

.m-6 {
  margin: 3.75rem !important; }

.mt-6,
.my-6 {
  margin-top: 3.75rem !important; }

.mr-6,
.mx-6 {
  margin-right: 3.75rem !important; }

.mb-6,
.my-6 {
  margin-bottom: 3.75rem !important; }

.ml-6,
.mx-6 {
  margin-left: 3.75rem !important; }

.m-7 {
  margin: 4.375rem !important; }

.mt-7,
.my-7 {
  margin-top: 4.375rem !important; }

.mr-7,
.mx-7 {
  margin-right: 4.375rem !important; }

.mb-7,
.my-7 {
  margin-bottom: 4.375rem !important; }

.ml-7,
.mx-7 {
  margin-left: 4.375rem !important; }

.m-8 {
  margin: 5rem !important; }

.mt-8,
.my-8 {
  margin-top: 5rem !important; }

.mr-8,
.mx-8 {
  margin-right: 5rem !important; }

.mb-8,
.my-8 {
  margin-bottom: 5rem !important; }

.ml-8,
.mx-8 {
  margin-left: 5rem !important; }

.m-9 {
  margin: 5.625rem !important; }

.mt-9,
.my-9 {
  margin-top: 5.625rem !important; }

.mr-9,
.mx-9 {
  margin-right: 5.625rem !important; }

.mb-9,
.my-9 {
  margin-bottom: 5.625rem !important; }

.ml-9,
.mx-9 {
  margin-left: 5.625rem !important; }

.m-10 {
  margin: 6.25rem !important; }

.mt-10,
.my-10 {
  margin-top: 6.25rem !important; }

.mr-10,
.mx-10 {
  margin-right: 6.25rem !important; }

.mb-10,
.my-10 {
  margin-bottom: 6.25rem !important; }

.ml-10,
.mx-10 {
  margin-left: 6.25rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.625rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.625rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.625rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.625rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.625rem !important; }

.p-2 {
  padding: 1.25rem !important; }

.pt-2,
.py-2 {
  padding-top: 1.25rem !important; }

.pr-2,
.px-2 {
  padding-right: 1.25rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 1.25rem !important; }

.pl-2,
.px-2 {
  padding-left: 1.25rem !important; }

.p-3 {
  padding: 1.875rem !important; }

.pt-3,
.py-3 {
  padding-top: 1.875rem !important; }

.pr-3,
.px-3 {
  padding-right: 1.875rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1.875rem !important; }

.pl-3,
.px-3 {
  padding-left: 1.875rem !important; }

.p-4 {
  padding: 2.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 2.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 2.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 2.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 2.5rem !important; }

.p-5 {
  padding: 3.125rem !important; }

.pt-5,
.py-5 {
  padding-top: 3.125rem !important; }

.pr-5,
.px-5 {
  padding-right: 3.125rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3.125rem !important; }

.pl-5,
.px-5 {
  padding-left: 3.125rem !important; }

.p-6 {
  padding: 3.75rem !important; }

.pt-6,
.py-6 {
  padding-top: 3.75rem !important; }

.pr-6,
.px-6 {
  padding-right: 3.75rem !important; }

.pb-6,
.py-6 {
  padding-bottom: 3.75rem !important; }

.pl-6,
.px-6 {
  padding-left: 3.75rem !important; }

.p-7 {
  padding: 4.375rem !important; }

.pt-7,
.py-7 {
  padding-top: 4.375rem !important; }

.pr-7,
.px-7 {
  padding-right: 4.375rem !important; }

.pb-7,
.py-7 {
  padding-bottom: 4.375rem !important; }

.pl-7,
.px-7 {
  padding-left: 4.375rem !important; }

.p-8 {
  padding: 5rem !important; }

.pt-8,
.py-8 {
  padding-top: 5rem !important; }

.pr-8,
.px-8 {
  padding-right: 5rem !important; }

.pb-8,
.py-8 {
  padding-bottom: 5rem !important; }

.pl-8,
.px-8 {
  padding-left: 5rem !important; }

.p-9 {
  padding: 5.625rem !important; }

.pt-9,
.py-9 {
  padding-top: 5.625rem !important; }

.pr-9,
.px-9 {
  padding-right: 5.625rem !important; }

.pb-9,
.py-9 {
  padding-bottom: 5.625rem !important; }

.pl-9,
.px-9 {
  padding-left: 5.625rem !important; }

.p-10 {
  padding: 6.25rem !important; }

.pt-10,
.py-10 {
  padding-top: 6.25rem !important; }

.pr-10,
.px-10 {
  padding-right: 6.25rem !important; }

.pb-10,
.py-10 {
  padding-bottom: 6.25rem !important; }

.pl-10,
.px-10 {
  padding-left: 6.25rem !important; }

.m-n1 {
  margin: -0.625rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.625rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.625rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.625rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.625rem !important; }

.m-n2 {
  margin: -1.25rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -1.25rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -1.25rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -1.25rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -1.25rem !important; }

.m-n3 {
  margin: -1.875rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1.875rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1.875rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1.875rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1.875rem !important; }

.m-n4 {
  margin: -2.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -2.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -2.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -2.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -2.5rem !important; }

.m-n5 {
  margin: -3.125rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3.125rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3.125rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3.125rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3.125rem !important; }

.m-n6 {
  margin: -3.75rem !important; }

.mt-n6,
.my-n6 {
  margin-top: -3.75rem !important; }

.mr-n6,
.mx-n6 {
  margin-right: -3.75rem !important; }

.mb-n6,
.my-n6 {
  margin-bottom: -3.75rem !important; }

.ml-n6,
.mx-n6 {
  margin-left: -3.75rem !important; }

.m-n7 {
  margin: -4.375rem !important; }

.mt-n7,
.my-n7 {
  margin-top: -4.375rem !important; }

.mr-n7,
.mx-n7 {
  margin-right: -4.375rem !important; }

.mb-n7,
.my-n7 {
  margin-bottom: -4.375rem !important; }

.ml-n7,
.mx-n7 {
  margin-left: -4.375rem !important; }

.m-n8 {
  margin: -5rem !important; }

.mt-n8,
.my-n8 {
  margin-top: -5rem !important; }

.mr-n8,
.mx-n8 {
  margin-right: -5rem !important; }

.mb-n8,
.my-n8 {
  margin-bottom: -5rem !important; }

.ml-n8,
.mx-n8 {
  margin-left: -5rem !important; }

.m-n9 {
  margin: -5.625rem !important; }

.mt-n9,
.my-n9 {
  margin-top: -5.625rem !important; }

.mr-n9,
.mx-n9 {
  margin-right: -5.625rem !important; }

.mb-n9,
.my-n9 {
  margin-bottom: -5.625rem !important; }

.ml-n9,
.mx-n9 {
  margin-left: -5.625rem !important; }

.m-n10 {
  margin: -6.25rem !important; }

.mt-n10,
.my-n10 {
  margin-top: -6.25rem !important; }

.mr-n10,
.mx-n10 {
  margin-right: -6.25rem !important; }

.mb-n10,
.my-n10 {
  margin-bottom: -6.25rem !important; }

.ml-n10,
.mx-n10 {
  margin-left: -6.25rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.625rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.625rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.625rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.625rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.625rem !important; }
  .m-sm-2 {
    margin: 1.25rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 1.25rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 1.25rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 1.25rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 1.25rem !important; }
  .m-sm-3 {
    margin: 1.875rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1.875rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1.875rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1.875rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1.875rem !important; }
  .m-sm-4 {
    margin: 2.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 2.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 2.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 2.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 2.5rem !important; }
  .m-sm-5 {
    margin: 3.125rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3.125rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3.125rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3.125rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3.125rem !important; }
  .m-sm-6 {
    margin: 3.75rem !important; }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 3.75rem !important; }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 3.75rem !important; }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 3.75rem !important; }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 3.75rem !important; }
  .m-sm-7 {
    margin: 4.375rem !important; }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 4.375rem !important; }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 4.375rem !important; }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 4.375rem !important; }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 4.375rem !important; }
  .m-sm-8 {
    margin: 5rem !important; }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 5rem !important; }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 5rem !important; }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 5rem !important; }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 5rem !important; }
  .m-sm-9 {
    margin: 5.625rem !important; }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 5.625rem !important; }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 5.625rem !important; }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 5.625rem !important; }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 5.625rem !important; }
  .m-sm-10 {
    margin: 6.25rem !important; }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 6.25rem !important; }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 6.25rem !important; }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 6.25rem !important; }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 6.25rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.625rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.625rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.625rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.625rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.625rem !important; }
  .p-sm-2 {
    padding: 1.25rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 1.25rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 1.25rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 1.25rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 1.25rem !important; }
  .p-sm-3 {
    padding: 1.875rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1.875rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1.875rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1.875rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1.875rem !important; }
  .p-sm-4 {
    padding: 2.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 2.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 2.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 2.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 2.5rem !important; }
  .p-sm-5 {
    padding: 3.125rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3.125rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3.125rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3.125rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3.125rem !important; }
  .p-sm-6 {
    padding: 3.75rem !important; }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 3.75rem !important; }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 3.75rem !important; }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 3.75rem !important; }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 3.75rem !important; }
  .p-sm-7 {
    padding: 4.375rem !important; }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 4.375rem !important; }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 4.375rem !important; }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 4.375rem !important; }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 4.375rem !important; }
  .p-sm-8 {
    padding: 5rem !important; }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 5rem !important; }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 5rem !important; }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 5rem !important; }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 5rem !important; }
  .p-sm-9 {
    padding: 5.625rem !important; }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 5.625rem !important; }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 5.625rem !important; }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 5.625rem !important; }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 5.625rem !important; }
  .p-sm-10 {
    padding: 6.25rem !important; }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 6.25rem !important; }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 6.25rem !important; }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 6.25rem !important; }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 6.25rem !important; }
  .m-sm-n1 {
    margin: -0.625rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.625rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.625rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.625rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.625rem !important; }
  .m-sm-n2 {
    margin: -1.25rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -1.25rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -1.25rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -1.25rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -1.25rem !important; }
  .m-sm-n3 {
    margin: -1.875rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1.875rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1.875rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1.875rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1.875rem !important; }
  .m-sm-n4 {
    margin: -2.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -2.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -2.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -2.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -2.5rem !important; }
  .m-sm-n5 {
    margin: -3.125rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3.125rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3.125rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3.125rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3.125rem !important; }
  .m-sm-n6 {
    margin: -3.75rem !important; }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -3.75rem !important; }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -3.75rem !important; }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -3.75rem !important; }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -3.75rem !important; }
  .m-sm-n7 {
    margin: -4.375rem !important; }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -4.375rem !important; }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -4.375rem !important; }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -4.375rem !important; }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -4.375rem !important; }
  .m-sm-n8 {
    margin: -5rem !important; }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -5rem !important; }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -5rem !important; }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -5rem !important; }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -5rem !important; }
  .m-sm-n9 {
    margin: -5.625rem !important; }
  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -5.625rem !important; }
  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -5.625rem !important; }
  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -5.625rem !important; }
  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -5.625rem !important; }
  .m-sm-n10 {
    margin: -6.25rem !important; }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -6.25rem !important; }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -6.25rem !important; }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -6.25rem !important; }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -6.25rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.625rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.625rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.625rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.625rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.625rem !important; }
  .m-md-2 {
    margin: 1.25rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 1.25rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 1.25rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 1.25rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 1.25rem !important; }
  .m-md-3 {
    margin: 1.875rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1.875rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1.875rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1.875rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1.875rem !important; }
  .m-md-4 {
    margin: 2.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 2.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 2.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 2.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 2.5rem !important; }
  .m-md-5 {
    margin: 3.125rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3.125rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3.125rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3.125rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3.125rem !important; }
  .m-md-6 {
    margin: 3.75rem !important; }
  .mt-md-6,
  .my-md-6 {
    margin-top: 3.75rem !important; }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 3.75rem !important; }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 3.75rem !important; }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 3.75rem !important; }
  .m-md-7 {
    margin: 4.375rem !important; }
  .mt-md-7,
  .my-md-7 {
    margin-top: 4.375rem !important; }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 4.375rem !important; }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 4.375rem !important; }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 4.375rem !important; }
  .m-md-8 {
    margin: 5rem !important; }
  .mt-md-8,
  .my-md-8 {
    margin-top: 5rem !important; }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 5rem !important; }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 5rem !important; }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 5rem !important; }
  .m-md-9 {
    margin: 5.625rem !important; }
  .mt-md-9,
  .my-md-9 {
    margin-top: 5.625rem !important; }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 5.625rem !important; }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 5.625rem !important; }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 5.625rem !important; }
  .m-md-10 {
    margin: 6.25rem !important; }
  .mt-md-10,
  .my-md-10 {
    margin-top: 6.25rem !important; }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 6.25rem !important; }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 6.25rem !important; }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 6.25rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.625rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.625rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.625rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.625rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.625rem !important; }
  .p-md-2 {
    padding: 1.25rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 1.25rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 1.25rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 1.25rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 1.25rem !important; }
  .p-md-3 {
    padding: 1.875rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1.875rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1.875rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1.875rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1.875rem !important; }
  .p-md-4 {
    padding: 2.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 2.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 2.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 2.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 2.5rem !important; }
  .p-md-5 {
    padding: 3.125rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3.125rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3.125rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3.125rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3.125rem !important; }
  .p-md-6 {
    padding: 3.75rem !important; }
  .pt-md-6,
  .py-md-6 {
    padding-top: 3.75rem !important; }
  .pr-md-6,
  .px-md-6 {
    padding-right: 3.75rem !important; }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 3.75rem !important; }
  .pl-md-6,
  .px-md-6 {
    padding-left: 3.75rem !important; }
  .p-md-7 {
    padding: 4.375rem !important; }
  .pt-md-7,
  .py-md-7 {
    padding-top: 4.375rem !important; }
  .pr-md-7,
  .px-md-7 {
    padding-right: 4.375rem !important; }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 4.375rem !important; }
  .pl-md-7,
  .px-md-7 {
    padding-left: 4.375rem !important; }
  .p-md-8 {
    padding: 5rem !important; }
  .pt-md-8,
  .py-md-8 {
    padding-top: 5rem !important; }
  .pr-md-8,
  .px-md-8 {
    padding-right: 5rem !important; }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 5rem !important; }
  .pl-md-8,
  .px-md-8 {
    padding-left: 5rem !important; }
  .p-md-9 {
    padding: 5.625rem !important; }
  .pt-md-9,
  .py-md-9 {
    padding-top: 5.625rem !important; }
  .pr-md-9,
  .px-md-9 {
    padding-right: 5.625rem !important; }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 5.625rem !important; }
  .pl-md-9,
  .px-md-9 {
    padding-left: 5.625rem !important; }
  .p-md-10 {
    padding: 6.25rem !important; }
  .pt-md-10,
  .py-md-10 {
    padding-top: 6.25rem !important; }
  .pr-md-10,
  .px-md-10 {
    padding-right: 6.25rem !important; }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 6.25rem !important; }
  .pl-md-10,
  .px-md-10 {
    padding-left: 6.25rem !important; }
  .m-md-n1 {
    margin: -0.625rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.625rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.625rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.625rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.625rem !important; }
  .m-md-n2 {
    margin: -1.25rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -1.25rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -1.25rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -1.25rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -1.25rem !important; }
  .m-md-n3 {
    margin: -1.875rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1.875rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1.875rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1.875rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1.875rem !important; }
  .m-md-n4 {
    margin: -2.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -2.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -2.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -2.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -2.5rem !important; }
  .m-md-n5 {
    margin: -3.125rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3.125rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3.125rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3.125rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3.125rem !important; }
  .m-md-n6 {
    margin: -3.75rem !important; }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -3.75rem !important; }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -3.75rem !important; }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -3.75rem !important; }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -3.75rem !important; }
  .m-md-n7 {
    margin: -4.375rem !important; }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -4.375rem !important; }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -4.375rem !important; }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -4.375rem !important; }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -4.375rem !important; }
  .m-md-n8 {
    margin: -5rem !important; }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -5rem !important; }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -5rem !important; }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -5rem !important; }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -5rem !important; }
  .m-md-n9 {
    margin: -5.625rem !important; }
  .mt-md-n9,
  .my-md-n9 {
    margin-top: -5.625rem !important; }
  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -5.625rem !important; }
  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -5.625rem !important; }
  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -5.625rem !important; }
  .m-md-n10 {
    margin: -6.25rem !important; }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -6.25rem !important; }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -6.25rem !important; }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -6.25rem !important; }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -6.25rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.625rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.625rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.625rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.625rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.625rem !important; }
  .m-lg-2 {
    margin: 1.25rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 1.25rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 1.25rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 1.25rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 1.25rem !important; }
  .m-lg-3 {
    margin: 1.875rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1.875rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1.875rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1.875rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1.875rem !important; }
  .m-lg-4 {
    margin: 2.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 2.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 2.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 2.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 2.5rem !important; }
  .m-lg-5 {
    margin: 3.125rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3.125rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3.125rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3.125rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3.125rem !important; }
  .m-lg-6 {
    margin: 3.75rem !important; }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 3.75rem !important; }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 3.75rem !important; }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 3.75rem !important; }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 3.75rem !important; }
  .m-lg-7 {
    margin: 4.375rem !important; }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 4.375rem !important; }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 4.375rem !important; }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 4.375rem !important; }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 4.375rem !important; }
  .m-lg-8 {
    margin: 5rem !important; }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 5rem !important; }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 5rem !important; }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 5rem !important; }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 5rem !important; }
  .m-lg-9 {
    margin: 5.625rem !important; }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 5.625rem !important; }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 5.625rem !important; }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 5.625rem !important; }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 5.625rem !important; }
  .m-lg-10 {
    margin: 6.25rem !important; }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 6.25rem !important; }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 6.25rem !important; }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 6.25rem !important; }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 6.25rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.625rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.625rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.625rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.625rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.625rem !important; }
  .p-lg-2 {
    padding: 1.25rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 1.25rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 1.25rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 1.25rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 1.25rem !important; }
  .p-lg-3 {
    padding: 1.875rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1.875rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1.875rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1.875rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1.875rem !important; }
  .p-lg-4 {
    padding: 2.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 2.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 2.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 2.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 2.5rem !important; }
  .p-lg-5 {
    padding: 3.125rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3.125rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3.125rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3.125rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3.125rem !important; }
  .p-lg-6 {
    padding: 3.75rem !important; }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 3.75rem !important; }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 3.75rem !important; }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 3.75rem !important; }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 3.75rem !important; }
  .p-lg-7 {
    padding: 4.375rem !important; }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 4.375rem !important; }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 4.375rem !important; }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 4.375rem !important; }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 4.375rem !important; }
  .p-lg-8 {
    padding: 5rem !important; }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 5rem !important; }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 5rem !important; }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 5rem !important; }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 5rem !important; }
  .p-lg-9 {
    padding: 5.625rem !important; }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 5.625rem !important; }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 5.625rem !important; }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 5.625rem !important; }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 5.625rem !important; }
  .p-lg-10 {
    padding: 6.25rem !important; }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 6.25rem !important; }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 6.25rem !important; }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 6.25rem !important; }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 6.25rem !important; }
  .m-lg-n1 {
    margin: -0.625rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.625rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.625rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.625rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.625rem !important; }
  .m-lg-n2 {
    margin: -1.25rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -1.25rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -1.25rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -1.25rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -1.25rem !important; }
  .m-lg-n3 {
    margin: -1.875rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1.875rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1.875rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1.875rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1.875rem !important; }
  .m-lg-n4 {
    margin: -2.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -2.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -2.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -2.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -2.5rem !important; }
  .m-lg-n5 {
    margin: -3.125rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3.125rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3.125rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3.125rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3.125rem !important; }
  .m-lg-n6 {
    margin: -3.75rem !important; }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -3.75rem !important; }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -3.75rem !important; }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -3.75rem !important; }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -3.75rem !important; }
  .m-lg-n7 {
    margin: -4.375rem !important; }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -4.375rem !important; }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -4.375rem !important; }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -4.375rem !important; }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -4.375rem !important; }
  .m-lg-n8 {
    margin: -5rem !important; }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -5rem !important; }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -5rem !important; }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -5rem !important; }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -5rem !important; }
  .m-lg-n9 {
    margin: -5.625rem !important; }
  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -5.625rem !important; }
  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -5.625rem !important; }
  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -5.625rem !important; }
  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -5.625rem !important; }
  .m-lg-n10 {
    margin: -6.25rem !important; }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -6.25rem !important; }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -6.25rem !important; }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -6.25rem !important; }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -6.25rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.625rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.625rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.625rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.625rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.625rem !important; }
  .m-xl-2 {
    margin: 1.25rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 1.25rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 1.25rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 1.25rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 1.25rem !important; }
  .m-xl-3 {
    margin: 1.875rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1.875rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1.875rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1.875rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1.875rem !important; }
  .m-xl-4 {
    margin: 2.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 2.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 2.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 2.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 2.5rem !important; }
  .m-xl-5 {
    margin: 3.125rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3.125rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3.125rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3.125rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3.125rem !important; }
  .m-xl-6 {
    margin: 3.75rem !important; }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 3.75rem !important; }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 3.75rem !important; }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 3.75rem !important; }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 3.75rem !important; }
  .m-xl-7 {
    margin: 4.375rem !important; }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 4.375rem !important; }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 4.375rem !important; }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 4.375rem !important; }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 4.375rem !important; }
  .m-xl-8 {
    margin: 5rem !important; }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 5rem !important; }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 5rem !important; }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 5rem !important; }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 5rem !important; }
  .m-xl-9 {
    margin: 5.625rem !important; }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 5.625rem !important; }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 5.625rem !important; }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 5.625rem !important; }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 5.625rem !important; }
  .m-xl-10 {
    margin: 6.25rem !important; }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 6.25rem !important; }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 6.25rem !important; }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 6.25rem !important; }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 6.25rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.625rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.625rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.625rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.625rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.625rem !important; }
  .p-xl-2 {
    padding: 1.25rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 1.25rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 1.25rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 1.25rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 1.25rem !important; }
  .p-xl-3 {
    padding: 1.875rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1.875rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1.875rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1.875rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1.875rem !important; }
  .p-xl-4 {
    padding: 2.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 2.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 2.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 2.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 2.5rem !important; }
  .p-xl-5 {
    padding: 3.125rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3.125rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3.125rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3.125rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3.125rem !important; }
  .p-xl-6 {
    padding: 3.75rem !important; }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 3.75rem !important; }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 3.75rem !important; }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 3.75rem !important; }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 3.75rem !important; }
  .p-xl-7 {
    padding: 4.375rem !important; }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 4.375rem !important; }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 4.375rem !important; }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 4.375rem !important; }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 4.375rem !important; }
  .p-xl-8 {
    padding: 5rem !important; }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 5rem !important; }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 5rem !important; }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 5rem !important; }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 5rem !important; }
  .p-xl-9 {
    padding: 5.625rem !important; }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 5.625rem !important; }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 5.625rem !important; }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 5.625rem !important; }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 5.625rem !important; }
  .p-xl-10 {
    padding: 6.25rem !important; }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 6.25rem !important; }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 6.25rem !important; }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 6.25rem !important; }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 6.25rem !important; }
  .m-xl-n1 {
    margin: -0.625rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.625rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.625rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.625rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.625rem !important; }
  .m-xl-n2 {
    margin: -1.25rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -1.25rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -1.25rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -1.25rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -1.25rem !important; }
  .m-xl-n3 {
    margin: -1.875rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1.875rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1.875rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1.875rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1.875rem !important; }
  .m-xl-n4 {
    margin: -2.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -2.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -2.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -2.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -2.5rem !important; }
  .m-xl-n5 {
    margin: -3.125rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3.125rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3.125rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3.125rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3.125rem !important; }
  .m-xl-n6 {
    margin: -3.75rem !important; }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -3.75rem !important; }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -3.75rem !important; }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -3.75rem !important; }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -3.75rem !important; }
  .m-xl-n7 {
    margin: -4.375rem !important; }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -4.375rem !important; }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -4.375rem !important; }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -4.375rem !important; }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -4.375rem !important; }
  .m-xl-n8 {
    margin: -5rem !important; }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -5rem !important; }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -5rem !important; }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -5rem !important; }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -5rem !important; }
  .m-xl-n9 {
    margin: -5.625rem !important; }
  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -5.625rem !important; }
  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -5.625rem !important; }
  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -5.625rem !important; }
  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -5.625rem !important; }
  .m-xl-n10 {
    margin: -6.25rem !important; }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -6.25rem !important; }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -6.25rem !important; }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -6.25rem !important; }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -6.25rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: "Roboto Mono", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #064CDB !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #043291 !important; }

.text-secondary {
  color: #999999 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #737373 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f8f8 !important; }

a.text-light:hover, a.text-light:focus {
  color: #d2d2d2 !important; }

.text-dark {
  color: #151515 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: black !important; }

.text-body {
  color: #555555 !important; }

.text-muted {
  color: #999999 !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #e5e5e5 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #e5e5e5; }
  .table .thead-dark th {
    color: inherit;
    border-color: #e5e5e5; } }

.fs-12 {
  font-size: 0.75rem !important;
  line-height: 1.4em; }

.fs-14 {
  font-size: 0.875rem !important;
  line-height: 1.4em; }

.fs-16 {
  font-size: 1rem !important;
  line-height: 1.4em; }

.fs-18 {
  font-size: 1.125rem !important;
  line-height: 1.4em; }

.fs-20 {
  font-size: 1.25rem !important;
  line-height: 1.4em; }

.fs-22 {
  font-size: 1.375rem !important;
  line-height: 1.4em; }

.fs-24 {
  font-size: 1.5rem !important;
  line-height: 1.4em; }

.fs-26 {
  font-size: 1.625rem !important;
  line-height: 1.4em; }

.fs-28 {
  font-size: 1.75rem !important;
  line-height: 1.4em; }

.fs-30 {
  font-size: 1.875rem !important;
  line-height: 1.4em; }

.fs-32 {
  font-size: 2rem !important;
  line-height: 1.4em; }

.fs-34 {
  font-size: 2.125rem !important;
  line-height: 1.4em; }

.fs-36 {
  font-size: 2.25rem !important;
  line-height: 1.4em; }

.fs-38 {
  font-size: 2.375rem !important;
  line-height: 1.4em; }

.fs-40 {
  font-size: 2.5rem !important;
  line-height: 1.4em; }

.fs-50 {
  font-size: 3.125rem !important;
  line-height: 1.4em; }

.fs-60 {
  font-size: 3.75rem !important;
  line-height: 1.4em; }

.fs-70 {
  font-size: 4.375rem !important;
  line-height: 1.4em; }

.fs-80 {
  font-size: 5rem !important;
  line-height: 1.4em; }

.fs-90 {
  font-size: 5.625rem !important;
  line-height: 1.4em; }

.fs-100 {
  font-size: 6.25rem !important;
  line-height: 1.4em; }

[class*="circle"] {
  border-radius: 50%; }

.font-weight-semibold {
  font-weight: 600; }

.bg-blue {
  background-color: #064CDB !important; }

a.bg-blue:hover, a.bg-blue:focus,
button.bg-blue:hover,
button.bg-blue:focus {
  background-color: #053ba9 !important; }

.bg-indigo {
  background-color: #6610f2 !important; }

a.bg-indigo:hover, a.bg-indigo:focus,
button.bg-indigo:hover,
button.bg-indigo:focus {
  background-color: #510bc4 !important; }

.bg-purple {
  background-color: #8A3FF6 !important; }

a.bg-purple:hover, a.bg-purple:focus,
button.bg-purple:hover,
button.bg-purple:focus {
  background-color: #6c0ef4 !important; }

.bg-pink {
  background-color: #e83e8c !important; }

a.bg-pink:hover, a.bg-pink:focus,
button.bg-pink:hover,
button.bg-pink:focus {
  background-color: #d91a72 !important; }

.bg-red {
  background-color: #dc3545 !important; }

a.bg-red:hover, a.bg-red:focus,
button.bg-red:hover,
button.bg-red:focus {
  background-color: #bd2130 !important; }

.bg-orange {
  background-color: #fd7e14 !important; }

a.bg-orange:hover, a.bg-orange:focus,
button.bg-orange:hover,
button.bg-orange:focus {
  background-color: #dc6502 !important; }

.bg-yellow {
  background-color: #ffc107 !important; }

a.bg-yellow:hover, a.bg-yellow:focus,
button.bg-yellow:hover,
button.bg-yellow:focus {
  background-color: #d39e00 !important; }

.bg-green {
  background-color: #28a745 !important; }

a.bg-green:hover, a.bg-green:focus,
button.bg-green:hover,
button.bg-green:focus {
  background-color: #1e7e34 !important; }

.bg-teal {
  background-color: #20c997 !important; }

a.bg-teal:hover, a.bg-teal:focus,
button.bg-teal:hover,
button.bg-teal:focus {
  background-color: #199d76 !important; }

.bg-cyan {
  background-color: #17a2b8 !important; }

a.bg-cyan:hover, a.bg-cyan:focus,
button.bg-cyan:hover,
button.bg-cyan:focus {
  background-color: #117a8b !important; }

.bg-white {
  background-color: #fff !important; }

a.bg-white:hover, a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important; }

.bg-gray {
  background-color: #999999 !important; }

a.bg-gray:hover, a.bg-gray:focus,
button.bg-gray:hover,
button.bg-gray:focus {
  background-color: gray !important; }

.bg-gray-dark {
  background-color: #151515 !important; }

a.bg-gray-dark:hover, a.bg-gray-dark:focus,
button.bg-gray-dark:hover,
button.bg-gray-dark:focus {
  background-color: black !important; }

.bg-gray-light {
  background-color: #f5f5f5 !important; }

a.bg-gray-light:hover, a.bg-gray-light:focus,
button.bg-gray-light:hover,
button.bg-gray-light:focus {
  background-color: gainsboro !important; }

.bg-100 {
  background-color: #f8f8f8 !important; }

a.bg-100:hover, a.bg-100:focus,
button.bg-100:hover,
button.bg-100:focus {
  background-color: #dfdfdf !important; }

.bg-200 {
  background-color: #f5f5f5 !important; }

a.bg-200:hover, a.bg-200:focus,
button.bg-200:hover,
button.bg-200:focus {
  background-color: gainsboro !important; }

.bg-300 {
  background-color: #e5e5e5 !important; }

a.bg-300:hover, a.bg-300:focus,
button.bg-300:hover,
button.bg-300:focus {
  background-color: #cccccc !important; }

.bg-400 {
  background-color: #ced4da !important; }

a.bg-400:hover, a.bg-400:focus,
button.bg-400:hover,
button.bg-400:focus {
  background-color: #b1bbc4 !important; }

.bg-500 {
  background-color: #adb5bd !important; }

a.bg-500:hover, a.bg-500:focus,
button.bg-500:hover,
button.bg-500:focus {
  background-color: #919ca6 !important; }

.bg-600 {
  background-color: #999999 !important; }

a.bg-600:hover, a.bg-600:focus,
button.bg-600:hover,
button.bg-600:focus {
  background-color: gray !important; }

.bg-700 {
  background-color: #555555 !important; }

a.bg-700:hover, a.bg-700:focus,
button.bg-700:hover,
button.bg-700:focus {
  background-color: #3c3c3c !important; }

.bg-800 {
  background-color: #151515 !important; }

a.bg-800:hover, a.bg-800:focus,
button.bg-800:hover,
button.bg-800:focus {
  background-color: black !important; }

.bg-900 {
  background-color: #111111 !important; }

a.bg-900:hover, a.bg-900:focus,
button.bg-900:hover,
button.bg-900:focus {
  background-color: black !important; }

.text-blue {
  color: #064CDB !important; }

a.text-blue:hover, a.text-blue:focus {
  color: #043291 !important; }

.text-indigo {
  color: #6610f2 !important; }

a.text-indigo:hover, a.text-indigo:focus {
  color: #4709ac !important; }

.text-purple {
  color: #8A3FF6 !important; }

a.text-purple:hover, a.text-purple:focus {
  color: #610ade !important; }

.text-pink {
  color: #e83e8c !important; }

a.text-pink:hover, a.text-pink:focus {
  color: #c21766 !important; }

.text-red {
  color: #dc3545 !important; }

a.text-red:hover, a.text-red:focus {
  color: #a71d2a !important; }

.text-orange {
  color: #fd7e14 !important; }

a.text-orange:hover, a.text-orange:focus {
  color: #c35a02 !important; }

.text-yellow {
  color: #ffc107 !important; }

a.text-yellow:hover, a.text-yellow:focus {
  color: #ba8b00 !important; }

.text-green {
  color: #28a745 !important; }

a.text-green:hover, a.text-green:focus {
  color: #19692c !important; }

.text-teal {
  color: #20c997 !important; }

a.text-teal:hover, a.text-teal:focus {
  color: #158765 !important; }

.text-cyan {
  color: #17a2b8 !important; }

a.text-cyan:hover, a.text-cyan:focus {
  color: #0f6674 !important; }

.text-white {
  color: #fff !important; }

a.text-white:hover, a.text-white:focus {
  color: #d9d9d9 !important; }

.text-gray {
  color: #999999 !important; }

a.text-gray:hover, a.text-gray:focus {
  color: #737373 !important; }

.text-gray-dark {
  color: #151515 !important; }

a.text-gray-dark:hover, a.text-gray-dark:focus {
  color: black !important; }

.text-gray-light {
  color: #f5f5f5 !important; }

a.text-gray-light:hover, a.text-gray-light:focus {
  color: #cfcfcf !important; }

.text-facebook {
  color: #3b5998 !important; }

a.text-facebook:hover, a.text-facebook:focus {
  color: #263961 !important; }

.text-google-plus {
  color: #dd4b39 !important; }

a.text-google-plus:hover, a.text-google-plus:focus {
  color: #ac2d1e !important; }

.text-twitter {
  color: #00aced !important; }

a.text-twitter:hover, a.text-twitter:focus {
  color: #0074a1 !important; }

.text-linkedin {
  color: #007bb6 !important; }

a.text-linkedin:hover, a.text-linkedin:focus {
  color: #00476a !important; }

.text-pinterest {
  color: #cb2027 !important; }

a.text-pinterest:hover, a.text-pinterest:focus {
  color: #89161a !important; }

.text-git {
  color: #666666 !important; }

a.text-git:hover, a.text-git:focus {
  color: #404040 !important; }

.text-tumblr {
  color: #32506d !important; }

a.text-tumblr:hover, a.text-tumblr:focus {
  color: #1a2a39 !important; }

.text-vimeo {
  color: #aad450 !important; }

a.text-vimeo:hover, a.text-vimeo:focus {
  color: #83ad2b !important; }

.text-flickr {
  color: #ff0084 !important; }

a.text-flickr:hover, a.text-flickr:focus {
  color: #b3005c !important; }

.text-reddit {
  color: #ff4500 !important; }

a.text-reddit:hover, a.text-reddit:focus {
  color: #b33000 !important; }

.text-dribbble {
  color: #ea4c89 !important; }

a.text-dribbble:hover, a.text-dribbble:focus {
  color: #d11960 !important; }

.text-skype {
  color: #00aff0 !important; }

a.text-skype:hover, a.text-skype:focus {
  color: #0077a4 !important; }

.text-instagram {
  color: #517fa4 !important; }

a.text-instagram:hover, a.text-instagram:focus {
  color: #385771 !important; }

.text-behance {
  color: #1769ff !important; }

a.text-behance:hover, a.text-behance:focus {
  color: #0047ca !important; }

.text-rss {
  color: #f26522 !important; }

a.text-rss:hover, a.text-rss:focus {
  color: #bc440b !important; }

h1, h2, h3, h4, h5,
.h1, .h2, .h3, .h4, .h5 {
  margin-bottom: .25em; }
  h1:last-child, h2:last-child, h3:last-child, h4:last-child, h5:last-child,
  .h1:last-child, .h2:last-child, .h3:last-child, .h4:last-child, .h5:last-child {
    margin-bottom: 0; }

p, blockquote, .alert, ul, ol {
  margin-bottom: 20px; }
  p:last-child, blockquote:last-child, .alert:last-child, ul:last-child, ol:last-child {
    margin-bottom: 0; }

.external,
.code,
.kbd,
.scroll,
.folder,
.file {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  position: relative;
  display: inline-block;
  margin: .2rem;
  padding: 0 .5rem 0 2.5rem;
  font-size: inherit;
  font-family: "Open Sans", sans-serif;
  border-radius: 0.25rem;
  color: #555555; }
  .external::before,
  .code::before,
  .kbd::before,
  .scroll::before,
  .folder::before,
  .file::before {
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    transition-delay: 0s;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    transform: translate(0, -50%);
    position: absolute;
    left: 0;
    top: 50%;
    display: inline-block;
    width: 2rem;
    height: 100%;
    text-align: center;
    background: #f5f5f5;
    content: "\ebfa";
    color: #000;
    font-family: 'icomoon' !important;
    border-radius: 0.25rem;
    border-right: 1px solid #e5e5e5;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.text-white .external,
.text-white .code,
.text-white .kbd,
.text-white .scroll,
.text-white .folder,
.text-white .file {
  border-color: rgba(255, 255, 255, 0.1);
  background: rgba(0, 0, 0, 0.2);
  color: #fff; }
  .text-white .external:before,
  .text-white .code:before,
  .text-white .kbd:before,
  .text-white .scroll:before,
  .text-white .folder:before,
  .text-white .file:before {
    background: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.5);
    border-color: transparent; }

.component-info .external,
.component-info .code,
.component-info .kbd,
.component-info .scroll,
.component-info .folder,
.component-info .file {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  border: 0;
  background: rgba(0, 0, 0, 0.1);
  color: #000; }
  .component-info .external:before,
  .component-info .code:before,
  .component-info .kbd:before,
  .component-info .scroll:before,
  .component-info .folder:before,
  .component-info .file:before {
    background: transparent;
    color: #555555;
    border-color: #e5e5e5; }

.external::before {
  content: "\ec27"; }

.external:hover {
  background: #064CDB;
  color: #fff;
  border-color: #064CDB; }
  .external:hover::before {
    background: rgba(0, 0, 0, 0.1);
    color: #fff;
    border-color: rgba(0, 0, 0, 0.1); }

.scroll::before {
  content: "\ec69"; }

.scroll:hover {
  background: #064CDB;
  color: #fff; }

.code::before {
  content: "\eca9"; }

.folder::before {
  content: "\ec34"; }

.file::before {
  content: "\ec2d"; }

.kbd::before {
  content: "\f11c"; }

.lead {
  font-weight: 400;
  font-size: 1rem; }

strong, b {
  font-weight: 700; }

p {
  line-height: 1.8; }

img {
  max-width: 100%; }

a:hover {
  text-decoration: none; }

.eyebrow, .nav-toc-page::before {
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: 600; }
  .eyebrow[class*="-sm"], .nav-toc-page[class*="-sm"]::before {
    font-size: .75rem; }

.blockquote {
  position: relative;
  padding-left: 6.25rem; }
  .blockquote p {
    font-size: 1.125rem;
    color: #000;
    line-height: 1.5; }
  .blockquote::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "\201C";
    display: block;
    width: 1em;
    line-height: 1em;
    font-size: 6.25rem;
    font-family: "Muli", sans-serif;
    color: #999999; }

.link {
  display: inline-block;
  position: relative; }
  .link:hover::before, .link:focus::before {
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    transition-delay: 0s;
    top: calc(100% + 2px);
    opacity: 1; }
  .link::before {
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    transition-delay: 0s;
    content: "";
    position: absolute;
    top: 100%;
    display: block;
    width: calc(100% - 2px);
    height: 1px;
    background: currentColor;
    opacity: 0; }

.action {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  display: inline-block;
  position: relative;
  padding-right: 2em; }
  .action::after {
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    transition-delay: 0s;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    transform: translate(0, -50%);
    position: absolute;
    right: .5em;
    top: 50%;
    content: "\ebde";
    display: block;
    font-family: 'icomoon' !important;
    font-size: 1em;
    width: 1em;
    line-height: 1em; }
  .action:hover::after {
    right: 0; }

.section-title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5rem;
  line-height: 3.75rem;
  flex: 0;
  width: 100%;
  margin-bottom: 15px;
  padding-right: 3.75rem; }
  .section-title > * {
    display: inline-flex; }
  .section-title::after {
    content: '';
    flex-grow: 1;
    margin-left: .625rem;
    border-top: 1px solid #e5e5e5; }
  .section-title .anchor {
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    transition-delay: 0s;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    transform: translate(0, -50%);
    position: absolute;
    right: 0;
    top: 50%;
    display: block;
    width: 3.125rem;
    height: 3.125rem;
    border-radius: 50%;
    border: 1px solid #e5e5e5;
    outline: none; }
    .section-title .anchor::after {
      -webkit-transition: all 0.2s;
      -moz-transition: all 0.2s;
      transition: all 0.2s;
      -webkit-transition-delay: 0s;
      -moz-transition-delay: 0s;
      transition-delay: 0s;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      position: absolute;
      top: 50%;
      left: 50%;
      content: "\ec41";
      font-family: 'icomoon' !important;
      font-size: 1.125rem;
      color: #000; }
    .section-title .anchor:hover {
      border-color: #064CDB; }
      .section-title .anchor:hover::after {
        color: #064CDB; }
    .section-title .anchor.copied {
      border-color: #064CDB;
      background: #064CDB; }
      .section-title .anchor.copied::after {
        color: #fff;
        content: "\ebf7"; }

.section-title-2 {
  padding-right: 0;
  font-size: 1.875rem; }
  .section-title-2 .anchor {
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    transition-delay: 0s;
    opacity: 0;
    color: #adb5bd;
    outline: none; }
    .section-title-2 .anchor:hover {
      color: #064CDB; }
    .section-title-2 .anchor.copied {
      opacity: 1;
      color: #28a745; }
  .section-title-2:hover .anchor {
    opacity: 1; }

.text-white h1, .text-white h2, .text-white h3, .text-white h4, .text-white h5, .text-white h6 .h1, .text-white .h2, .text-white .h3, .text-white .h4, .text-white .h5, .text-white .h6 {
  color: #fff; }

.text-white p {
  color: rgba(255, 255, 255, 0.55); }

.text-white a {
  color: rgba(255, 255, 255, 0.75); }
  .text-white a:hover {
    color: #fff; }

@media (max-width: 991.98px) {
  .display-1 {
    font-size: 4.5rem; }
  .display-2 {
    font-size: 4.125rem; }
  .display-3 {
    font-size: 3.375rem; }
  .display-4 {
    font-size: 2.625rem; }
  h1, .h1 {
    font-size: 2.34375rem; }
  h2, .h2 {
    font-size: 1.875rem; }
  h3, .h3 {
    font-size: 1.40625rem; }
  h4, .h4 {
    font-size: 1.125rem; }
  h5, .h5 {
    font-size: 0.9375rem; } }

.form-group label {
  margin-bottom: .25rem;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.05em; }

.form-control {
  -webkit-box-shadow: 0px 0px 5px 1px rgba(229, 229, 229, 0.5);
  -moz-box-shadow: 0px 0px 5px 1px rgba(229, 229, 229, 0.5);
  box-shadow: 0px 0px 5px 1px rgba(229, 229, 229, 0.5); }

.form-control-inverted {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  border-color: transparent;
  box-shadow: none;
  background: rgba(0, 0, 0, 0.25);
  color: #fff; }
  .form-control-inverted:focus {
    border-color: transparent;
    background: rgba(0, 0, 0, 0.5); }

.input-group-text {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s; }

.input-group {
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05); }
  .input-group > .form-control {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    border-left-width: 0;
    border-right-width: 0; }
    .input-group > .form-control:first-child {
      border-left-width: 1px; }
    .input-group > .form-control:last-child {
      border-right-width: 1px; }
  .input-group:focus-within .input-group-text {
    border-color: #6697fb; }

.form-control-minimal {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  border: 0; }

.bootstrap-select {
  position: relative; }
  .bootstrap-select .btn {
    -webkit-box-shadow: 0px 0px 5px 1px rgba(229, 229, 229, 0.5);
    -moz-box-shadow: 0px 0px 5px 1px rgba(229, 229, 229, 0.5);
    box-shadow: 0px 0px 5px 1px rgba(229, 229, 229, 0.5);
    background: #fff; }
    .bootstrap-select .btn:focus {
      outline: none !important;
      box-shadow: none; }
  .bootstrap-select.form-control .btn {
    padding: 0.9375rem; }
  .bootstrap-select.form-control-sm .btn {
    padding: 0.625rem; }
  .bootstrap-select.form-control-lg .btn {
    padding: 1.25rem; }

.gutter-1 {
  margin: -5px; }
  .gutter-1 > * {
    margin-bottom: 0 !important;
    padding: 5px; }

.gutter-2 {
  margin: -10px; }
  .gutter-2 > * {
    margin-bottom: 0 !important;
    padding: 10px; }

.gutter-3 {
  margin: -15px; }
  .gutter-3 > * {
    margin-bottom: 0 !important;
    padding: 15px; }

.gutter-4 {
  margin: -20px; }
  .gutter-4 > * {
    margin-bottom: 0 !important;
    padding: 20px; }

.gutter-5 {
  margin: -25px; }
  .gutter-5 > * {
    margin-bottom: 0 !important;
    padding: 25px; }

@media (min-width: 576px) {
  .gutter-sm-1 {
    margin: -5px; }
    .gutter-sm-1 > * {
      margin-bottom: 0 !important;
      padding: 5px; }
  .gutter-sm-2 {
    margin: -10px; }
    .gutter-sm-2 > * {
      margin-bottom: 0 !important;
      padding: 10px; }
  .gutter-sm-3 {
    margin: -15px; }
    .gutter-sm-3 > * {
      margin-bottom: 0 !important;
      padding: 15px; }
  .gutter-sm-4 {
    margin: -20px; }
    .gutter-sm-4 > * {
      margin-bottom: 0 !important;
      padding: 20px; }
  .gutter-sm-5 {
    margin: -25px; }
    .gutter-sm-5 > * {
      margin-bottom: 0 !important;
      padding: 25px; } }

@media (min-width: 768px) {
  .gutter-md-1 {
    margin: -5px; }
    .gutter-md-1 > * {
      margin-bottom: 0 !important;
      padding: 5px; }
  .gutter-md-2 {
    margin: -10px; }
    .gutter-md-2 > * {
      margin-bottom: 0 !important;
      padding: 10px; }
  .gutter-md-3 {
    margin: -15px; }
    .gutter-md-3 > * {
      margin-bottom: 0 !important;
      padding: 15px; }
  .gutter-md-4 {
    margin: -20px; }
    .gutter-md-4 > * {
      margin-bottom: 0 !important;
      padding: 20px; }
  .gutter-md-5 {
    margin: -25px; }
    .gutter-md-5 > * {
      margin-bottom: 0 !important;
      padding: 25px; } }

@media (min-width: 992px) {
  .gutter-lg-1 {
    margin: -5px; }
    .gutter-lg-1 > * {
      margin-bottom: 0 !important;
      padding: 5px; }
  .gutter-lg-2 {
    margin: -10px; }
    .gutter-lg-2 > * {
      margin-bottom: 0 !important;
      padding: 10px; }
  .gutter-lg-3 {
    margin: -15px; }
    .gutter-lg-3 > * {
      margin-bottom: 0 !important;
      padding: 15px; }
  .gutter-lg-4 {
    margin: -20px; }
    .gutter-lg-4 > * {
      margin-bottom: 0 !important;
      padding: 20px; }
  .gutter-lg-5 {
    margin: -25px; }
    .gutter-lg-5 > * {
      margin-bottom: 0 !important;
      padding: 25px; } }

@media (min-width: 1200px) {
  .gutter-xl-1 {
    margin: -5px; }
    .gutter-xl-1 > * {
      margin-bottom: 0 !important;
      padding: 5px; }
  .gutter-xl-2 {
    margin: -10px; }
    .gutter-xl-2 > * {
      margin-bottom: 0 !important;
      padding: 10px; }
  .gutter-xl-3 {
    margin: -15px; }
    .gutter-xl-3 > * {
      margin-bottom: 0 !important;
      padding: 15px; }
  .gutter-xl-4 {
    margin: -20px; }
    .gutter-xl-4 > * {
      margin-bottom: 0 !important;
      padding: 20px; }
  .gutter-xl-5 {
    margin: -25px; }
    .gutter-xl-5 > * {
      margin-bottom: 0 !important;
      padding: 25px; } }

.gutter-0 {
  padding: 1px 1px 0 0;
  margin-left: 0;
  margin-right: 0; }
  .gutter-0 > * {
    padding: 0;
    margin: -1px -1px 0 0 !important; }

.vh-10 {
  height: 10vh;
  min-height: 10vh; }

.vh-20 {
  height: 20vh;
  min-height: 20vh; }

.vh-25 {
  height: 25vh;
  min-height: 25vh; }

.vh-30 {
  height: 30vh;
  min-height: 30vh; }

.vh-40 {
  height: 40vh;
  min-height: 40vh; }

.vh-50 {
  height: 50vh;
  min-height: 50vh; }

.vh-60 {
  height: 60vh;
  min-height: 60vh; }

.vh-70 {
  height: 70vh;
  min-height: 70vh; }

.vh-75 {
  height: 75vh;
  min-height: 75vh; }

.vh-80 {
  height: 80vh;
  min-height: 80vh; }

.vh-90 {
  height: 90vh;
  min-height: 90vh; }

.vh-100 {
  height: 100vh;
  min-height: 100vh; }

.vh-max-10 {
  max-height: 10vh; }

.vh-max-20 {
  max-height: 20vh; }

.vh-max-25 {
  max-height: 25vh; }

.vh-max-30 {
  max-height: 30vh; }

.vh-max-40 {
  max-height: 40vh; }

.vh-max-50 {
  max-height: 50vh; }

.vh-max-60 {
  max-height: 60vh; }

.vh-max-70 {
  max-height: 70vh; }

.vh-max-75 {
  max-height: 75vh; }

.vh-max-80 {
  max-height: 80vh; }

.vh-max-90 {
  max-height: 90vh; }

.vh-max-100 {
  max-height: 100vh; }

@media (max-width: 767.98px) {
  [class*="vh-"] {
    height: auto; } }

.fw-25 {
  flex: 0 0 25% !important;
  width: 25% !important; }

.fw-50 {
  flex: 0 0 50% !important;
  width: 50% !important; }

.fw-75 {
  flex: 0 0 75% !important;
  width: 75% !important; }

.fw-100 {
  flex: 0 0 100% !important;
  width: 100% !important; }

.fw-auto {
  flex: 0 0 auto !important;
  width: auto !important; }

.overlay {
  overflow: visible; }
  .overlay .overlay-item-bottom {
    margin-bottom: -12.5rem; }
  .overlay .overlay-item-top {
    margin-top: -12.5rem; }

@media (min-width: 992px) {
  .overlay-content {
    margin-top: -4.375rem; }
  .overlay-hero {
    margin-bottom: -4.375rem; } }

.header {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: static;
  width: 100%;
  z-index: 100; }
  .header + section {
    padding-top: 12.5rem; }
  .header nav {
    width: 100%; }

.header-sticky {
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  position: absolute;
  top: 0;
  right: 0;
  bottom: auto;
  left: 0; }

.headroom--unpinned .header-sticky {
  -webkit-transform: translate(0, -100%);
  -moz-transform: translate(0, -100%);
  transform: translate(0, -100%);
  opacity: 0; }

.header-transparent {
  background: transparent }

.header-white {
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  background: #fff; }

@media (max-width: 991.98px) {
  .header-transparent,
  .header-minimal-dark {
    background: #151515; } }

.header-minimal-light .nav-link.active,
.header-white .nav-link.active {
  border-color: #064CDB !important; }

@media (max-width: 991.98px) {
  .header-minimal-light,
  .header-white {
    background: #fff; }
    .header-minimal-light .burger span,
    .header-white .burger span {
      background-color: #000; }
      .header-minimal-light .burger span::before, .header-minimal-light .burger span::after,
      .header-white .burger span::before,
      .header-white .burger span::after {
        background-color: #000; } }

.headroom--not-top.headroom--pinned .header-transparent {
  background: #151515; }

.headroom--not-top.headroom--pinned .header-minimal-dark {
  background: #151515; }

.headroom--not-top.headroom--pinned .header-minimal-light {
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  background: #fff; }

body {
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body > section,
body > footer {
  position: relative;
  margin: 0 auto;
  padding: 6.25rem 0;
  overflow: hidden; }

.row + .row {
  margin-top: 2.5rem; }

.container-fluid {
  max-width: 1600px; }

.container-full {
  padding-right: 15px;
  padding-left: 15px; }

article > section:not(:first-child) {
  margin-top: 3.125rem; }

article > section > .row {
  margin-top: 30px;
  margin-bottom: 30px; }

.text-white [class*="separator"], .text-white[class*="separator"] {
  border-color: rgba(255, 255, 255, 0.1); }

.page-progress {
  display: inline-block;
  height: 100px;
  width: 4px;
  background: #e5e5e5;
  border-radius: 2px; }
  .page-progress div {
    width: 4px;
    border-radius: 2px;
    background: #151515; }

.content-body {
  padding: 45px; }
  @media (max-width: 991.98px) {
    .content-body {
      padding: 20px; } }
  .content-body > section:not(:first-of-type) {
    margin-top: 3.125rem; }

.content-aside {
  padding: 0; }

.content-header {
  margin: -45px;
  margin-bottom: 45px;
  padding: 45px;
  border-bottom: 1px solid #e5e5e5; }

.content-footer {
  margin: -45px;
  margin-top: 45px;
  padding: 45px;
  border-top: 1px solid #e5e5e5; }

[class*="separator"] {
  border-width: 0;
  border-color: #e5e5e5;
  border-style: solid; }
  [class*="separator"][class*="-top"] {
    border-top-width: 1px; }
  [class*="separator"][class*="-right"] {
    border-right-width: 1px; }
  [class*="separator"][class*="-bottom"] {
    border-bottom-width: 1px; }
  [class*="separator"][class*="-left"] {
    border-left-width: 1px; }

.half {
  position: relative;
  padding: 10px 0; }
  .half .half-bg {
    position: absolute;
    content: "";
    top: 50%;
    width: 100%;
    height: 50%; }
    .half .half-bg .image {
      z-index: 1; }

.sticky {
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s; }
  .sticky.is_stuck {
    -webkit-transform: translate(0, 15px);
    -moz-transform: translate(0, 15px);
    transform: translate(0, 15px); }

.headroom--pinned .sticky-with-header.is_stuck {
  -webkit-transform: translate(0, 6.25rem);
  -moz-transform: translate(0, 6.25rem);
  transform: translate(0, 6.25rem); }

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?pxhbgs");
  src: url("../fonts/icomoon.eot?pxhbgs#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?pxhbgs") format("truetype"), url("../fonts/icomoon.woff?pxhbgs") format("woff"), url("../fonts/icomoon.svg?pxhbgs#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-asterisk:before {
  content: "\f069"; }

.icon-plus:before {
  content: "\f067"; }

.icon-question:before {
  content: "\f128"; }

.icon-minus:before {
  content: "\f068"; }

.icon-glass:before {
  content: "\f000"; }

.icon-music:before {
  content: "\f001"; }

.icon-search:before {
  content: "\f002"; }

.icon-envelope-o:before {
  content: "\f003"; }

.icon-heart:before {
  content: "\f004"; }

.icon-star:before {
  content: "\f005"; }

.icon-star-o:before {
  content: "\f006"; }

.icon-user:before {
  content: "\f007"; }

.icon-film:before {
  content: "\f008"; }

.icon-th-large:before {
  content: "\f009"; }

.icon-th:before {
  content: "\f00a"; }

.icon-th-list:before {
  content: "\f00b"; }

.icon-check:before {
  content: "\f00c"; }

.icon-close:before {
  content: "\f00d"; }

.icon-remove:before {
  content: "\f00d"; }

.icon-times:before {
  content: "\f00d"; }

.icon-search-plus:before {
  content: "\f00e"; }

.icon-search-minus:before {
  content: "\f010"; }

.icon-power-off:before {
  content: "\f011"; }

.icon-signal:before {
  content: "\f012"; }

.icon-cog:before {
  content: "\f013"; }

.icon-gear:before {
  content: "\f013"; }

.icon-trash-o:before {
  content: "\f014"; }

.icon-home:before {
  content: "\f015"; }

.icon-file-o:before {
  content: "\f016"; }

.icon-clock-o:before {
  content: "\f017"; }

.icon-road:before {
  content: "\f018"; }

.icon-download:before {
  content: "\f019"; }

.icon-arrow-circle-o-down:before {
  content: "\f01a"; }

.icon-arrow-circle-o-up:before {
  content: "\f01b"; }

.icon-inbox:before {
  content: "\f01c"; }

.icon-play-circle-o:before {
  content: "\f01d"; }

.icon-repeat:before {
  content: "\f01e"; }

.icon-rotate-right:before {
  content: "\f01e"; }

.icon-refresh:before {
  content: "\f021"; }

.icon-list-alt:before {
  content: "\f022"; }

.icon-lock:before {
  content: "\f023"; }

.icon-flag:before {
  content: "\f024"; }

.icon-headphones:before {
  content: "\f025"; }

.icon-volume-off:before {
  content: "\f026"; }

.icon-volume-down:before {
  content: "\f027"; }

.icon-volume-up:before {
  content: "\f028"; }

.icon-qrcode:before {
  content: "\f029"; }

.icon-barcode:before {
  content: "\f02a"; }

.icon-tag:before {
  content: "\f02b"; }

.icon-tags:before {
  content: "\f02c"; }

.icon-book:before {
  content: "\f02d"; }

.icon-bookmark:before {
  content: "\f02e"; }

.icon-print:before {
  content: "\f02f"; }

.icon-camera:before {
  content: "\f030"; }

.icon-font:before {
  content: "\f031"; }

.icon-bold:before {
  content: "\f032"; }

.icon-italic:before {
  content: "\f033"; }

.icon-text-height:before {
  content: "\f034"; }

.icon-text-width:before {
  content: "\f035"; }

.icon-align-left:before {
  content: "\f036"; }

.icon-align-center:before {
  content: "\f037"; }

.icon-align-right:before {
  content: "\f038"; }

.icon-align-justify:before {
  content: "\f039"; }

.icon-list:before {
  content: "\f03a"; }

.icon-dedent:before {
  content: "\f03b"; }

.icon-outdent:before {
  content: "\f03b"; }

.icon-indent:before {
  content: "\f03c"; }

.icon-video-camera:before {
  content: "\f03d"; }

.icon-image:before {
  content: "\f03e"; }

.icon-photo:before {
  content: "\f03e"; }

.icon-picture-o:before {
  content: "\f03e"; }

.icon-pencil:before {
  content: "\f040"; }

.icon-map-marker:before {
  content: "\f041"; }

.icon-adjust:before {
  content: "\f042"; }

.icon-tint:before {
  content: "\f043"; }

.icon-edit:before {
  content: "\f044"; }

.icon-pencil-square-o:before {
  content: "\f044"; }

.icon-share-square-o:before {
  content: "\f045"; }

.icon-check-square-o:before {
  content: "\f046"; }

.icon-arrows:before {
  content: "\f047"; }

.icon-step-backward:before {
  content: "\f048"; }

.icon-fast-backward:before {
  content: "\f049"; }

.icon-backward:before {
  content: "\f04a"; }

.icon-play:before {
  content: "\f04b"; }

.icon-pause:before {
  content: "\f04c"; }

.icon-stop:before {
  content: "\f04d"; }

.icon-forward:before {
  content: "\f04e"; }

.icon-fast-forward:before {
  content: "\f050"; }

.icon-step-forward:before {
  content: "\f051"; }

.icon-eject:before {
  content: "\f052"; }

.icon-chevron-left:before {
  content: "\f053"; }

.icon-chevron-right:before {
  content: "\f054"; }

.icon-plus-circle:before {
  content: "\f055"; }

.icon-minus-circle:before {
  content: "\f056"; }

.icon-times-circle:before {
  content: "\f057"; }

.icon-check-circle:before {
  content: "\f058"; }

.icon-question-circle:before {
  content: "\f059"; }

.icon-info-circle:before {
  content: "\f05a"; }

.icon-crosshairs:before {
  content: "\f05b"; }

.icon-times-circle-o:before {
  content: "\f05c"; }

.icon-check-circle-o:before {
  content: "\f05d"; }

.icon-ban:before {
  content: "\f05e"; }

.icon-arrow-left:before {
  content: "\f060"; }

.icon-arrow-right:before {
  content: "\f061"; }

.icon-arrow-up:before {
  content: "\f062"; }

.icon-arrow-down:before {
  content: "\f063"; }

.icon-mail-forward:before {
  content: "\f064"; }

.icon-share:before {
  content: "\f064"; }

.icon-expand:before {
  content: "\f065"; }

.icon-compress:before {
  content: "\f066"; }

.icon-exclamation-circle:before {
  content: "\f06a"; }

.icon-gift:before {
  content: "\f06b"; }

.icon-leaf:before {
  content: "\f06c"; }

.icon-fire:before {
  content: "\f06d"; }

.icon-eye:before {
  content: "\f06e"; }

.icon-eye-slash:before {
  content: "\f070"; }

.icon-exclamation-triangle:before {
  content: "\f071"; }

.icon-warning:before {
  content: "\f071"; }

.icon-plane:before {
  content: "\f072"; }

.icon-calendar:before {
  content: "\f073"; }

.icon-random:before {
  content: "\f074"; }

.icon-comment:before {
  content: "\f075"; }

.icon-magnet:before {
  content: "\f076"; }

.icon-chevron-up:before {
  content: "\f077"; }

.icon-chevron-down:before {
  content: "\f078"; }

.icon-retweet:before {
  content: "\f079"; }

.icon-shopping-cart:before {
  content: "\f07a"; }

.icon-folder:before {
  content: "\f07b"; }

.icon-folder-open:before {
  content: "\f07c"; }

.icon-arrows-v:before {
  content: "\f07d"; }

.icon-arrows-h:before {
  content: "\f07e"; }

.icon-bar-chart:before {
  content: "\f080"; }

.icon-bar-chart-o:before {
  content: "\f080"; }

.icon-twitter-square:before {
  content: "\f081"; }

.icon-facebook-square:before {
  content: "\f082"; }

.icon-camera-retro:before {
  content: "\f083"; }

.icon-key:before {
  content: "\f084"; }

.icon-cogs:before {
  content: "\f085"; }

.icon-gears:before {
  content: "\f085"; }

.icon-comments:before {
  content: "\f086"; }

.icon-thumbs-o-up:before {
  content: "\f087"; }

.icon-thumbs-o-down:before {
  content: "\f088"; }

.icon-star-half:before {
  content: "\f089"; }

.icon-heart-o:before {
  content: "\f08a"; }

.icon-sign-out:before {
  content: "\f08b"; }

.icon-linkedin-square:before {
  content: "\f08c"; }

.icon-thumb-tack:before {
  content: "\f08d"; }

.icon-external-link:before {
  content: "\f08e"; }

.icon-sign-in:before {
  content: "\f090"; }

.icon-trophy:before {
  content: "\f091"; }

.icon-github-square:before {
  content: "\f092"; }

.icon-upload:before {
  content: "\f093"; }

.icon-lemon-o:before {
  content: "\f094"; }

.icon-phone:before {
  content: "\f095"; }

.icon-square-o:before {
  content: "\f096"; }

.icon-bookmark-o:before {
  content: "\f097"; }

.icon-phone-square:before {
  content: "\f098"; }

.icon-twitter:before {
  content: "\f099"; }

.icon-facebook:before {
  content: "\f09a"; }

.icon-facebook-f:before {
  content: "\f09a"; }

.icon-github:before {
  content: "\f09b"; }

.icon-unlock:before {
  content: "\f09c"; }

.icon-credit-card:before {
  content: "\f09d"; }

.icon-feed:before {
  content: "\f09e"; }

.icon-rss:before {
  content: "\f09e"; }

.icon-hdd-o:before {
  content: "\f0a0"; }

.icon-bullhorn:before {
  content: "\f0a1"; }

.icon-bell-o:before {
  content: "\f0a2"; }

.icon-certificate:before {
  content: "\f0a3"; }

.icon-hand-o-right:before {
  content: "\f0a4"; }

.icon-hand-o-left:before {
  content: "\f0a5"; }

.icon-hand-o-up:before {
  content: "\f0a6"; }

.icon-hand-o-down:before {
  content: "\f0a7"; }

.icon-arrow-circle-left:before {
  content: "\f0a8"; }

.icon-arrow-circle-right:before {
  content: "\f0a9"; }

.icon-arrow-circle-up:before {
  content: "\f0aa"; }

.icon-arrow-circle-down:before {
  content: "\f0ab"; }

.icon-globe:before {
  content: "\f0ac"; }

.icon-wrench:before {
  content: "\f0ad"; }

.icon-tasks:before {
  content: "\f0ae"; }

.icon-filter:before {
  content: "\f0b0"; }

.icon-briefcase:before {
  content: "\f0b1"; }

.icon-arrows-alt:before {
  content: "\f0b2"; }

.icon-group:before {
  content: "\f0c0"; }

.icon-users:before {
  content: "\f0c0"; }

.icon-chain:before {
  content: "\f0c1"; }

.icon-link:before {
  content: "\f0c1"; }

.icon-cloud:before {
  content: "\f0c2"; }

.icon-flask:before {
  content: "\f0c3"; }

.icon-cut:before {
  content: "\f0c4"; }

.icon-scissors:before {
  content: "\f0c4"; }

.icon-copy:before {
  content: "\f0c5"; }

.icon-files-o:before {
  content: "\f0c5"; }

.icon-paperclip:before {
  content: "\f0c6"; }

.icon-floppy-o:before {
  content: "\f0c7"; }

.icon-save:before {
  content: "\f0c7"; }

.icon-square:before {
  content: "\f0c8"; }

.icon-bars:before {
  content: "\f0c9"; }

.icon-navicon:before {
  content: "\f0c9"; }

.icon-reorder:before {
  content: "\f0c9"; }

.icon-list-ul:before {
  content: "\f0ca"; }

.icon-list-ol:before {
  content: "\f0cb"; }

.icon-strikethrough:before {
  content: "\f0cc"; }

.icon-underline:before {
  content: "\f0cd"; }

.icon-table:before {
  content: "\f0ce"; }

.icon-magic:before {
  content: "\f0d0"; }

.icon-truck:before {
  content: "\f0d1"; }

.icon-pinterest:before {
  content: "\f0d2"; }

.icon-pinterest-square:before {
  content: "\f0d3"; }

.icon-google-plus-square:before {
  content: "\f0d4"; }

.icon-google-plus:before {
  content: "\f0d5"; }

.icon-money:before {
  content: "\f0d6"; }

.icon-caret-down:before {
  content: "\f0d7"; }

.icon-caret-up:before {
  content: "\f0d8"; }

.icon-caret-left:before {
  content: "\f0d9"; }

.icon-caret-right:before {
  content: "\f0da"; }

.icon-columns:before {
  content: "\f0db"; }

.icon-sort:before {
  content: "\f0dc"; }

.icon-unsorted:before {
  content: "\f0dc"; }

.icon-sort-desc:before {
  content: "\f0dd"; }

.icon-sort-down:before {
  content: "\f0dd"; }

.icon-sort-asc:before {
  content: "\f0de"; }

.icon-sort-up:before {
  content: "\f0de"; }

.icon-envelope:before {
  content: "\f0e0"; }

.icon-linkedin:before {
  content: "\f0e1"; }

.icon-rotate-left:before {
  content: "\f0e2"; }

.icon-undo:before {
  content: "\f0e2"; }

.icon-gavel:before {
  content: "\f0e3"; }

.icon-legal:before {
  content: "\f0e3"; }

.icon-dashboard:before {
  content: "\f0e4"; }

.icon-tachometer:before {
  content: "\f0e4"; }

.icon-comment-o:before {
  content: "\f0e5"; }

.icon-comments-o:before {
  content: "\f0e6"; }

.icon-bolt:before {
  content: "\f0e7"; }

.icon-flash:before {
  content: "\f0e7"; }

.icon-sitemap:before {
  content: "\f0e8"; }

.icon-umbrella:before {
  content: "\f0e9"; }

.icon-clipboard:before {
  content: "\f0ea"; }

.icon-paste:before {
  content: "\f0ea"; }

.icon-lightbulb-o:before {
  content: "\f0eb"; }

.icon-exchange:before {
  content: "\f0ec"; }

.icon-cloud-download:before {
  content: "\f0ed"; }

.icon-cloud-upload:before {
  content: "\f0ee"; }

.icon-user-md:before {
  content: "\f0f0"; }

.icon-stethoscope:before {
  content: "\f0f1"; }

.icon-suitcase:before {
  content: "\f0f2"; }

.icon-bell:before {
  content: "\f0f3"; }

.icon-coffee:before {
  content: "\f0f4"; }

.icon-cutlery:before {
  content: "\f0f5"; }

.icon-file-text-o:before {
  content: "\f0f6"; }

.icon-building-o:before {
  content: "\f0f7"; }

.icon-hospital-o:before {
  content: "\f0f8"; }

.icon-ambulance:before {
  content: "\f0f9"; }

.icon-medkit:before {
  content: "\f0fa"; }

.icon-fighter-jet:before {
  content: "\f0fb"; }

.icon-beer:before {
  content: "\f0fc"; }

.icon-h-square:before {
  content: "\f0fd"; }

.icon-plus-square:before {
  content: "\f0fe"; }

.icon-angle-double-left:before {
  content: "\f100"; }

.icon-angle-double-right:before {
  content: "\f101"; }

.icon-angle-double-up:before {
  content: "\f102"; }

.icon-angle-double-down:before {
  content: "\f103"; }

.icon-angle-left:before {
  content: "\f104"; }

.icon-angle-right:before {
  content: "\f105"; }

.icon-angle-up:before {
  content: "\f106"; }

.icon-angle-down:before {
  content: "\f107"; }

.icon-desktop:before {
  content: "\f108"; }

.icon-laptop:before {
  content: "\f109"; }

.icon-tablet:before {
  content: "\f10a"; }

.icon-mobile:before {
  content: "\f10b"; }

.icon-mobile-phone:before {
  content: "\f10b"; }

.icon-circle-o:before {
  content: "\f10c"; }

.icon-quote-left:before {
  content: "\f10d"; }

.icon-quote-right:before {
  content: "\f10e"; }

.icon-spinner:before {
  content: "\f110"; }

.icon-circle:before {
  content: "\f111"; }

.icon-mail-reply:before {
  content: "\f112"; }

.icon-reply:before {
  content: "\f112"; }

.icon-github-alt:before {
  content: "\f113"; }

.icon-folder-o:before {
  content: "\f114"; }

.icon-folder-open-o:before {
  content: "\f115"; }

.icon-smile-o:before {
  content: "\f118"; }

.icon-frown-o:before {
  content: "\f119"; }

.icon-meh-o:before {
  content: "\f11a"; }

.icon-gamepad:before {
  content: "\f11b"; }

.icon-keyboard-o:before {
  content: "\f11c"; }

.icon-flag-o:before {
  content: "\f11d"; }

.icon-flag-checkered:before {
  content: "\f11e"; }

.icon-terminal:before {
  content: "\f120"; }

.icon-code:before {
  content: "\f121"; }

.icon-mail-reply-all:before {
  content: "\f122"; }

.icon-reply-all:before {
  content: "\f122"; }

.icon-star-half-empty:before {
  content: "\f123"; }

.icon-star-half-full:before {
  content: "\f123"; }

.icon-star-half-o:before {
  content: "\f123"; }

.icon-location-arrow:before {
  content: "\f124"; }

.icon-crop:before {
  content: "\f125"; }

.icon-code-fork:before {
  content: "\f126"; }

.icon-chain-broken:before {
  content: "\f127"; }

.icon-unlink:before {
  content: "\f127"; }

.icon-info:before {
  content: "\f129"; }

.icon-exclamation:before {
  content: "\f12a"; }

.icon-superscript:before {
  content: "\f12b"; }

.icon-subscript:before {
  content: "\f12c"; }

.icon-eraser:before {
  content: "\f12d"; }

.icon-puzzle-piece:before {
  content: "\f12e"; }

.icon-microphone:before {
  content: "\f130"; }

.icon-microphone-slash:before {
  content: "\f131"; }

.icon-shield:before {
  content: "\f132"; }

.icon-calendar-o:before {
  content: "\f133"; }

.icon-fire-extinguisher:before {
  content: "\f134"; }

.icon-rocket:before {
  content: "\f135"; }

.icon-maxcdn:before {
  content: "\f136"; }

.icon-chevron-circle-left:before {
  content: "\f137"; }

.icon-chevron-circle-right:before {
  content: "\f138"; }

.icon-chevron-circle-up:before {
  content: "\f139"; }

.icon-chevron-circle-down:before {
  content: "\f13a"; }

.icon-html5:before {
  content: "\f13b"; }

.icon-css3:before {
  content: "\f13c"; }

.icon-anchor:before {
  content: "\f13d"; }

.icon-unlock-alt:before {
  content: "\f13e"; }

.icon-bullseye:before {
  content: "\f140"; }

.icon-ellipsis-h:before {
  content: "\f141"; }

.icon-ellipsis-v:before {
  content: "\f142"; }

.icon-rss-square:before {
  content: "\f143"; }

.icon-play-circle:before {
  content: "\f144"; }

.icon-ticket:before {
  content: "\f145"; }

.icon-minus-square:before {
  content: "\f146"; }

.icon-minus-square-o:before {
  content: "\f147"; }

.icon-level-up:before {
  content: "\f148"; }

.icon-level-down:before {
  content: "\f149"; }

.icon-check-square:before {
  content: "\f14a"; }

.icon-pencil-square:before {
  content: "\f14b"; }

.icon-external-link-square:before {
  content: "\f14c"; }

.icon-share-square:before {
  content: "\f14d"; }

.icon-compass:before {
  content: "\f14e"; }

.icon-caret-square-o-down:before {
  content: "\f150"; }

.icon-toggle-down:before {
  content: "\f150"; }

.icon-caret-square-o-up:before {
  content: "\f151"; }

.icon-toggle-up:before {
  content: "\f151"; }

.icon-caret-square-o-right:before {
  content: "\f152"; }

.icon-toggle-right:before {
  content: "\f152"; }

.icon-eur:before {
  content: "\f153"; }

.icon-euro:before {
  content: "\f153"; }

.icon-gbp:before {
  content: "\f154"; }

.icon-dollar:before {
  content: "\f155"; }

.icon-usd:before {
  content: "\f155"; }

.icon-inr:before {
  content: "\f156"; }

.icon-rupee:before {
  content: "\f156"; }

.icon-cny:before {
  content: "\f157"; }

.icon-jpy:before {
  content: "\f157"; }

.icon-rmb:before {
  content: "\f157"; }

.icon-yen:before {
  content: "\f157"; }

.icon-rouble:before {
  content: "\f158"; }

.icon-rub:before {
  content: "\f158"; }

.icon-ruble:before {
  content: "\f158"; }

.icon-krw:before {
  content: "\f159"; }

.icon-won:before {
  content: "\f159"; }

.icon-bitcoin:before {
  content: "\f15a"; }

.icon-btc:before {
  content: "\f15a"; }

.icon-file:before {
  content: "\f15b"; }

.icon-file-text:before {
  content: "\f15c"; }

.icon-sort-alpha-asc:before {
  content: "\f15d"; }

.icon-sort-alpha-desc:before {
  content: "\f15e"; }

.icon-sort-amount-asc:before {
  content: "\f160"; }

.icon-sort-amount-desc:before {
  content: "\f161"; }

.icon-sort-numeric-asc:before {
  content: "\f162"; }

.icon-sort-numeric-desc:before {
  content: "\f163"; }

.icon-thumbs-up:before {
  content: "\f164"; }

.icon-thumbs-down:before {
  content: "\f165"; }

.icon-youtube-square:before {
  content: "\f166"; }

.icon-youtube:before {
  content: "\f167"; }

.icon-xing:before {
  content: "\f168"; }

.icon-xing-square:before {
  content: "\f169"; }

.icon-youtube-play:before {
  content: "\f16a"; }

.icon-dropbox:before {
  content: "\f16b"; }

.icon-stack-overflow:before {
  content: "\f16c"; }

.icon-instagram:before {
  content: "\f16d"; }

.icon-flickr:before {
  content: "\f16e"; }

.icon-adn:before {
  content: "\f170"; }

.icon-bitbucket:before {
  content: "\f171"; }

.icon-bitbucket-square:before {
  content: "\f172"; }

.icon-tumblr:before {
  content: "\f173"; }

.icon-tumblr-square:before {
  content: "\f174"; }

.icon-long-arrow-down:before {
  content: "\f175"; }

.icon-long-arrow-up:before {
  content: "\f176"; }

.icon-long-arrow-left:before {
  content: "\f177"; }

.icon-long-arrow-right:before {
  content: "\f178"; }

.icon-apple:before {
  content: "\f179"; }

.icon-windows:before {
  content: "\f17a"; }

.icon-android:before {
  content: "\f17b"; }

.icon-linux:before {
  content: "\f17c"; }

.icon-dribbble:before {
  content: "\f17d"; }

.icon-skype:before {
  content: "\f17e"; }

.icon-foursquare:before {
  content: "\f180"; }

.icon-trello:before {
  content: "\f181"; }

.icon-female:before {
  content: "\f182"; }

.icon-male:before {
  content: "\f183"; }

.icon-gittip:before {
  content: "\f184"; }

.icon-gratipay:before {
  content: "\f184"; }

.icon-sun-o:before {
  content: "\f185"; }

.icon-moon-o:before {
  content: "\f186"; }

.icon-archive:before {
  content: "\f187"; }

.icon-bug:before {
  content: "\f188"; }

.icon-vk:before {
  content: "\f189"; }

.icon-weibo:before {
  content: "\f18a"; }

.icon-renren:before {
  content: "\f18b"; }

.icon-pagelines:before {
  content: "\f18c"; }

.icon-stack-exchange:before {
  content: "\f18d"; }

.icon-arrow-circle-o-right:before {
  content: "\f18e"; }

.icon-arrow-circle-o-left:before {
  content: "\f190"; }

.icon-caret-square-o-left:before {
  content: "\f191"; }

.icon-toggle-left:before {
  content: "\f191"; }

.icon-dot-circle-o:before {
  content: "\f192"; }

.icon-wheelchair:before {
  content: "\f193"; }

.icon-vimeo-square:before {
  content: "\f194"; }

.icon-try:before {
  content: "\f195"; }

.icon-turkish-lira:before {
  content: "\f195"; }

.icon-plus-square-o:before {
  content: "\f196"; }

.icon-space-shuttle:before {
  content: "\f197"; }

.icon-slack:before {
  content: "\f198"; }

.icon-envelope-square:before {
  content: "\f199"; }

.icon-wordpress:before {
  content: "\f19a"; }

.icon-openid:before {
  content: "\f19b"; }

.icon-bank:before {
  content: "\f19c"; }

.icon-institution:before {
  content: "\f19c"; }

.icon-university:before {
  content: "\f19c"; }

.icon-graduation-cap:before {
  content: "\f19d"; }

.icon-mortar-board:before {
  content: "\f19d"; }

.icon-yahoo:before {
  content: "\f19e"; }

.icon-google:before {
  content: "\f1a0"; }

.icon-reddit:before {
  content: "\f1a1"; }

.icon-reddit-square:before {
  content: "\f1a2"; }

.icon-stumbleupon-circle:before {
  content: "\f1a3"; }

.icon-stumbleupon:before {
  content: "\f1a4"; }

.icon-delicious:before {
  content: "\f1a5"; }

.icon-digg:before {
  content: "\f1a6"; }

.icon-pied-piper-pp:before {
  content: "\f1a7"; }

.icon-pied-piper-alt:before {
  content: "\f1a8"; }

.icon-drupal:before {
  content: "\f1a9"; }

.icon-joomla:before {
  content: "\f1aa"; }

.icon-language:before {
  content: "\f1ab"; }

.icon-fax:before {
  content: "\f1ac"; }

.icon-building:before {
  content: "\f1ad"; }

.icon-child:before {
  content: "\f1ae"; }

.icon-paw:before {
  content: "\f1b0"; }

.icon-spoon:before {
  content: "\f1b1"; }

.icon-cube:before {
  content: "\f1b2"; }

.icon-cubes:before {
  content: "\f1b3"; }

.icon-behance:before {
  content: "\f1b4"; }

.icon-behance-square:before {
  content: "\f1b5"; }

.icon-steam:before {
  content: "\f1b6"; }

.icon-steam-square:before {
  content: "\f1b7"; }

.icon-recycle:before {
  content: "\f1b8"; }

.icon-automobile:before {
  content: "\f1b9"; }

.icon-car:before {
  content: "\f1b9"; }

.icon-cab:before {
  content: "\f1ba"; }

.icon-taxi:before {
  content: "\f1ba"; }

.icon-tree:before {
  content: "\f1bb"; }

.icon-spotify:before {
  content: "\f1bc"; }

.icon-deviantart:before {
  content: "\f1bd"; }

.icon-soundcloud:before {
  content: "\f1be"; }

.icon-database:before {
  content: "\f1c0"; }

.icon-file-pdf-o:before {
  content: "\f1c1"; }

.icon-file-word-o:before {
  content: "\f1c2"; }

.icon-file-excel-o:before {
  content: "\f1c3"; }

.icon-file-powerpoint-o:before {
  content: "\f1c4"; }

.icon-file-image-o:before {
  content: "\f1c5"; }

.icon-file-photo-o:before {
  content: "\f1c5"; }

.icon-file-picture-o:before {
  content: "\f1c5"; }

.icon-file-archive-o:before {
  content: "\f1c6"; }

.icon-file-zip-o:before {
  content: "\f1c6"; }

.icon-file-audio-o:before {
  content: "\f1c7"; }

.icon-file-sound-o:before {
  content: "\f1c7"; }

.icon-file-movie-o:before {
  content: "\f1c8"; }

.icon-file-video-o:before {
  content: "\f1c8"; }

.icon-file-code-o:before {
  content: "\f1c9"; }

.icon-vine:before {
  content: "\f1ca"; }

.icon-codepen:before {
  content: "\f1cb"; }

.icon-jsfiddle:before {
  content: "\f1cc"; }

.icon-life-bouy:before {
  content: "\f1cd"; }

.icon-life-buoy:before {
  content: "\f1cd"; }

.icon-life-ring:before {
  content: "\f1cd"; }

.icon-life-saver:before {
  content: "\f1cd"; }

.icon-support:before {
  content: "\f1cd"; }

.icon-circle-o-notch:before {
  content: "\f1ce"; }

.icon-ra:before {
  content: "\f1d0"; }

.icon-rebel:before {
  content: "\f1d0"; }

.icon-resistance:before {
  content: "\f1d0"; }

.icon-empire:before {
  content: "\f1d1"; }

.icon-ge:before {
  content: "\f1d1"; }

.icon-git-square:before {
  content: "\f1d2"; }

.icon-git:before {
  content: "\f1d3"; }

.icon-hacker-news:before {
  content: "\f1d4"; }

.icon-y-combinator-square:before {
  content: "\f1d4"; }

.icon-yc-square:before {
  content: "\f1d4"; }

.icon-tencent-weibo:before {
  content: "\f1d5"; }

.icon-qq:before {
  content: "\f1d6"; }

.icon-wechat:before {
  content: "\f1d7"; }

.icon-weixin:before {
  content: "\f1d7"; }

.icon-paper-plane:before {
  content: "\f1d8"; }

.icon-send:before {
  content: "\f1d8"; }

.icon-paper-plane-o:before {
  content: "\f1d9"; }

.icon-send-o:before {
  content: "\f1d9"; }

.icon-history:before {
  content: "\f1da"; }

.icon-circle-thin:before {
  content: "\f1db"; }

.icon-header:before {
  content: "\f1dc"; }

.icon-paragraph:before {
  content: "\f1dd"; }

.icon-sliders:before {
  content: "\f1de"; }

.icon-share-alt:before {
  content: "\f1e0"; }

.icon-share-alt-square:before {
  content: "\f1e1"; }

.icon-bomb:before {
  content: "\f1e2"; }

.icon-futbol-o:before {
  content: "\f1e3"; }

.icon-soccer-ball-o:before {
  content: "\f1e3"; }

.icon-tty:before {
  content: "\f1e4"; }

.icon-binoculars:before {
  content: "\f1e5"; }

.icon-plug:before {
  content: "\f1e6"; }

.icon-slideshare:before {
  content: "\f1e7"; }

.icon-twitch:before {
  content: "\f1e8"; }

.icon-yelp:before {
  content: "\f1e9"; }

.icon-newspaper-o:before {
  content: "\f1ea"; }

.icon-wifi:before {
  content: "\f1eb"; }

.icon-calculator:before {
  content: "\f1ec"; }

.icon-paypal:before {
  content: "\f1ed"; }

.icon-google-wallet:before {
  content: "\f1ee"; }

.icon-cc-visa:before {
  content: "\f1f0"; }

.icon-cc-mastercard:before {
  content: "\f1f1"; }

.icon-cc-discover:before {
  content: "\f1f2"; }

.icon-cc-amex:before {
  content: "\f1f3"; }

.icon-cc-paypal:before {
  content: "\f1f4"; }

.icon-cc-stripe:before {
  content: "\f1f5"; }

.icon-bell-slash:before {
  content: "\f1f6"; }

.icon-bell-slash-o:before {
  content: "\f1f7"; }

.icon-trash:before {
  content: "\f1f8"; }

.icon-copyright:before {
  content: "\f1f9"; }

.icon-at:before {
  content: "\f1fa"; }

.icon-eyedropper:before {
  content: "\f1fb"; }

.icon-paint-brush:before {
  content: "\f1fc"; }

.icon-birthday-cake:before {
  content: "\f1fd"; }

.icon-area-chart:before {
  content: "\f1fe"; }

.icon-pie-chart:before {
  content: "\f200"; }

.icon-line-chart:before {
  content: "\f201"; }

.icon-lastfm:before {
  content: "\f202"; }

.icon-lastfm-square:before {
  content: "\f203"; }

.icon-toggle-off:before {
  content: "\f204"; }

.icon-toggle-on:before {
  content: "\f205"; }

.icon-bicycle:before {
  content: "\f206"; }

.icon-bus:before {
  content: "\f207"; }

.icon-ioxhost:before {
  content: "\f208"; }

.icon-angellist:before {
  content: "\f209"; }

.icon-cc:before {
  content: "\f20a"; }

.icon-ils:before {
  content: "\f20b"; }

.icon-shekel:before {
  content: "\f20b"; }

.icon-sheqel:before {
  content: "\f20b"; }

.icon-meanpath:before {
  content: "\f20c"; }

.icon-buysellads:before {
  content: "\f20d"; }

.icon-connectdevelop:before {
  content: "\f20e"; }

.icon-dashcube:before {
  content: "\f210"; }

.icon-forumbee:before {
  content: "\f211"; }

.icon-leanpub:before {
  content: "\f212"; }

.icon-sellsy:before {
  content: "\f213"; }

.icon-shirtsinbulk:before {
  content: "\f214"; }

.icon-simplybuilt:before {
  content: "\f215"; }

.icon-skyatlas:before {
  content: "\f216"; }

.icon-cart-plus:before {
  content: "\f217"; }

.icon-cart-arrow-down:before {
  content: "\f218"; }

.icon-diamond:before {
  content: "\f219"; }

.icon-ship:before {
  content: "\f21a"; }

.icon-user-secret:before {
  content: "\f21b"; }

.icon-motorcycle:before {
  content: "\f21c"; }

.icon-street-view:before {
  content: "\f21d"; }

.icon-heartbeat:before {
  content: "\f21e"; }

.icon-venus:before {
  content: "\f221"; }

.icon-mars:before {
  content: "\f222"; }

.icon-mercury:before {
  content: "\f223"; }

.icon-intersex:before {
  content: "\f224"; }

.icon-transgender:before {
  content: "\f224"; }

.icon-transgender-alt:before {
  content: "\f225"; }

.icon-venus-double:before {
  content: "\f226"; }

.icon-mars-double:before {
  content: "\f227"; }

.icon-venus-mars:before {
  content: "\f228"; }

.icon-mars-stroke:before {
  content: "\f229"; }

.icon-mars-stroke-v:before {
  content: "\f22a"; }

.icon-mars-stroke-h:before {
  content: "\f22b"; }

.icon-neuter:before {
  content: "\f22c"; }

.icon-genderless:before {
  content: "\f22d"; }

.icon-facebook-official:before {
  content: "\f230"; }

.icon-pinterest-p:before {
  content: "\f231"; }

.icon-whatsapp:before {
  content: "\f232"; }

.icon-server:before {
  content: "\f233"; }

.icon-user-plus:before {
  content: "\f234"; }

.icon-user-times:before {
  content: "\f235"; }

.icon-bed:before {
  content: "\f236"; }

.icon-hotel:before {
  content: "\f236"; }

.icon-viacoin:before {
  content: "\f237"; }

.icon-train:before {
  content: "\f238"; }

.icon-subway:before {
  content: "\f239"; }

.icon-medium:before {
  content: "\f23a"; }

.icon-y-combinator:before {
  content: "\f23b"; }

.icon-yc:before {
  content: "\f23b"; }

.icon-optin-monster:before {
  content: "\f23c"; }

.icon-opencart:before {
  content: "\f23d"; }

.icon-expeditedssl:before {
  content: "\f23e"; }

.icon-battery:before {
  content: "\f240"; }

.icon-battery-4:before {
  content: "\f240"; }

.icon-battery-full:before {
  content: "\f240"; }

.icon-battery-3:before {
  content: "\f241"; }

.icon-battery-three-quarters:before {
  content: "\f241"; }

.icon-battery-2:before {
  content: "\f242"; }

.icon-battery-half:before {
  content: "\f242"; }

.icon-battery-1:before {
  content: "\f243"; }

.icon-battery-quarter:before {
  content: "\f243"; }

.icon-battery-0:before {
  content: "\f244"; }

.icon-battery-empty:before {
  content: "\f244"; }

.icon-mouse-pointer:before {
  content: "\f245"; }

.icon-i-cursor:before {
  content: "\f246"; }

.icon-object-group:before {
  content: "\f247"; }

.icon-object-ungroup:before {
  content: "\f248"; }

.icon-sticky-note:before {
  content: "\f249"; }

.icon-sticky-note-o:before {
  content: "\f24a"; }

.icon-cc-jcb:before {
  content: "\f24b"; }

.icon-cc-diners-club:before {
  content: "\f24c"; }

.icon-clone:before {
  content: "\f24d"; }

.icon-balance-scale:before {
  content: "\f24e"; }

.icon-hourglass-o:before {
  content: "\f250"; }

.icon-hourglass-1:before {
  content: "\f251"; }

.icon-hourglass-start:before {
  content: "\f251"; }

.icon-hourglass-2:before {
  content: "\f252"; }

.icon-hourglass-half:before {
  content: "\f252"; }

.icon-hourglass-3:before {
  content: "\f253"; }

.icon-hourglass-end:before {
  content: "\f253"; }

.icon-hourglass:before {
  content: "\f254"; }

.icon-hand-grab-o:before {
  content: "\f255"; }

.icon-hand-rock-o:before {
  content: "\f255"; }

.icon-hand-paper-o:before {
  content: "\f256"; }

.icon-hand-stop-o:before {
  content: "\f256"; }

.icon-hand-scissors-o:before {
  content: "\f257"; }

.icon-hand-lizard-o:before {
  content: "\f258"; }

.icon-hand-spock-o:before {
  content: "\f259"; }

.icon-hand-pointer-o:before {
  content: "\f25a"; }

.icon-hand-peace-o:before {
  content: "\f25b"; }

.icon-trademark:before {
  content: "\f25c"; }

.icon-registered:before {
  content: "\f25d"; }

.icon-creative-commons:before {
  content: "\f25e"; }

.icon-gg:before {
  content: "\f260"; }

.icon-gg-circle:before {
  content: "\f261"; }

.icon-tripadvisor:before {
  content: "\f262"; }

.icon-odnoklassniki:before {
  content: "\f263"; }

.icon-odnoklassniki-square:before {
  content: "\f264"; }

.icon-get-pocket:before {
  content: "\f265"; }

.icon-wikipedia-w:before {
  content: "\f266"; }

.icon-safari:before {
  content: "\f267"; }

.icon-chrome:before {
  content: "\f268"; }

.icon-firefox:before {
  content: "\f269"; }

.icon-opera:before {
  content: "\f26a"; }

.icon-internet-explorer:before {
  content: "\f26b"; }

.icon-television:before {
  content: "\f26c"; }

.icon-tv:before {
  content: "\f26c"; }

.icon-contao:before {
  content: "\f26d"; }

.icon-500px:before {
  content: "\f26e"; }

.icon-amazon:before {
  content: "\f270"; }

.icon-calendar-plus-o:before {
  content: "\f271"; }

.icon-calendar-minus-o:before {
  content: "\f272"; }

.icon-calendar-times-o:before {
  content: "\f273"; }

.icon-calendar-check-o:before {
  content: "\f274"; }

.icon-industry:before {
  content: "\f275"; }

.icon-map-pin:before {
  content: "\f276"; }

.icon-map-signs:before {
  content: "\f277"; }

.icon-map-o:before {
  content: "\f278"; }

.icon-map:before {
  content: "\f279"; }

.icon-commenting:before {
  content: "\f27a"; }

.icon-commenting-o:before {
  content: "\f27b"; }

.icon-houzz:before {
  content: "\f27c"; }

.icon-vimeo:before {
  content: "\f27d"; }

.icon-black-tie:before {
  content: "\f27e"; }

.icon-fonticons:before {
  content: "\f280"; }

.icon-reddit-alien:before {
  content: "\f281"; }

.icon-edge:before {
  content: "\f282"; }

.icon-credit-card-alt:before {
  content: "\f283"; }

.icon-codiepie:before {
  content: "\f284"; }

.icon-modx:before {
  content: "\f285"; }

.icon-fort-awesome:before {
  content: "\f286"; }

.icon-usb:before {
  content: "\f287"; }

.icon-product-hunt:before {
  content: "\f288"; }

.icon-mixcloud:before {
  content: "\f289"; }

.icon-scribd:before {
  content: "\f28a"; }

.icon-pause-circle:before {
  content: "\f28b"; }

.icon-pause-circle-o:before {
  content: "\f28c"; }

.icon-stop-circle:before {
  content: "\f28d"; }

.icon-stop-circle-o:before {
  content: "\f28e"; }

.icon-shopping-bag:before {
  content: "\f290"; }

.icon-shopping-basket:before {
  content: "\f291"; }

.icon-hashtag:before {
  content: "\f292"; }

.icon-bluetooth:before {
  content: "\f293"; }

.icon-bluetooth-b:before {
  content: "\f294"; }

.icon-percent:before {
  content: "\f295"; }

.icon-gitlab:before {
  content: "\f296"; }

.icon-wpbeginner:before {
  content: "\f297"; }

.icon-wpforms:before {
  content: "\f298"; }

.icon-envira:before {
  content: "\f299"; }

.icon-universal-access:before {
  content: "\f29a"; }

.icon-wheelchair-alt:before {
  content: "\f29b"; }

.icon-question-circle-o:before {
  content: "\f29c"; }

.icon-blind:before {
  content: "\f29d"; }

.icon-audio-description:before {
  content: "\f29e"; }

.icon-volume-control-phone:before {
  content: "\f2a0"; }

.icon-braille:before {
  content: "\f2a1"; }

.icon-assistive-listening-systems:before {
  content: "\f2a2"; }

.icon-american-sign-language-interpreting:before {
  content: "\f2a3"; }

.icon-asl-interpreting:before {
  content: "\f2a3"; }

.icon-deaf:before {
  content: "\f2a4"; }

.icon-deafness:before {
  content: "\f2a4"; }

.icon-hard-of-hearing:before {
  content: "\f2a4"; }

.icon-glide:before {
  content: "\f2a5"; }

.icon-glide-g:before {
  content: "\f2a6"; }

.icon-sign-language:before {
  content: "\f2a7"; }

.icon-signing:before {
  content: "\f2a7"; }

.icon-low-vision:before {
  content: "\f2a8"; }

.icon-viadeo:before {
  content: "\f2a9"; }

.icon-viadeo-square:before {
  content: "\f2aa"; }

.icon-snapchat:before {
  content: "\f2ab"; }

.icon-snapchat-ghost:before {
  content: "\f2ac"; }

.icon-snapchat-square:before {
  content: "\f2ad"; }

.icon-pied-piper:before {
  content: "\f2ae"; }

.icon-first-order:before {
  content: "\f2b0"; }

.icon-yoast:before {
  content: "\f2b1"; }

.icon-themeisle:before {
  content: "\f2b2"; }

.icon-google-plus-circle:before {
  content: "\f2b3"; }

.icon-google-plus-official:before {
  content: "\f2b3"; }

.icon-fa:before {
  content: "\f2b4"; }

.icon-font-awesome:before {
  content: "\f2b4"; }

.icon-handshake-o:before {
  content: "\f2b5"; }

.icon-envelope-open:before {
  content: "\f2b6"; }

.icon-envelope-open-o:before {
  content: "\f2b7"; }

.icon-linode:before {
  content: "\f2b8"; }

.icon-address-book:before {
  content: "\f2b9"; }

.icon-address-book-o:before {
  content: "\f2ba"; }

.icon-address-card:before {
  content: "\f2bb"; }

.icon-vcard:before {
  content: "\f2bb"; }

.icon-address-card-o:before {
  content: "\f2bc"; }

.icon-vcard-o:before {
  content: "\f2bc"; }

.icon-user-circle:before {
  content: "\f2bd"; }

.icon-user-circle-o:before {
  content: "\f2be"; }

.icon-user-o:before {
  content: "\f2c0"; }

.icon-id-badge:before {
  content: "\f2c1"; }

.icon-drivers-license:before {
  content: "\f2c2"; }

.icon-id-card:before {
  content: "\f2c2"; }

.icon-drivers-license-o:before {
  content: "\f2c3"; }

.icon-id-card-o:before {
  content: "\f2c3"; }

.icon-quora:before {
  content: "\f2c4"; }

.icon-free-code-camp:before {
  content: "\f2c5"; }

.icon-telegram:before {
  content: "\f2c6"; }

.icon-thermometer:before {
  content: "\f2c7"; }

.icon-thermometer-4:before {
  content: "\f2c7"; }

.icon-thermometer-full:before {
  content: "\f2c7"; }

.icon-thermometer-3:before {
  content: "\f2c8"; }

.icon-thermometer-three-quarters:before {
  content: "\f2c8"; }

.icon-thermometer-2:before {
  content: "\f2c9"; }

.icon-thermometer-half:before {
  content: "\f2c9"; }

.icon-thermometer-1:before {
  content: "\f2ca"; }

.icon-thermometer-quarter:before {
  content: "\f2ca"; }

.icon-thermometer-0:before {
  content: "\f2cb"; }

.icon-thermometer-empty:before {
  content: "\f2cb"; }

.icon-shower:before {
  content: "\f2cc"; }

.icon-bath:before {
  content: "\f2cd"; }

.icon-bathtub:before {
  content: "\f2cd"; }

.icon-s15:before {
  content: "\f2cd"; }

.icon-podcast:before {
  content: "\f2ce"; }

.icon-window-maximize:before {
  content: "\f2d0"; }

.icon-window-minimize:before {
  content: "\f2d1"; }

.icon-window-restore:before {
  content: "\f2d2"; }

.icon-times-rectangle:before {
  content: "\f2d3"; }

.icon-window-close:before {
  content: "\f2d3"; }

.icon-times-rectangle-o:before {
  content: "\f2d4"; }

.icon-window-close-o:before {
  content: "\f2d4"; }

.icon-bandcamp:before {
  content: "\f2d5"; }

.icon-grav:before {
  content: "\f2d6"; }

.icon-etsy:before {
  content: "\f2d7"; }

.icon-imdb:before {
  content: "\f2d8"; }

.icon-ravelry:before {
  content: "\f2d9"; }

.icon-eercast:before {
  content: "\f2da"; }

.icon-microchip:before {
  content: "\f2db"; }

.icon-snowflake-o:before {
  content: "\f2dc"; }

.icon-superpowers:before {
  content: "\f2dd"; }

.icon-wpexplorer:before {
  content: "\f2de"; }

.icon-meetup:before {
  content: "\f2e0"; }

.icon-activity:before {
  content: "\ebcc"; }

.icon-airplay:before {
  content: "\ebcd"; }

.icon-alert-circle:before {
  content: "\ebce"; }

.icon-alert-octagon:before {
  content: "\ebcf"; }

.icon-alert-triangle:before {
  content: "\ebd0"; }

.icon-align-center2:before {
  content: "\ebd1"; }

.icon-align-justify2:before {
  content: "\ebd2"; }

.icon-align-left2:before {
  content: "\ebd3"; }

.icon-align-right2:before {
  content: "\ebd4"; }

.icon-anchor2:before {
  content: "\ebd5"; }

.icon-aperture:before {
  content: "\ebd6"; }

.icon-archive2:before {
  content: "\ebd7"; }

.icon-arrow-down2:before {
  content: "\ebd8"; }

.icon-arrow-down-circle:before {
  content: "\ebd9"; }

.icon-arrow-down-left:before {
  content: "\ebda"; }

.icon-arrow-down-right:before {
  content: "\ebdb"; }

.icon-arrow-left2:before {
  content: "\ebdc"; }

.icon-arrow-left-circle:before {
  content: "\ebdd"; }

.icon-arrow-right2:before {
  content: "\ebde"; }

.icon-arrow-right-circle:before {
  content: "\ebdf"; }

.icon-arrow-up2:before {
  content: "\ebe0"; }

.icon-arrow-up-circle:before {
  content: "\ebe1"; }

.icon-arrow-up-left:before {
  content: "\ebe2"; }

.icon-arrow-up-right:before {
  content: "\ebe3"; }

.icon-at-sign:before {
  content: "\ebe4"; }

.icon-award:before {
  content: "\ebe5"; }

.icon-bar-chart2:before {
  content: "\ebe6"; }

.icon-bar-chart-2:before {
  content: "\ebe7"; }

.icon-battery2:before {
  content: "\ebe8"; }

.icon-battery-charging:before {
  content: "\ebe9"; }

.icon-bell2:before {
  content: "\ebea"; }

.icon-bell-off:before {
  content: "\ebeb"; }

.icon-bluetooth2:before {
  content: "\ebec"; }

.icon-bold2:before {
  content: "\ebed"; }

.icon-book2:before {
  content: "\ebee"; }

.icon-book-open:before {
  content: "\ebef"; }

.icon-bookmark2:before {
  content: "\ebf0"; }

.icon-box:before {
  content: "\ebf1"; }

.icon-briefcase2:before {
  content: "\ebf2"; }

.icon-calendar2:before {
  content: "\ebf3"; }

.icon-camera2:before {
  content: "\ebf4"; }

.icon-camera-off:before {
  content: "\ebf5"; }

.icon-cast:before {
  content: "\ebf6"; }

.icon-check2:before {
  content: "\ebf7"; }

.icon-check-circle2:before {
  content: "\ebf8"; }

.icon-check-square2:before {
  content: "\ebf9"; }

.icon-chevron-down2:before {
  content: "\ebfa"; }

.icon-chevron-left2:before {
  content: "\ebfb"; }

.icon-chevron-right2:before {
  content: "\ebfc"; }

.icon-chevron-up2:before {
  content: "\ebfd"; }

.icon-chevrons-down:before {
  content: "\ebfe"; }

.icon-chevrons-left:before {
  content: "\ebff"; }

.icon-chevrons-right:before {
  content: "\ec00"; }

.icon-chevrons-up:before {
  content: "\ec01"; }

.icon-chrome2:before {
  content: "\ec02"; }

.icon-circle2:before {
  content: "\ec03"; }

.icon-clipboard2:before {
  content: "\ec04"; }

.icon-clock:before {
  content: "\ec05"; }

.icon-cloud2:before {
  content: "\ec06"; }

.icon-cloud-drizzle:before {
  content: "\ec07"; }

.icon-cloud-lightning:before {
  content: "\ec08"; }

.icon-cloud-off:before {
  content: "\ec09"; }

.icon-cloud-rain:before {
  content: "\ec0a"; }

.icon-cloud-snow:before {
  content: "\ec0b"; }

.icon-code2:before {
  content: "\ec0c"; }

.icon-codepen2:before {
  content: "\ec0d"; }

.icon-command:before {
  content: "\ec0e"; }

.icon-compass2:before {
  content: "\ec0f"; }

.icon-copy2:before {
  content: "\ec10"; }

.icon-corner-down-left:before {
  content: "\ec11"; }

.icon-corner-down-right:before {
  content: "\ec12"; }

.icon-corner-left-down:before {
  content: "\ec13"; }

.icon-corner-left-up:before {
  content: "\ec14"; }

.icon-corner-right-down:before {
  content: "\ec15"; }

.icon-corner-right-up:before {
  content: "\ec16"; }

.icon-corner-up-left:before {
  content: "\ec17"; }

.icon-corner-up-right:before {
  content: "\ec18"; }

.icon-cpu:before {
  content: "\ec19"; }

.icon-credit-card2:before {
  content: "\ec1a"; }

.icon-crop2:before {
  content: "\ec1b"; }

.icon-crosshair:before {
  content: "\ec1c"; }

.icon-database2:before {
  content: "\ec1d"; }

.icon-delete:before {
  content: "\ec1e"; }

.icon-disc:before {
  content: "\ec1f"; }

.icon-dollar-sign:before {
  content: "\ec20"; }

.icon-download2:before {
  content: "\ec21"; }

.icon-download-cloud:before {
  content: "\ec22"; }

.icon-droplet:before {
  content: "\ec23"; }

.icon-edit2:before {
  content: "\ec24"; }

.icon-edit-2:before {
  content: "\ec25"; }

.icon-edit-3:before {
  content: "\ec26"; }

.icon-external-link2:before {
  content: "\ec27"; }

.icon-eye2:before {
  content: "\ec28"; }

.icon-eye-off:before {
  content: "\ec29"; }

.icon-facebook2:before {
  content: "\ec2a"; }

.icon-fast-forward2:before {
  content: "\ec2b"; }

.icon-feather:before {
  content: "\ec2c"; }

.icon-file2:before {
  content: "\ec2d"; }

.icon-file-minus:before {
  content: "\ec2e"; }

.icon-file-plus:before {
  content: "\ec2f"; }

.icon-file-text2:before {
  content: "\ec30"; }

.icon-film2:before {
  content: "\ec31"; }

.icon-filter2:before {
  content: "\ec32"; }

.icon-flag2:before {
  content: "\ec33"; }

.icon-folder2:before {
  content: "\ec34"; }

.icon-folder-minus:before {
  content: "\ec35"; }

.icon-folder-plus:before {
  content: "\ec36"; }

.icon-gift2:before {
  content: "\ec37"; }

.icon-git-branch:before {
  content: "\ec38"; }

.icon-git-commit:before {
  content: "\ec39"; }

.icon-git-merge:before {
  content: "\ec3a"; }

.icon-git-pull-request:before {
  content: "\ec3b"; }

.icon-github2:before {
  content: "\ec3c"; }

.icon-gitlab2:before {
  content: "\ec3d"; }

.icon-globe2:before {
  content: "\ec3e"; }

.icon-grid:before {
  content: "\ec3f"; }

.icon-hard-drive:before {
  content: "\ec40"; }

.icon-hash:before {
  content: "\ec41"; }

.icon-headphones2:before {
  content: "\ec42"; }

.icon-heart2:before {
  content: "\ec43"; }

.icon-help-circle:before {
  content: "\ec44"; }

.icon-home2:before {
  content: "\ec45"; }

.icon-image2:before {
  content: "\ec46"; }

.icon-inbox2:before {
  content: "\ec47"; }

.icon-info2:before {
  content: "\ec48"; }

.icon-instagram2:before {
  content: "\ec49"; }

.icon-italic2:before {
  content: "\ec4a"; }

.icon-layers:before {
  content: "\ec4b"; }

.icon-layout:before {
  content: "\ec4c"; }

.icon-life-buoy2:before {
  content: "\ec4d"; }

.icon-link2:before {
  content: "\ec4e"; }

.icon-link-2:before {
  content: "\ec4f"; }

.icon-linkedin2:before {
  content: "\ec50"; }

.icon-list2:before {
  content: "\ec51"; }

.icon-loader:before {
  content: "\ec52"; }

.icon-lock2:before {
  content: "\ec53"; }

.icon-log-in:before {
  content: "\ec54"; }

.icon-log-out:before {
  content: "\ec55"; }

.icon-mail:before {
  content: "\ec56"; }

.icon-map2:before {
  content: "\ec57"; }

.icon-map-pin2:before {
  content: "\ec58"; }

.icon-maximize:before {
  content: "\ec59"; }

.icon-maximize-2:before {
  content: "\ec5a"; }

.icon-menu:before {
  content: "\ec5b"; }

.icon-message-circle:before {
  content: "\ec5c"; }

.icon-message-square:before {
  content: "\ec5d"; }

.icon-mic:before {
  content: "\ec5e"; }

.icon-mic-off:before {
  content: "\ec5f"; }

.icon-minimize:before {
  content: "\ec60"; }

.icon-minimize-2:before {
  content: "\ec61"; }

.icon-minus2:before {
  content: "\ec62"; }

.icon-minus-circle2:before {
  content: "\ec63"; }

.icon-minus-square2:before {
  content: "\ec64"; }

.icon-monitor:before {
  content: "\ec65"; }

.icon-moon:before {
  content: "\ec66"; }

.icon-more-horizontal:before {
  content: "\ec67"; }

.icon-more-vertical:before {
  content: "\ec68"; }

.icon-move:before {
  content: "\ec69"; }

.icon-music2:before {
  content: "\ec6a"; }

.icon-navigation:before {
  content: "\ec6b"; }

.icon-navigation-2:before {
  content: "\ec6c"; }

.icon-octagon:before {
  content: "\ec6d"; }

.icon-package:before {
  content: "\ec6e"; }

.icon-paperclip2:before {
  content: "\ec6f"; }

.icon-pause2:before {
  content: "\ec70"; }

.icon-pause-circle2:before {
  content: "\ec71"; }

.icon-percent2:before {
  content: "\ec72"; }

.icon-phone2:before {
  content: "\ec73"; }

.icon-phone-call:before {
  content: "\ec74"; }

.icon-phone-forwarded:before {
  content: "\ec75"; }

.icon-phone-incoming:before {
  content: "\ec76"; }

.icon-phone-missed:before {
  content: "\ec77"; }

.icon-phone-off:before {
  content: "\ec78"; }

.icon-phone-outgoing:before {
  content: "\ec79"; }

.icon-pie-chart2:before {
  content: "\ec7a"; }

.icon-play2:before {
  content: "\ec7b"; }

.icon-play-circle2:before {
  content: "\ec7c"; }

.icon-plus2:before {
  content: "\ec7d"; }

.icon-plus-circle2:before {
  content: "\ec7e"; }

.icon-plus-square2:before {
  content: "\ec7f"; }

.icon-pocket:before {
  content: "\ec80"; }

.icon-power:before {
  content: "\ec81"; }

.icon-printer:before {
  content: "\ec82"; }

.icon-radio:before {
  content: "\ec83"; }

.icon-refresh-ccw:before {
  content: "\ec84"; }

.icon-refresh-cw:before {
  content: "\ec85"; }

.icon-repeat2:before {
  content: "\ec86"; }

.icon-rewind:before {
  content: "\ec87"; }

.icon-rotate-ccw:before {
  content: "\ec88"; }

.icon-rotate-cw:before {
  content: "\ec89"; }

.icon-rss2:before {
  content: "\ec8a"; }

.icon-save2:before {
  content: "\ec8b"; }

.icon-scissors2:before {
  content: "\ec8c"; }

.icon-search2:before {
  content: "\ec8d"; }

.icon-send2:before {
  content: "\ec8e"; }

.icon-server2:before {
  content: "\ec8f"; }

.icon-settings:before {
  content: "\ec90"; }

.icon-share2:before {
  content: "\ec91"; }

.icon-share-2:before {
  content: "\ec92"; }

.icon-shield2:before {
  content: "\ec93"; }

.icon-shield-off:before {
  content: "\ec94"; }

.icon-shopping-bag2:before {
  content: "\ec95"; }

.icon-shopping-cart2:before {
  content: "\ec96"; }

.icon-shuffle:before {
  content: "\ec97"; }

.icon-sidebar:before {
  content: "\ec98"; }

.icon-skip-back:before {
  content: "\ec99"; }

.icon-skip-forward:before {
  content: "\ec9a"; }

.icon-slack2:before {
  content: "\ec9b"; }

.icon-slash:before {
  content: "\ec9c"; }

.icon-sliders2:before {
  content: "\ec9d"; }

.icon-smartphone:before {
  content: "\ec9e"; }

.icon-speaker:before {
  content: "\ec9f"; }

.icon-square2:before {
  content: "\eca0"; }

.icon-star2:before {
  content: "\eca1"; }

.icon-stop-circle2:before {
  content: "\eca2"; }

.icon-sun:before {
  content: "\eca3"; }

.icon-sunrise:before {
  content: "\eca4"; }

.icon-sunset:before {
  content: "\eca5"; }

.icon-tablet2:before {
  content: "\eca6"; }

.icon-tag2:before {
  content: "\eca7"; }

.icon-target:before {
  content: "\eca8"; }

.icon-terminal2:before {
  content: "\eca9"; }

.icon-thermometer2:before {
  content: "\ecaa"; }

.icon-thumbs-down2:before {
  content: "\ecab"; }

.icon-thumbs-up2:before {
  content: "\ecac"; }

.icon-toggle-left2:before {
  content: "\ecad"; }

.icon-toggle-right2:before {
  content: "\ecae"; }

.icon-trash2:before {
  content: "\ecaf"; }

.icon-trash-2:before {
  content: "\ecb0"; }

.icon-trending-down:before {
  content: "\ecb1"; }

.icon-trending-up:before {
  content: "\ecb2"; }

.icon-triangle:before {
  content: "\ecb3"; }

.icon-truck2:before {
  content: "\ecb4"; }

.icon-tv2:before {
  content: "\ecb5"; }

.icon-twitter2:before {
  content: "\ecb6"; }

.icon-type:before {
  content: "\ecb7"; }

.icon-umbrella2:before {
  content: "\ecb8"; }

.icon-underline2:before {
  content: "\ecb9"; }

.icon-unlock2:before {
  content: "\ecba"; }

.icon-upload2:before {
  content: "\ecbb"; }

.icon-upload-cloud:before {
  content: "\ecbc"; }

.icon-user2:before {
  content: "\ecbd"; }

.icon-user-check:before {
  content: "\ecbe"; }

.icon-user-minus:before {
  content: "\ecbf"; }

.icon-user-plus2:before {
  content: "\ecc0"; }

.icon-user-x:before {
  content: "\ecc1"; }

.icon-users2:before {
  content: "\ecc2"; }

.icon-video:before {
  content: "\ecc3"; }

.icon-video-off:before {
  content: "\ecc4"; }

.icon-voicemail:before {
  content: "\ecc5"; }

.icon-volume:before {
  content: "\ecc6"; }

.icon-volume-1:before {
  content: "\ecc7"; }

.icon-volume-2:before {
  content: "\ecc8"; }

.icon-volume-x:before {
  content: "\ecc9"; }

.icon-watch:before {
  content: "\ecca"; }

.icon-wifi2:before {
  content: "\eccb"; }

.icon-wifi-off:before {
  content: "\eccc"; }

.icon-wind:before {
  content: "\eccd"; }

.icon-x:before {
  content: "\ecce"; }

.icon-x-circle:before {
  content: "\eccf"; }

.icon-x-square:before {
  content: "\ecd0"; }

.icon-youtube2:before {
  content: "\ecd1"; }

.icon-zap:before {
  content: "\ecd2"; }

.icon-zap-off:before {
  content: "\ecd3"; }

.icon-zoom-in:before {
  content: "\ecd4"; }

.icon-zoom-out:before {
  content: "\ecd5"; }

.widget {
  padding: 30px; }
  .widget:not(:first-child) {
    margin-top: 15px; }

.widget-title {
  margin-bottom: 20px;
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: 600;
  color: #555555; }

.external,
.code,
.kbd,
.scroll,
.folder,
.file, .boxed, .accordion-list .card:hover, .accordion-list .card.active, .breadcrumb-back:hover, .card, .card-price, .pagination .page-link:not(.active):hover, .owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next, .tags a:hover, .figure .figure-caption, pre[class*="language-"], .map-box {
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  border-radius: 0.25rem;
  border: 1px solid #e5e5e5;
  background: white; }

.bordered {
  border-radius: 0.25rem;
  border: 1px solid #e5e5e5; }

.no-border {
  border-color: transparent; }

.boxed > .row {
  margin-left: 0;
  margin-right: 0; }

.stacked, .accordion-stack .card {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  position: relative;
  background: #fff; }
  .stacked::before, .accordion-stack .card::before {
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    transition-delay: 0s;
    border-radius: 0.25rem;
    opacity: .75;
    content: "";
    position: absolute;
    left: 4px;
    right: 4px;
    bottom: -8px;
    display: block;
    height: 100%;
    background: #fff;
    border: 1px solid #e5e5e5;
    z-index: -1; }
  .stacked:hover::before, .accordion-stack .card:hover::before {
    bottom: -12px; }

.equal, .type, .map-box {
  position: relative; }
  .equal::before, .type::before, .map-box::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 100%; }
  .equal > *, .type > *, .map-box > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex; }
  .equal > a, .type > a, .map-box > a {
    color: inherit; }
    .equal > a:hover, .type > a:hover, .map-box > a:hover {
      color: inherit; }
  .equal[class*="-short"]::before, .type[class*="-short"]::before, .map-box[class*="-short"]::before {
    padding-top: 75%; }
  .equal[class*="-long"]::before, .type[class*="-long"]::before, .map-box[class*="-long"]::before {
    padding-top: 125%; }

.lift, .card.bordered.lift {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s; }
  .lift:hover, .card.bordered.lift:hover {
    -webkit-transform: translate(0, -10px);
    -moz-transform: translate(0, -10px);
    transform: translate(0, -10px);
    position: relative;
    z-index: 50;
    -webkit-box-shadow: 0px 10px 15px 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 10px 15px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0px 10px 15px 0 rgba(0, 0, 0, 0.1); }

.scale {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s; }
  .scale:hover {
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    transform: scale(1.05);
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
    background: #fff; }

.equal .equal-header,
.equal .equal-footer {
  position: absolute;
  right: 0;
  left: 0; }

.equal .equal-header {
  top: 0; }

.equal .equal-footer {
  bottom: 0; }

.equal .image {
  z-index: 0; }

.equal .row {
  height: 100%; }

.highlight {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s; }
  .highlight:hover {
    border-color: #064CDB;
    color: #064CDB; }

.separated {
  overflow: hidden; }
  .separated > * {
    -webkit-box-shadow: -1px -1px 0px 0px #e5e5e5;
    -moz-box-shadow: -1px -1px 0px 0px #e5e5e5;
    box-shadow: -1px -1px 0px 0px #e5e5e5; }
    .separated > *:not(:last-child) {
      margin-bottom: 0 !important; }

.text-white .separated > * {
  -webkit-box-shadow: -1px -1px 0px 0px rgba(255, 255, 255, 0.1);
  -moz-box-shadow: -1px -1px 0px 0px rgba(255, 255, 255, 0.1);
  box-shadow: -1px -1px 0px 0px rgba(255, 255, 255, 0.1); }

.icon-boxed {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  width: 3.125rem;
  height: 3.125rem;
  background: #f5f5f5;
  color: #222;
  border-radius: 50%;
  font-size: 1.125rem; }
  .icon-boxed[class*="-sm"] {
    width: 2.5rem;
    height: 2.5rem; }
  .icon-boxed[class*="-lg"] {
    width: 5rem;
    height: 5rem;
    font-size: 1.75rem; }

.icon-background {
  position: absolute;
  top: -15%;
  right: -10%;
  opacity: .1;
  font-size: 12.5rem; }


@media (min-width: 992px) {
  .navbar-nav .nav-item + .nav-item {
    margin-left: .5rem; } }

.navbar-nav .nav-link {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  font-size: 0.875rem;
  text-transform: uppercase; }
  @media (min-width: 992px) {
    .navbar-nav .nav-link {
      padding: .9375rem;
      line-height: 2.375rem;
      border-top: 1px solid transparent; }
      .navbar-nav .nav-link:hover {
        border-color: #e5e5e5; }
      .navbar-nav .nav-link.active {
        border-color: #fff; } }

@media (max-width: 991.98px) {
  .navbar-nav {
    margin-top: 30px; } }

.navbar-brand {
  margin-right: 30px;
  padding: 0; }
  .navbar-brand img {
    max-height: 5.375rem; }

.navbar-toggler {
  padding: 0;
  border: 0; }

.navbar-vertical {
  display: block;
  padding: 0; }
  .navbar-vertical .navbar-collapse {
    margin-top: 30px;
    margin-right: -30px;
    margin-left: -30px; }
  .navbar-vertical .nav-vertical {
    padding: 0; }

.burger {
  display: inline-block;
  position: relative;
  width: 2.375rem;
  height: 2.375rem;
  z-index: 100; }
  .burger span {
    position: relative;
    margin-top: 6px;
    margin-bottom: 6px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    user-select: none;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -10px;
    margin-top: -1.5px; }
    .burger span, .burger span::before, .burger span::after {
      display: block;
      width: 20px;
      height: 2px;
      background-color: #fff;
      outline: 1px solid transparent;
      -webkit-transition-property: background-color;
      -moz-transition-property: background-color;
      -o-transition-property: background-color;
      transition-property: background-color;
      transition-property: background-color, transform;
      -webkit-transition-duration: 0.3s;
      -moz-transition-duration: 0.3s;
      -o-transition-duration: 0.3s;
      transition-duration: 0.3s;
      transition-duration: 0.3s; }
    .burger span::before, .burger span::after {
      position: absolute;
      content: ""; }
    .burger span::before {
      top: -6px; }
    .burger span::after {
      top: 6px; }
  .burger:hover {
    cursor: pointer; }

.navbar.active .burger span {
  background-color: transparent; }

.navbar.active .burger span::before {
  -webkit-transform: translateY(6px) rotate(45deg);
  -moz-transform: translateY(6px) rotate(45deg);
  -ms-transform: translateY(6px) rotate(45deg);
  -o-transform: translateY(6px) rotate(45deg);
  transform: translateY(6px) rotate(45deg);
  transform: translateY(6px) rotate(45deg); }

.navbar.active .burger span::after {
  -webkit-transform: translateY(-6px) rotate(-45deg);
  -moz-transform: translateY(-6px) rotate(-45deg);
  -ms-transform: translateY(-6px) rotate(-45deg);
  -o-transform: translateY(-6px) rotate(-45deg);
  transform: translateY(-6px) rotate(-45deg);
  transform: translateY(-6px) rotate(-45deg); }

.photo {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  border-radius: 0.25rem;
  position: relative;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black); }
  .photo img {
    width: 100%; }
  .photo a {
    display: block;
    margin: 0;
    background-size: cover;
    background-position: center; }
    .photo a::before {
      border-radius: 0.25rem;
      -webkit-transition: all 0.2s;
      -moz-transition: all 0.2s;
      transition: all 0.2s;
      -webkit-transition-delay: 0s;
      -moz-transition-delay: 0s;
      transition-delay: 0s;
      opacity: 0;
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.2); }
  .photo:hover a:before {
    opacity: 0.75; }
  .photo:hover .photo-caption {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1; }

.photo-caption {
  -webkit-transform: translate(0, 100%);
  -moz-transform: translate(0, 100%);
  transform: translate(0, 100%);
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  text-shadow: 0px 0px 25px rgba(0, 0, 0, 0.4);
  position: absolute;
  bottom: .625rem;
  left: .625rem;
  padding: .5rem;
  opacity: 0;
  color: #fff; }

.image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  z-index: -1; }
  .image[class*="-gradient-vertical"] {
    -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.2)), to(rgba(0, 0, 0, 0))); }
  .image[class*="-gradient-vertical-invert"] {
    -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.2))); }
  .image[class*="-overlay"]::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    background: rgba(0, 0, 0, 0.55); }

.no-cssmask .image[class*="-gradient-vertical"], .no-cssmask .image[class*="-gradient-vertical-invert"] {
  display: none; }

.scroll-spy {
  height: 100vh;
  overflow-y: scroll; }

.accordion .card {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  overflow: visible; }
  .accordion .card .card-header {
    padding: 0;
    border: 0;
    background: transparent; }
    .accordion .card .card-header > * {
      font-family: "Open Sans", sans-serif;
      font-weight: 400; }
    .accordion .card .card-header button {
      -webkit-transition: all 0.2s;
      -moz-transition: all 0.2s;
      transition: all 0.2s;
      -webkit-transition-delay: 0s;
      -moz-transition-delay: 0s;
      transition-delay: 0s;
      position: relative;
      width: 100%;
      padding: 20px 30px;
      text-align: left;
      text-transform: none;
      font-weight: 400;
      font-size: 1rem;
      letter-spacing: 0;
      color: inherit;
      white-space: normal; }
      .accordion .card .card-header button:hover {
        text-decoration: none; }
      .accordion .card .card-header button::before {
        position: absolute;
        content: "\e145";
        width: 1em;
        line-height: 1em;
        right: 30px;
        top: 50%;
        font-family: 'Material Symbols Outlined' !important;
        font-size: 1.5rem;
        margin-top: -0.5em;
        color: #999999;
        -webkit-transition: all 0.2s;
        -moz-transition: all 0.2s;
        transition: all 0.2s;
        -webkit-transition-delay: 0s;
        -moz-transition-delay: 0s;
        transition-delay: 0s; }
      .accordion .card .card-header button[aria-expanded="true"]::before {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        transform: rotate(180deg); }
  .accordion .card .card-body {
    padding-top: 0; }

.accordion-boxed {
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05); }
  .accordion-boxed .card {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important; }
    .accordion-boxed .card .card-header button {
      padding: 20px 60px 20px 30px; }

.accordion-bordered {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  background: transparent; }
  .accordion-bordered .card {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    background: transparent; }
    .accordion-bordered .card .card-header button {
      padding: 20px 60px 20px 30px; }

.accordion-fluid .card {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  border-color: transparent;
  background: transparent; }
  .accordion-fluid .card::before, .accordion-fluid .card::after {
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    transition-delay: 0s;
    content: "";
    position: absolute;
    display: block;
    top: -1px;
    bottom: -1px;
    width: 100vw; }
  .accordion-fluid .card::before {
    right: 100%; }
  .accordion-fluid .card::after {
    left: 100%; }
  .accordion-fluid .card:not(:first-of-type):not(:last-of-type), .accordion-fluid .card:first-of-type {
    border-bottom: 1px solid #e5e5e5; }
    .accordion-fluid .card:not(:first-of-type):not(:last-of-type)::before, .accordion-fluid .card:not(:first-of-type):not(:last-of-type)::after, .accordion-fluid .card:first-of-type::before, .accordion-fluid .card:first-of-type::after {
      border-bottom: 1px solid #e5e5e5; }
  .accordion-fluid .card .card-header button {
    padding: 3.125rem 60px 3.125rem 0;
    font-size: 1.25rem; }
  .accordion-fluid .card .card-body {
    padding: 0 0 60px 0; }
  .accordion-fluid .card.active {
    background: #fff; }
    .accordion-fluid .card.active::before, .accordion-fluid .card.active::after {
      background: #fff; }

.accordion-stack {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  background: transparent; }
  .accordion-stack .card.active::before {
    bottom: 0; }
  .accordion-stack .card:not(:first-of-type):not(:last-of-type), .accordion-stack .card:first-of-type {
    border-radius: 0.25rem;
    border-bottom: 1px solid #e5e5e5; }
  .accordion-stack .card:last-of-type {
    border-radius: 0.25rem; }
  .accordion-stack .card .card-header button {
    position: relative;
    width: 100%;
    text-align: left;
    font-size: 1.125rem; }
    .accordion-stack .card .card-header button::before {
      position: static;
      content: "\e145";
      display: inline-flex;
      align-items: center;
      justify-content: center;
      vertical-align: middle;
      width: 2.5rem;
      height: 2.5rem;
      margin-right: 20px;
      border-radius: 50%;
      font-family: 'Material Symbols Outlined' !important;
      margin-top: 0;
      background: #064CDB;
      color: #fff; }
    .accordion-stack .card .card-header button[aria-expanded="true"]::before {
      content: "\e15b";
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      transform: rotate(0deg); }
  .accordion-stack .card + .card {
    margin-top: 15px; }

.accordion-changelog .card .card-header button {
  padding: 30px 70px; }

.accordion-changelog .card:hover .label {
  -webkit-transform: scale(1.5);
  -moz-transform: scale(1.5);
  transform: scale(1.5); }

.accordion-changelog .card:last-of-type {
  border-bottom: 1px solid #e5e5e5;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.accordion-changelog .label {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  position: absolute;
  top: 50%;
  left: 30px;
  display: block;
  width: 10px;
  height: 10px;
  margin-top: -5px;
  border-radius: 50%;
  background: #f8f8f8; }

.accordion-list {
  background: transparent; }
  .accordion-list .card {
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    transition-delay: 0s;
    box-shadow: none;
    border-radius: 0.25rem;
    padding-left: 3.125rem;
    background: transparent;
    border-color: transparent;
    counter-increment: accordion; }
    .accordion-list .card:not(:first-of-type):not(:last-of-type), .accordion-list .card:first-of-type {
      border-bottom: 1px solid transparent;
      border-radius: 0.25rem; }
    .accordion-list .card:not(:first-child) {
      margin-top: 10px; }
    .accordion-list .card:hover:not(:first-of-type):not(:last-of-type), .accordion-list .card:hover:first-of-type {
      border-bottom-color: #e5e5e5; }
    .accordion-list .card::before {
      -webkit-transform: translate(0, -50%);
      -moz-transform: translate(0, -50%);
      transform: translate(0, -50%);
      -webkit-transition: all 0.2s;
      -moz-transition: all 0.2s;
      transition: all 0.2s;
      -webkit-transition-delay: 0s;
      -moz-transition-delay: 0s;
      transition-delay: 0s;
      content: counter(accordion, decimal);
      position: absolute;
      display: block;
      top: 50%;
      left: 10px;
      width: 3.125rem;
      height: 3.125rem;
      color: #fff;
      line-height: 3.125rem;
      text-align: center;
      z-index: 20;
      border-radius: 0.25rem;
      background: #999999;
      font-size: 1rem;
      font-weight: 600; }
    .accordion-list .card.active:not(:first-of-type):not(:last-of-type), .accordion-list .card.active:first-of-type {
      border-bottom-color: #e5e5e5; }
    .accordion-list .card.active::before {
      -webkit-transform: translate(-20px, -50%);
      -moz-transform: translate(-20px, -50%);
      transform: translate(-20px, -50%);
      background: #064CDB;
      color: #fff; }
    .accordion-list .card .card-header button {
      line-height: 30px;
      padding-top: 20px;
      padding-bottom: 20px; }
      .accordion-list .card .card-header button::before {
        display: none; }

.breadcrumb {
  display: inline-flex;
  padding: 0.9375rem;
  border: 1px solid #e5e5e5;
  background: transparent; }

.breadcrumb-item {
  font-size: .75rem;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.05em; }
  .breadcrumb-item a {
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    transition-delay: 0s;
    color: #000; }

.breadcrumb-back {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  position: relative;
  width: 50px;
  height: 50px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  text-align: center;
  border: 1px solid #e5e5e5; }
  .breadcrumb-back::before {
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    transition-delay: 0s;
    position: absolute;
    content: "\ebdc";
    top: 50%;
    left: 50%;
    font-family: 'icomoon' !important;
    font-size: 1.125rem;
    color: #000; }
  .breadcrumb-back:not(:last-child) {
    margin-right: 15px; }
  .breadcrumb-back:hover {
    border-radius: 50%; }

.text-white .breadcrumb {
  border: 0;
  background: rgba(0, 0, 0, 0.1); }

.text-white .breadcrumb-item {
  color: rgba(255, 255, 255, 0.5); }
  .text-white .breadcrumb-item a {
    color: #fff; }
    .text-white .breadcrumb-item a:hover {
      color: rgba(255, 255, 255, 0.75); }

.dropdown .nav-link {
  outline: 0; }

.dropdown .dropdown-menu {
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
  background: #fff;
  border-radius: 0.25rem;
  overflow: hidden;
  margin: 0;
  padding: 0;
  border: 0; }
  .dropdown .dropdown-menu .dropdown-item {
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    transition-delay: 0s;
    position: relative;
    padding: .5rem 1rem;
    font-size: 0.875rem;
    color: #555555; }
    .dropdown .dropdown-menu .dropdown-item:hover {
      background-color: rgba(0, 0, 0, 0.1);
      color: #000; }
    .dropdown .dropdown-menu .dropdown-item.active {
      color: #fff;
      background: #999999; }
      .dropdown .dropdown-menu .dropdown-item.active:hover {
        background-color: #999999; }

@media (min-width: 992px) {
  .navbar .dropdown-menu.show {
    animation: dropdown .2s ease forwards; } }

@keyframes dropdown {
  0% {
    opacity: 0;
    transform: translateY(1rem); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

.nav {
  position: relative; }
  .nav .nav-item {
    display: inline-block; }
  .nav .nav-link {
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    transition-delay: 0s;
    display: inline-block;
    color: #999999; }
    .nav .nav-link.active {
      color: #111111; }
  .nav .lavalamp-object {
    display: none;
    z-index: 1; }

.nav-pills {
  display: inline-flex;
  background: #e5e5e5;
  border-radius: 0.25rem;
  padding: 2px; }
  .nav-pills .nav-link {
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: 600;
    color: #999; }
    .nav-pills .nav-link.active {
      background: transparent; }
  .nav-pills .lavalamp-object {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    border-radius: 0.25rem; }

.nav-tabs .nav-link {
  border: 0; }

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: transparent; }

.nav-tabs .lavalamp-object {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  border-bottom: 2px solid #064CDB;
  border-radius: 0; }

.card-header-tabs {
  margin: -30px;
  margin-bottom: -30px !important; }
  .card-header-tabs:not(.nav-justified) {
    margin-left: 0;
    margin-right: 0; }
  .card-header-tabs .nav-item a {
    display: block;
    line-height: 1.875rem;
    padding-top: 20px;
    padding-bottom: 20px; }

.card-header-pills {
  margin-top: 10px;
  margin-right: 0;
  margin-left: 0; }

.nav-toc-page {
  position: relative;
  margin-top: 40px;
  padding: 15px;
  border: 1px solid #e5e5e5;
  border-radius: 0.25rem;
  border-top-left-radius: 0; }
  .nav-toc-page::before {
    position: absolute;
    top: -40px;
    left: -1px;
    content: "Table of Contents";
    padding: 0 30px;
    line-height: 40px;
    color: #adb5bd;
    font-size: .75rem;
    background: #e5e5e5;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .nav-toc-page.boxed::before {
    background: #fff;
    border-top: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
    border-left: 1px solid #e5e5e5; }
  .nav-toc-page .nav-item {
    counter-increment: item; }
  .nav-toc-page .nav-link {
    position: relative;
    width: 100%;
    font-size: .9375rem;
    color: #555555;
    border-radius: 0.25rem;
    padding: 15px; }
    .nav-toc-page .nav-link::before {
      position: absolute;
      right: 15px;
      color: #999999;
      content: "0" counter(item); }
    .nav-toc-page .nav-link::after {
      -webkit-transition: all 0.2s;
      -moz-transition: all 0.2s;
      transition: all 0.2s;
      -webkit-transition-delay: 0s;
      -moz-transition-delay: 0s;
      transition-delay: 0s;
      opacity: 0;
      margin-left: 1rem;
      content: "view now";
      text-transform: uppercase;
      letter-spacing: 0.05em;
      font-size: 12px;
      color: #999; }
    .nav-toc-page .nav-link:hover {
      background: #e5e5e5;
      color: #000; }
      .nav-toc-page .nav-link:hover::after {
        opacity: 1; }

.nav-vertical {
  width: 100%;
  border-radius: 0.25rem;
  padding: .625rem; }
  .nav-vertical .nav-item:not(:first-child) {
    margin-top: 2px; }
  .nav-vertical .nav-link {
    border-radius: 0.25rem;
    display: block;
    padding: 1.25rem; }
    .nav-vertical .nav-link:hover, .nav-vertical .nav-link.active {
      color: #555555;
      background: rgba(0, 0, 0, 0.05); }
    .nav-vertical .nav-link[aria-expanded] {
      position: relative; }
      .nav-vertical .nav-link[aria-expanded]::after {
        position: absolute;
        content: "\ebfa";
        width: 1em;
        line-height: 1em;
        right: 1rem;
        top: 50%;
        font-family: 'icomoon' !important;
        font-size: 1.2rem;
        margin-top: -0.5em;
        color: #999999;
        -webkit-transition: all 0.2s;
        -moz-transition: all 0.2s;
        transition: all 0.2s;
        -webkit-transition-delay: 0s;
        -moz-transition-delay: 0s;
        transition-delay: 0s; }
    .nav-vertical .nav-link[aria-expanded="true"]::after {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      transform: rotate(180deg); }
  .nav-vertical .nav {
    margin-top: 2px; }
    .nav-vertical .nav .nav-link {
      padding: .5rem 1.25rem .5rem 1.875rem;
      font-size: 0.875rem; }

.nav-vertical-2 {
  width: 100%; }
  .nav-vertical-2 .nav-item {
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    transition-delay: 0s; }
    .nav-vertical-2 .nav-item.active {
      padding-bottom: 15px; }
  .nav-vertical-2 .nav-link {
    color: #000;
    display: block;
    border-right: 2px solid transparent;
    padding: 15px 30px; }
    .nav-vertical-2 .nav-link:hover {
      background: rgba(0, 0, 0, 0.05); }
    .nav-vertical-2 .nav-link.active {
      color: #064CDB;
      border-color: #064CDB; }
    .nav-vertical-2 .nav-link[aria-expanded] {
      position: relative; }
      .nav-vertical-2 .nav-link[aria-expanded]::after {
        position: absolute;
        content: "\ebfa";
        width: 1em;
        line-height: 1em;
        right: 30px;
        top: 50%;
        font-family: 'icomoon' !important;
        font-size: 1.2rem;
        margin-top: -0.5em;
        color: #999999;
        -webkit-transition: all 0.2s;
        -moz-transition: all 0.2s;
        transition: all 0.2s;
        -webkit-transition-delay: 0s;
        -moz-transition-delay: 0s;
        transition-delay: 0s; }
    .nav-vertical-2 .nav-link[aria-expanded="true"]::after {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      transform: rotate(180deg); }
  .nav-vertical-2 .nav {
    margin-top: 2px; }
    .nav-vertical-2 .nav .nav-link {
      padding: .5rem 1.25rem .5rem 1.875rem;
      font-size: 0.875rem;
      color: #555555; }
      .nav-vertical-2 .nav .nav-link.active {
        color: #064CDB; }

.nav-dots {
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  transform: translate(0, -50%);
  position: fixed;
  top: 50%;
  right: 60px;
  z-index: 50;
  width: .75rem; }
  @media (max-width: 767.98px) {
    .nav-dots {
      display: none; } }
  .nav-dots .nav-item {
    display: block; }
    .nav-dots .nav-item + .nav-item {
      margin-top: 1rem; }
  .nav-dots .nav-link {
    position: relative;
    display: block;
    width: .75rem;
    height: .75rem;
    border-radius: 50%;
    padding: 0;
    text-align: center;
    line-height: 50px;
    color: #fff;
    box-shadow: inset 0 0 0 10px rgba(0, 0, 0, 0.25); }
    .nav-dots .nav-link:hover {
      box-shadow: inset 0 0 0 10px rgba(0, 0, 0, 0.5); }
    .nav-dots .nav-link.active {
      -webkit-transform: scale(1.4);
      -moz-transform: scale(1.4);
      transform: scale(1.4);
      box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.5); }

.text-white .nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .text-white .nav .nav-link:hover, .text-white .nav .nav-link.active {
    color: #fff;
    background: rgba(255, 255, 255, 0.1); }
  .text-white .nav .nav-link[aria-expanded] {
    position: relative; }
    .text-white .nav .nav-link[aria-expanded]::after {
      color: rgba(255, 255, 255, 0.5); }

.scroll {
  max-height: 100vh;
  overflow-y: scroll; }

html.active,
html.active > body {
  overflow: hidden; }

.overlay-menu-open {
  display: none; }

@media (max-width: 991.98px) {
  .overlay-menu-open {
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    transition-delay: 0s;
    position: fixed;
    bottom: 15px;
    left: 15px;
    right: 15px;
    display: block;
    height: 60px;
    text-align: center;
    line-height: 60px;
    background: #064CDB;
    color: #fff;
    border-radius: 0.25rem;
    cursor: pointer;
    z-index: 120; }
    .overlay-menu-open:focus {
      background: #053fb6; }
    .overlay-menu-open:before {
      content: "Table of Contents";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      text-align: center;
      line-height: 60px;
      text-transform: uppercase;
      font-size: 0.875rem;
      letter-spacing: 0.05em;
      font-weight: 600; }
    .overlay-menu-open.active {
      bottom: 15px !important; }
      .overlay-menu-open.active::before {
        content: "Close"; }
  .overlay-menu {
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    transition-delay: 0s;
    position: fixed;
    top: 100%;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
    margin: 0 !important;
    padding: 0;
    border: 0;
    border-radius: 0;
    overflow-x: scroll; }
    .overlay-menu .nav-vertical {
      margin-bottom: 80px; }
    .overlay-menu.active {
      top: 0; }
  .headroom--unpinned .overlay-menu-open {
    top: auto;
    bottom: -75px; } }

.card .card-body {
  position: relative;
  overflow: hidden; }

.card .card-header,
.card .card-footer {
  padding: 30px; }

.card.bordered {
  box-shadow: none;
  background: transparent; }
  .card.bordered .card-header {
    border-bottom: 1px solid #e5e5e5; }
  .card.bordered .card-footer {
    border-top: 1px solid #e5e5e5; }

.card-body.action,
.card-header.action,
.card-footer.action {
  display: block;
  padding-right: 60px; }
  .card-body.action::after,
  .card-header.action::after,
  .card-footer.action::after {
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    transition-delay: 0s;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    transform: translate(0, -50%);
    position: absolute;
    right: 45px;
    top: 50%;
    content: "\ebde";
    display: block;
    font-family: 'icomoon' !important;
    font-size: 1em;
    width: 10px;
    line-height: 1em; }
  .card-body.action:hover::after,
  .card-header.action:hover::after,
  .card-footer.action:hover::after {
    right: 30px; }

.card a.card-footer {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  padding: 20px 30px;
  text-transform: uppercase;
  text-align: center;
  font-size: 0.875rem;
  letter-spacing: 0.05em;
  color: #555555; }
  .card a.card-footer:hover {
    background: #e5e5e5; }

a.card {
  display: block; }

.card-background {
  overflow: hidden; }
  .card-background:hover .image::after {
    background: rgba(6, 76, 219, 0.75); }
  .card-background > * {
    z-index: 10; }
  .card-background .image {
    z-index: 5; }
    .card-background .image::after {
      -webkit-transition: all 0.2s;
      -moz-transition: all 0.2s;
      transition: all 0.2s;
      -webkit-transition-delay: 0s;
      -moz-transition-delay: 0s;
      transition-delay: 0s;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: "";
      background: rgba(0, 0, 0, 0.5); }
  .card-background .card-title {
    color: #fff; }
  .card-background .card-text {
    color: rgba(255, 255, 255, 0.75); }
  .card-background .card-footer {
    background: transparent;
    color: #fff;
    border-color: rgba(255, 255, 255, 0.25); }
    .card-background .card-footer:hover {
      background: rgba(255, 255, 255, 0.25);
      border-color: transparent; }

.card-price {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  color: #000;
  display: block;
  width: 6.25rem;
  height: 6.25rem;
  margin-top: -30px;
  border-radius: 0.25rem;
  text-align: center;
  line-height: 6.25rem;
  font-size: 1.875rem; }

.card:hover .card-price {
  -webkit-transform: translate(0, -10px);
  -moz-transform: translate(0, -10px);
  transform: translate(0, -10px); }

.card-entry {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  background: transparent;
  border-color: transparent; }
  .card-entry .card-img-top {
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
    border-radius: 0.25rem; }
  .card-entry .card-body {
    padding: 20px 0 0 0; }
  .card-entry .card-video {
    position: relative; }
    .card-entry .card-video::after {
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 0.25rem; }
    .card-entry .card-video::before {
      -webkit-transition: all 0.2s;
      -moz-transition: all 0.2s;
      transition: all 0.2s;
      -webkit-transition-delay: 0s;
      -moz-transition-delay: 0s;
      transition-delay: 0s;
      -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
      -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
      box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
      position: absolute;
      content: "\f04b";
      display: block;
      width: 5rem;
      height: 5rem;
      margin-top: -2.5rem;
      margin-left: -2.5rem;
      line-height: 5rem;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      background: #fff;
      font-family: 'icomoon' !important;
      font-size: 1.125rem;
      text-align: center;
      color: #000;
      z-index: 5; }
    .card-entry .card-video:hover::before {
      -webkit-transform: scale(1.2);
      -moz-transform: scale(1.2);
      transform: scale(1.2); }
  .card-entry .card-title {
    font-family: "Open Sans", sans-serif;
    font-size: 1.125rem; }
    .card-entry .card-title a {
      color: #000; }

.card-showcase {
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  display: block;
  background: transparent;
  border: 0; }
  .card-showcase a {
    display: block; }
  .card-showcase img {
    border-radius: 0.25rem; }

.card-component {
  background: transparent;
  box-shadow: none; }
  .card-component .card-footer {
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
    border: 0;
    background: #fff;
    border-radius: 0.25rem; }
    .card-component .card-footer pre[class*="language-"] {
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      box-shadow: none !important;
      background: transparent;
      margin: 0; }

.table {
  border-radius: 0.25rem; }
  .table th {
    font-weight: 400;
    color: #999999; }
  .table th,
  .table td {
    padding: 1.25rem 1.875rem; }
  .table thead th {
    border-bottom: 0;
    border-top: 0; }
  .table.boxed th,
  .table.boxed td {
    border-top: 0; }

.list-group-minimal .list-group-item {
  margin-bottom: 15px;
  padding: 0;
  background: transparent;
  border: 0; }
  .list-group-minimal .list-group-item a {
    color: inherit; }

.list-group-lines .list-group-item {
  border-radius: 0.25rem;
  background: transparent;
  padding: 20px;
  margin-bottom: 15px; }
  .list-group-lines .list-group-item.boxed {
    background: #fff; }
  .list-group-lines .list-group-item .badge {
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    transform: translate(0, -50%);
    position: absolute;
    top: 50%;
    right: -15px; }

.list-group-contacts .list-group-item {
  background: transparent;
  padding: 0;
  border: 0;
  font-size: 0.875rem;
  color: #999999; }
  .list-group-contacts .list-group-item + .list-group-item {
    margin-top: 1rem; }
  .list-group-contacts .list-group-item span {
    display: block;
    margin-top: .25em;
    font-size: 1.125rem;
    color: #000; }

.list-group-related {
  margin-left: -30px;
  margin-right: -30px; }
  .list-group-related .list-group-item {
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    transition-delay: 0s;
    background: transparent;
    border: 0;
    border-radius: 0;
    padding: 15px 30px;
    color: #999;
    font-size: 0.875rem; }
    .list-group-related .list-group-item:hover {
      background: rgba(0, 0, 0, 0.05); }
    .list-group-related .list-group-item.active {
      color: #555555; }
      .list-group-related .list-group-item.active::before, .list-group-related .list-group-item.active:hover::before {
        opacity: 1; }
    .list-group-related .list-group-item:hover::before {
      opacity: .25; }

.pagination .page-item + .page-item {
  margin-left: .5rem; }

.pagination .page-link {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-box-shadow: 0px 0px 0 1px #e5e5e5;
  -moz-box-shadow: 0px 0px 0 1px #e5e5e5;
  box-shadow: 0px 0px 0 1px #e5e5e5;
  box-sizing: content-box;
  width: 1.875rem;
  line-height: 1.875rem;
  text-align: center;
  border-radius: 50% !important;
  font-size: 0.875rem;
  font-weight: 600; }
  .pagination .page-link:not(.active):hover {
    border: 0; }

.masonry {
  padding: 0;
  list-style: none; }
  .masonry > li {
    position: relative; }

.alert {
  overflow: hidden; }
  .alert [class*="icon-"] {
    position: absolute;
    top: -2rem;
    left: -2rem;
    font-size: 10rem;
    color: rgba(0, 0, 0, 0.05); }

.badge {
  text-transform: uppercase;
  font-size: .75rem;
  font-weight: 600;
  padding: .5rem 1rem;
  letter-spacing: 0.05em; }
  .badge[class*="-lg"] {
    min-width: 80px; }

.badge-blue {
  color: #fff;
  background-color: #064CDB; }
  a.badge-blue:hover, a.badge-blue:focus {
    color: #fff;
    background-color: #053ba9; }
  a.badge-blue:focus, a.badge-blue.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(6, 76, 219, 0.5); }

.badge-indigo {
  color: #fff;
  background-color: #6610f2; }
  a.badge-indigo:hover, a.badge-indigo:focus {
    color: #fff;
    background-color: #510bc4; }
  a.badge-indigo:focus, a.badge-indigo.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(102, 16, 242, 0.5); }

.badge-purple {
  color: #fff;
  background-color: #8A3FF6; }
  a.badge-purple:hover, a.badge-purple:focus {
    color: #fff;
    background-color: #6c0ef4; }
  a.badge-purple:focus, a.badge-purple.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(138, 63, 246, 0.5); }

.badge-pink {
  color: #fff;
  background-color: #e83e8c; }
  a.badge-pink:hover, a.badge-pink:focus {
    color: #fff;
    background-color: #d91a72; }
  a.badge-pink:focus, a.badge-pink.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(232, 62, 140, 0.5); }

.badge-red {
  color: #fff;
  background-color: #dc3545; }
  a.badge-red:hover, a.badge-red:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-red:focus, a.badge-red.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.5); }

.badge-orange {
  color: #111111;
  background-color: #fd7e14; }
  a.badge-orange:hover, a.badge-orange:focus {
    color: #111111;
    background-color: #dc6502; }
  a.badge-orange:focus, a.badge-orange.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(253, 126, 20, 0.5); }

.badge-yellow {
  color: #111111;
  background-color: #ffc107; }
  a.badge-yellow:hover, a.badge-yellow:focus {
    color: #111111;
    background-color: #d39e00; }
  a.badge-yellow:focus, a.badge-yellow.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.5); }

.badge-green {
  color: #fff;
  background-color: #28a745; }
  a.badge-green:hover, a.badge-green:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-green:focus, a.badge-green.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.5); }

.badge-teal {
  color: #fff;
  background-color: #20c997; }
  a.badge-teal:hover, a.badge-teal:focus {
    color: #fff;
    background-color: #199d76; }
  a.badge-teal:focus, a.badge-teal.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(32, 201, 151, 0.5); }

.badge-cyan {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-cyan:hover, a.badge-cyan:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-cyan:focus, a.badge-cyan.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5); }

.badge-white {
  color: #111111;
  background-color: #fff; }
  a.badge-white:hover, a.badge-white:focus {
    color: #111111;
    background-color: #e6e6e6; }
  a.badge-white:focus, a.badge-white.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5); }

.badge-gray {
  color: #111111;
  background-color: #999999; }
  a.badge-gray:hover, a.badge-gray:focus {
    color: #111111;
    background-color: gray; }
  a.badge-gray:focus, a.badge-gray.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(153, 153, 153, 0.5); }

.badge-gray-dark {
  color: #fff;
  background-color: #151515; }
  a.badge-gray-dark:hover, a.badge-gray-dark:focus {
    color: #fff;
    background-color: black; }
  a.badge-gray-dark:focus, a.badge-gray-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(21, 21, 21, 0.5); }

.badge-gray-light {
  color: #111111;
  background-color: #f5f5f5; }
  a.badge-gray-light:hover, a.badge-gray-light:focus {
    color: #111111;
    background-color: gainsboro; }
  a.badge-gray-light:focus, a.badge-gray-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(245, 245, 245, 0.5); }

.badge-facebook {
  color: #fff;
  background-color: #3b5998; }
  a.badge-facebook:hover, a.badge-facebook:focus {
    color: #fff;
    background-color: #2d4373; }
  a.badge-facebook:focus, a.badge-facebook.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(59, 89, 152, 0.5); }

.badge-google-plus {
  color: #fff;
  background-color: #dd4b39; }
  a.badge-google-plus:hover, a.badge-google-plus:focus {
    color: #fff;
    background-color: #c23321; }
  a.badge-google-plus:focus, a.badge-google-plus.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(221, 75, 57, 0.5); }

.badge-twitter {
  color: #fff;
  background-color: #00aced; }
  a.badge-twitter:hover, a.badge-twitter:focus {
    color: #fff;
    background-color: #0087ba; }
  a.badge-twitter:focus, a.badge-twitter.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(0, 172, 237, 0.5); }

.badge-linkedin {
  color: #fff;
  background-color: #007bb6; }
  a.badge-linkedin:hover, a.badge-linkedin:focus {
    color: #fff;
    background-color: #005983; }
  a.badge-linkedin:focus, a.badge-linkedin.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(0, 123, 182, 0.5); }

.badge-pinterest {
  color: #fff;
  background-color: #cb2027; }
  a.badge-pinterest:hover, a.badge-pinterest:focus {
    color: #fff;
    background-color: #9f191f; }
  a.badge-pinterest:focus, a.badge-pinterest.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(203, 32, 39, 0.5); }

.badge-git {
  color: #fff;
  background-color: #666666; }
  a.badge-git:hover, a.badge-git:focus {
    color: #fff;
    background-color: #4d4d4d; }
  a.badge-git:focus, a.badge-git.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(102, 102, 102, 0.5); }

.badge-tumblr {
  color: #fff;
  background-color: #32506d; }
  a.badge-tumblr:hover, a.badge-tumblr:focus {
    color: #fff;
    background-color: #22364a; }
  a.badge-tumblr:focus, a.badge-tumblr.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(50, 80, 109, 0.5); }

.badge-vimeo {
  color: #111111;
  background-color: #aad450; }
  a.badge-vimeo:hover, a.badge-vimeo:focus {
    color: #111111;
    background-color: #93c130; }
  a.badge-vimeo:focus, a.badge-vimeo.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(170, 212, 80, 0.5); }

.badge-flickr {
  color: #fff;
  background-color: #ff0084; }
  a.badge-flickr:hover, a.badge-flickr:focus {
    color: #fff;
    background-color: #cc006a; }
  a.badge-flickr:focus, a.badge-flickr.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(255, 0, 132, 0.5); }

.badge-reddit {
  color: #fff;
  background-color: #ff4500; }
  a.badge-reddit:hover, a.badge-reddit:focus {
    color: #fff;
    background-color: #cc3700; }
  a.badge-reddit:focus, a.badge-reddit.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(255, 69, 0, 0.5); }

.badge-dribbble {
  color: #fff;
  background-color: #ea4c89; }
  a.badge-dribbble:hover, a.badge-dribbble:focus {
    color: #fff;
    background-color: #e51e6b; }
  a.badge-dribbble:focus, a.badge-dribbble.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(234, 76, 137, 0.5); }

.badge-skype {
  color: #fff;
  background-color: #00aff0; }
  a.badge-skype:hover, a.badge-skype:focus {
    color: #fff;
    background-color: #008abd; }
  a.badge-skype:focus, a.badge-skype.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(0, 175, 240, 0.5); }

.badge-instagram {
  color: #fff;
  background-color: #517fa4; }
  a.badge-instagram:hover, a.badge-instagram:focus {
    color: #fff;
    background-color: #406582; }
  a.badge-instagram:focus, a.badge-instagram.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(81, 127, 164, 0.5); }

.badge-behance {
  color: #fff;
  background-color: #1769ff; }
  a.badge-behance:hover, a.badge-behance:focus {
    color: #fff;
    background-color: #0050e3; }
  a.badge-behance:focus, a.badge-behance.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(23, 105, 255, 0.5); }

.badge-rss {
  color: #fff;
  background-color: #f26522; }
  a.badge-rss:hover, a.badge-rss:focus {
    color: #fff;
    background-color: #d54d0d; }
  a.badge-rss:focus, a.badge-rss.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(242, 101, 34, 0.5); }

.badge-100 {
  color: #111111;
  background-color: #f8f8f8; }
  a.badge-100:hover, a.badge-100:focus {
    color: #111111;
    background-color: #dfdfdf; }
  a.badge-100:focus, a.badge-100.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(248, 248, 248, 0.5); }

.badge-200 {
  color: #111111;
  background-color: #f5f5f5; }
  a.badge-200:hover, a.badge-200:focus {
    color: #111111;
    background-color: gainsboro; }
  a.badge-200:focus, a.badge-200.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(245, 245, 245, 0.5); }

.badge-300 {
  color: #111111;
  background-color: #e5e5e5; }
  a.badge-300:hover, a.badge-300:focus {
    color: #111111;
    background-color: #cccccc; }
  a.badge-300:focus, a.badge-300.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(229, 229, 229, 0.5); }

.badge-400 {
  color: #111111;
  background-color: #ced4da; }
  a.badge-400:hover, a.badge-400:focus {
    color: #111111;
    background-color: #b1bbc4; }
  a.badge-400:focus, a.badge-400.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(206, 212, 218, 0.5); }

.badge-500 {
  color: #111111;
  background-color: #adb5bd; }
  a.badge-500:hover, a.badge-500:focus {
    color: #111111;
    background-color: #919ca6; }
  a.badge-500:focus, a.badge-500.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(173, 181, 189, 0.5); }

.badge-600 {
  color: #111111;
  background-color: #999999; }
  a.badge-600:hover, a.badge-600:focus {
    color: #111111;
    background-color: gray; }
  a.badge-600:focus, a.badge-600.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(153, 153, 153, 0.5); }

.badge-700 {
  color: #fff;
  background-color: #555555; }
  a.badge-700:hover, a.badge-700:focus {
    color: #fff;
    background-color: #3c3c3c; }
  a.badge-700:focus, a.badge-700.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(85, 85, 85, 0.5); }

.badge-800 {
  color: #fff;
  background-color: #151515; }
  a.badge-800:hover, a.badge-800:focus {
    color: #fff;
    background-color: black; }
  a.badge-800:focus, a.badge-800.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(21, 21, 21, 0.5); }

.badge-900 {
  color: #fff;
  background-color: #111111; }
  a.badge-900:hover, a.badge-900:focus {
    color: #fff;
    background-color: black; }
  a.badge-900:focus, a.badge-900.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(17, 17, 17, 0.5); }

.btn-rounded {
  border-radius: 10rem; }

.btn-frame {
  display: inline-block;
  padding: .5rem;
  border-radius: 50%; }

.btn {
  position: relative; }
  .btn .badge {
    font-size: 1em;
    margin-right: -45px;
    margin-left: 1em; }

.btn-ico {
  position: relative;
  box-sizing: content-box;
  width: 1.5em;
  height: 1.5em;
  padding: 0.9375rem; }
  .btn-ico[class*="-sm"] {
    padding: 0.625rem; }
  .btn-ico[class*="-lg"] {
    padding: 1.25rem; }
  .btn-ico[class*="-rounded"] {
    border-radius: 50% !important; }
  .btn-ico i {
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%; }

.btn-inverted {
  background: rgba(255, 255, 255, 0.1);
  color: #fff; }
  .btn-inverted:hover {
    background: rgba(255, 255, 255, 0.15); }

.btn-blue {
  color: #fff;
  background-color: #064CDB;
  border-color: #064CDB; }
  .btn-blue:hover {
    color: #fff;
    background-color: #053fb6;
    border-color: #053ba9; }
  .btn-blue:focus, .btn-blue.focus {
    box-shadow: 0 0 0 0 rgba(43, 103, 224, 0.5); }
  .btn-blue.disabled, .btn-blue:disabled {
    color: #fff;
    background-color: #064CDB;
    border-color: #064CDB; }
  .btn-blue:not(:disabled):not(.disabled):active, .btn-blue:not(:disabled):not(.disabled).active,
  .show > .btn-blue.dropdown-toggle {
    color: #fff;
    background-color: #053ba9;
    border-color: #04369d; }
    .btn-blue:not(:disabled):not(.disabled):active:focus, .btn-blue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-blue.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(43, 103, 224, 0.5); }

.btn-indigo {
  color: #fff;
  background-color: #6610f2;
  border-color: #6610f2; }
  .btn-indigo:hover {
    color: #fff;
    background-color: #560bd0;
    border-color: #510bc4; }
  .btn-indigo:focus, .btn-indigo.focus {
    box-shadow: 0 0 0 0 rgba(125, 52, 244, 0.5); }
  .btn-indigo.disabled, .btn-indigo:disabled {
    color: #fff;
    background-color: #6610f2;
    border-color: #6610f2; }
  .btn-indigo:not(:disabled):not(.disabled):active, .btn-indigo:not(:disabled):not(.disabled).active,
  .show > .btn-indigo.dropdown-toggle {
    color: #fff;
    background-color: #510bc4;
    border-color: #4c0ab8; }
    .btn-indigo:not(:disabled):not(.disabled):active:focus, .btn-indigo:not(:disabled):not(.disabled).active:focus,
    .show > .btn-indigo.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(125, 52, 244, 0.5); }

.btn-purple {
  color: #fff;
  background-color: #8A3FF6;
  border-color: #8A3FF6; }
  .btn-purple:hover {
    color: #fff;
    background-color: #741af4;
    border-color: #6c0ef4; }
  .btn-purple:focus, .btn-purple.focus {
    box-shadow: 0 0 0 0 rgba(156, 92, 247, 0.5); }
  .btn-purple.disabled, .btn-purple:disabled {
    color: #fff;
    background-color: #8A3FF6;
    border-color: #8A3FF6; }
  .btn-purple:not(:disabled):not(.disabled):active, .btn-purple:not(:disabled):not(.disabled).active,
  .show > .btn-purple.dropdown-toggle {
    color: #fff;
    background-color: #6c0ef4;
    border-color: #660bea; }
    .btn-purple:not(:disabled):not(.disabled):active:focus, .btn-purple:not(:disabled):not(.disabled).active:focus,
    .show > .btn-purple.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(156, 92, 247, 0.5); }

.btn-pink {
  color: #fff;
  background-color: #e83e8c;
  border-color: #e83e8c; }
  .btn-pink:hover {
    color: #fff;
    background-color: #e41c78;
    border-color: #d91a72; }
  .btn-pink:focus, .btn-pink.focus {
    box-shadow: 0 0 0 0 rgba(235, 91, 157, 0.5); }
  .btn-pink.disabled, .btn-pink:disabled {
    color: #fff;
    background-color: #e83e8c;
    border-color: #e83e8c; }
  .btn-pink:not(:disabled):not(.disabled):active, .btn-pink:not(:disabled):not(.disabled).active,
  .show > .btn-pink.dropdown-toggle {
    color: #fff;
    background-color: #d91a72;
    border-color: #ce196c; }
    .btn-pink:not(:disabled):not(.disabled):active:focus, .btn-pink:not(:disabled):not(.disabled).active:focus,
    .show > .btn-pink.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(235, 91, 157, 0.5); }

.btn-red {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-red:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-red:focus, .btn-red.focus {
    box-shadow: 0 0 0 0 rgba(225, 83, 97, 0.5); }
  .btn-red.disabled, .btn-red:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-red:not(:disabled):not(.disabled):active, .btn-red:not(:disabled):not(.disabled).active,
  .show > .btn-red.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-red:not(:disabled):not(.disabled):active:focus, .btn-red:not(:disabled):not(.disabled).active:focus,
    .show > .btn-red.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(225, 83, 97, 0.5); }

.btn-orange {
  color: #111111;
  background-color: #fd7e14;
  border-color: #fd7e14; }
  .btn-orange:hover {
    color: #fff;
    background-color: #e96b02;
    border-color: #dc6502; }
  .btn-orange:focus, .btn-orange.focus {
    box-shadow: 0 0 0 0 rgba(218, 110, 20, 0.5); }
  .btn-orange.disabled, .btn-orange:disabled {
    color: #111111;
    background-color: #fd7e14;
    border-color: #fd7e14; }
  .btn-orange:not(:disabled):not(.disabled):active, .btn-orange:not(:disabled):not(.disabled).active,
  .show > .btn-orange.dropdown-toggle {
    color: #fff;
    background-color: #dc6502;
    border-color: #cf5f02; }
    .btn-orange:not(:disabled):not(.disabled):active:focus, .btn-orange:not(:disabled):not(.disabled).active:focus,
    .show > .btn-orange.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(218, 110, 20, 0.5); }

.btn-yellow {
  color: #111111;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-yellow:hover {
    color: #111111;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-yellow:focus, .btn-yellow.focus {
    box-shadow: 0 0 0 0 rgba(219, 167, 9, 0.5); }
  .btn-yellow.disabled, .btn-yellow:disabled {
    color: #111111;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-yellow:not(:disabled):not(.disabled):active, .btn-yellow:not(:disabled):not(.disabled).active,
  .show > .btn-yellow.dropdown-toggle {
    color: #111111;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-yellow:not(:disabled):not(.disabled):active:focus, .btn-yellow:not(:disabled):not(.disabled).active:focus,
    .show > .btn-yellow.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(219, 167, 9, 0.5); }

.btn-green {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-green:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-green:focus, .btn-green.focus {
    box-shadow: 0 0 0 0 rgba(72, 180, 97, 0.5); }
  .btn-green.disabled, .btn-green:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-green:not(:disabled):not(.disabled):active, .btn-green:not(:disabled):not(.disabled).active,
  .show > .btn-green.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-green:not(:disabled):not(.disabled):active:focus, .btn-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-green.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(72, 180, 97, 0.5); }

.btn-teal {
  color: #fff;
  background-color: #20c997;
  border-color: #20c997; }
  .btn-teal:hover {
    color: #fff;
    background-color: #1ba87e;
    border-color: #199d76; }
  .btn-teal:focus, .btn-teal.focus {
    box-shadow: 0 0 0 0 rgba(65, 209, 167, 0.5); }
  .btn-teal.disabled, .btn-teal:disabled {
    color: #fff;
    background-color: #20c997;
    border-color: #20c997; }
  .btn-teal:not(:disabled):not(.disabled):active, .btn-teal:not(:disabled):not(.disabled).active,
  .show > .btn-teal.dropdown-toggle {
    color: #fff;
    background-color: #199d76;
    border-color: #17926e; }
    .btn-teal:not(:disabled):not(.disabled):active:focus, .btn-teal:not(:disabled):not(.disabled).active:focus,
    .show > .btn-teal.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(65, 209, 167, 0.5); }

.btn-cyan {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-cyan:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-cyan:focus, .btn-cyan.focus {
    box-shadow: 0 0 0 0 rgba(58, 176, 195, 0.5); }
  .btn-cyan.disabled, .btn-cyan:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-cyan:not(:disabled):not(.disabled):active, .btn-cyan:not(:disabled):not(.disabled).active,
  .show > .btn-cyan.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-cyan:not(:disabled):not(.disabled):active:focus, .btn-cyan:not(:disabled):not(.disabled).active:focus,
    .show > .btn-cyan.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(58, 176, 195, 0.5); }

.btn-white {
  color: #111111;
  background-color: #fff;
  border-color: #fff; }
  .btn-white:hover {
    color: #111111;
    background-color: #ececec;
    border-color: #e6e6e6; }
  .btn-white:focus, .btn-white.focus {
    box-shadow: 0 0 0 0 rgba(219, 219, 219, 0.5); }
  .btn-white.disabled, .btn-white:disabled {
    color: #111111;
    background-color: #fff;
    border-color: #fff; }
  .btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active,
  .show > .btn-white.dropdown-toggle {
    color: #111111;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-white:not(:disabled):not(.disabled):active:focus, .btn-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(219, 219, 219, 0.5); }

.btn-gray {
  color: #111111;
  background-color: #999999;
  border-color: #999999; }
  .btn-gray:hover {
    color: #fff;
    background-color: #868686;
    border-color: gray; }
  .btn-gray:focus, .btn-gray.focus {
    box-shadow: 0 0 0 0 rgba(133, 133, 133, 0.5); }
  .btn-gray.disabled, .btn-gray:disabled {
    color: #111111;
    background-color: #999999;
    border-color: #999999; }
  .btn-gray:not(:disabled):not(.disabled):active, .btn-gray:not(:disabled):not(.disabled).active,
  .show > .btn-gray.dropdown-toggle {
    color: #fff;
    background-color: gray;
    border-color: #797979; }
    .btn-gray:not(:disabled):not(.disabled):active:focus, .btn-gray:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(133, 133, 133, 0.5); }

.btn-gray-dark {
  color: #fff;
  background-color: #151515;
  border-color: #151515; }
  .btn-gray-dark:hover {
    color: #fff;
    background-color: #020202;
    border-color: black; }
  .btn-gray-dark:focus, .btn-gray-dark.focus {
    box-shadow: 0 0 0 0 rgba(56, 56, 56, 0.5); }
  .btn-gray-dark.disabled, .btn-gray-dark:disabled {
    color: #fff;
    background-color: #151515;
    border-color: #151515; }
  .btn-gray-dark:not(:disabled):not(.disabled):active, .btn-gray-dark:not(:disabled):not(.disabled).active,
  .show > .btn-gray-dark.dropdown-toggle {
    color: #fff;
    background-color: black;
    border-color: black; }
    .btn-gray-dark:not(:disabled):not(.disabled):active:focus, .btn-gray-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(56, 56, 56, 0.5); }

.btn-gray-light {
  color: #111111;
  background-color: #f5f5f5;
  border-color: #f5f5f5; }
  .btn-gray-light:hover {
    color: #111111;
    background-color: #e2e2e2;
    border-color: gainsboro; }
  .btn-gray-light:focus, .btn-gray-light.focus {
    box-shadow: 0 0 0 0 rgba(211, 211, 211, 0.5); }
  .btn-gray-light.disabled, .btn-gray-light:disabled {
    color: #111111;
    background-color: #f5f5f5;
    border-color: #f5f5f5; }
  .btn-gray-light:not(:disabled):not(.disabled):active, .btn-gray-light:not(:disabled):not(.disabled).active,
  .show > .btn-gray-light.dropdown-toggle {
    color: #111111;
    background-color: gainsboro;
    border-color: #d5d5d5; }
    .btn-gray-light:not(:disabled):not(.disabled):active:focus, .btn-gray-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-gray-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(211, 211, 211, 0.5); }

.btn-outline-blue {
  color: #064CDB;
  border-color: #064CDB; }
  .btn-outline-blue:hover {
    color: #fff;
    background-color: #064CDB;
    border-color: #064CDB; }
  .btn-outline-blue:focus, .btn-outline-blue.focus {
    box-shadow: 0 0 0 0 rgba(6, 76, 219, 0.5); }
  .btn-outline-blue.disabled, .btn-outline-blue:disabled {
    color: #064CDB;
    background-color: transparent; }
  .btn-outline-blue:not(:disabled):not(.disabled):active, .btn-outline-blue:not(:disabled):not(.disabled).active,
  .show > .btn-outline-blue.dropdown-toggle {
    color: #fff;
    background-color: #064CDB;
    border-color: #064CDB; }
    .btn-outline-blue:not(:disabled):not(.disabled):active:focus, .btn-outline-blue:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-blue.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(6, 76, 219, 0.5); }

.btn-outline-indigo {
  color: #6610f2;
  border-color: #6610f2; }
  .btn-outline-indigo:hover {
    color: #fff;
    background-color: #6610f2;
    border-color: #6610f2; }
  .btn-outline-indigo:focus, .btn-outline-indigo.focus {
    box-shadow: 0 0 0 0 rgba(102, 16, 242, 0.5); }
  .btn-outline-indigo.disabled, .btn-outline-indigo:disabled {
    color: #6610f2;
    background-color: transparent; }
  .btn-outline-indigo:not(:disabled):not(.disabled):active, .btn-outline-indigo:not(:disabled):not(.disabled).active,
  .show > .btn-outline-indigo.dropdown-toggle {
    color: #fff;
    background-color: #6610f2;
    border-color: #6610f2; }
    .btn-outline-indigo:not(:disabled):not(.disabled):active:focus, .btn-outline-indigo:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-indigo.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(102, 16, 242, 0.5); }

.btn-outline-purple {
  color: #8A3FF6;
  border-color: #8A3FF6; }
  .btn-outline-purple:hover {
    color: #fff;
    background-color: #8A3FF6;
    border-color: #8A3FF6; }
  .btn-outline-purple:focus, .btn-outline-purple.focus {
    box-shadow: 0 0 0 0 rgba(138, 63, 246, 0.5); }
  .btn-outline-purple.disabled, .btn-outline-purple:disabled {
    color: #8A3FF6;
    background-color: transparent; }
  .btn-outline-purple:not(:disabled):not(.disabled):active, .btn-outline-purple:not(:disabled):not(.disabled).active,
  .show > .btn-outline-purple.dropdown-toggle {
    color: #fff;
    background-color: #8A3FF6;
    border-color: #8A3FF6; }
    .btn-outline-purple:not(:disabled):not(.disabled):active:focus, .btn-outline-purple:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-purple.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(138, 63, 246, 0.5); }

.btn-outline-pink {
  color: #e83e8c;
  border-color: #e83e8c; }
  .btn-outline-pink:hover {
    color: #fff;
    background-color: #e83e8c;
    border-color: #e83e8c; }
  .btn-outline-pink:focus, .btn-outline-pink.focus {
    box-shadow: 0 0 0 0 rgba(232, 62, 140, 0.5); }
  .btn-outline-pink.disabled, .btn-outline-pink:disabled {
    color: #e83e8c;
    background-color: transparent; }
  .btn-outline-pink:not(:disabled):not(.disabled):active, .btn-outline-pink:not(:disabled):not(.disabled).active,
  .show > .btn-outline-pink.dropdown-toggle {
    color: #fff;
    background-color: #e83e8c;
    border-color: #e83e8c; }
    .btn-outline-pink:not(:disabled):not(.disabled):active:focus, .btn-outline-pink:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-pink.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(232, 62, 140, 0.5); }

.btn-outline-red {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-red:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-red:focus, .btn-outline-red.focus {
    box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.5); }
  .btn-outline-red.disabled, .btn-outline-red:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-red:not(:disabled):not(.disabled):active, .btn-outline-red:not(:disabled):not(.disabled).active,
  .show > .btn-outline-red.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-red:not(:disabled):not(.disabled):active:focus, .btn-outline-red:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-red.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.5); }

.btn-outline-orange {
  color: #fd7e14;
  border-color: #fd7e14; }
  .btn-outline-orange:hover {
    color: #111111;
    background-color: #fd7e14;
    border-color: #fd7e14; }
  .btn-outline-orange:focus, .btn-outline-orange.focus {
    box-shadow: 0 0 0 0 rgba(253, 126, 20, 0.5); }
  .btn-outline-orange.disabled, .btn-outline-orange:disabled {
    color: #fd7e14;
    background-color: transparent; }
  .btn-outline-orange:not(:disabled):not(.disabled):active, .btn-outline-orange:not(:disabled):not(.disabled).active,
  .show > .btn-outline-orange.dropdown-toggle {
    color: #111111;
    background-color: #fd7e14;
    border-color: #fd7e14; }
    .btn-outline-orange:not(:disabled):not(.disabled):active:focus, .btn-outline-orange:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-orange.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(253, 126, 20, 0.5); }

.btn-outline-yellow {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-yellow:hover {
    color: #111111;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-yellow:focus, .btn-outline-yellow.focus {
    box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.5); }
  .btn-outline-yellow.disabled, .btn-outline-yellow:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-yellow:not(:disabled):not(.disabled):active, .btn-outline-yellow:not(:disabled):not(.disabled).active,
  .show > .btn-outline-yellow.dropdown-toggle {
    color: #111111;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-yellow:not(:disabled):not(.disabled):active:focus, .btn-outline-yellow:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-yellow.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.5); }

.btn-outline-green {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-green:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-green:focus, .btn-outline-green.focus {
    box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.5); }
  .btn-outline-green.disabled, .btn-outline-green:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-green:not(:disabled):not(.disabled):active, .btn-outline-green:not(:disabled):not(.disabled).active,
  .show > .btn-outline-green.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-green:not(:disabled):not(.disabled):active:focus, .btn-outline-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-green.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.5); }

.btn-outline-teal {
  color: #20c997;
  border-color: #20c997; }
  .btn-outline-teal:hover {
    color: #fff;
    background-color: #20c997;
    border-color: #20c997; }
  .btn-outline-teal:focus, .btn-outline-teal.focus {
    box-shadow: 0 0 0 0 rgba(32, 201, 151, 0.5); }
  .btn-outline-teal.disabled, .btn-outline-teal:disabled {
    color: #20c997;
    background-color: transparent; }
  .btn-outline-teal:not(:disabled):not(.disabled):active, .btn-outline-teal:not(:disabled):not(.disabled).active,
  .show > .btn-outline-teal.dropdown-toggle {
    color: #fff;
    background-color: #20c997;
    border-color: #20c997; }
    .btn-outline-teal:not(:disabled):not(.disabled):active:focus, .btn-outline-teal:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-teal.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(32, 201, 151, 0.5); }

.btn-outline-cyan {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-cyan:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-cyan:focus, .btn-outline-cyan.focus {
    box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5); }
  .btn-outline-cyan.disabled, .btn-outline-cyan:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-cyan:not(:disabled):not(.disabled):active, .btn-outline-cyan:not(:disabled):not(.disabled).active,
  .show > .btn-outline-cyan.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-cyan:not(:disabled):not(.disabled):active:focus, .btn-outline-cyan:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-cyan.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5); }

.btn-outline-white {
  color: #fff;
  border-color: #fff; }
  .btn-outline-white:hover {
    color: #111111;
    background-color: #fff;
    border-color: #fff; }
  .btn-outline-white:focus, .btn-outline-white.focus {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5); }
  .btn-outline-white.disabled, .btn-outline-white:disabled {
    color: #fff;
    background-color: transparent; }
  .btn-outline-white:not(:disabled):not(.disabled):active, .btn-outline-white:not(:disabled):not(.disabled).active,
  .show > .btn-outline-white.dropdown-toggle {
    color: #111111;
    background-color: #fff;
    border-color: #fff; }
    .btn-outline-white:not(:disabled):not(.disabled):active:focus, .btn-outline-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5); }

.btn-outline-gray {
  color: #999999;
  border-color: #999999; }
  .btn-outline-gray:hover {
    color: #111111;
    background-color: #999999;
    border-color: #999999; }
  .btn-outline-gray:focus, .btn-outline-gray.focus {
    box-shadow: 0 0 0 0 rgba(153, 153, 153, 0.5); }
  .btn-outline-gray.disabled, .btn-outline-gray:disabled {
    color: #999999;
    background-color: transparent; }
  .btn-outline-gray:not(:disabled):not(.disabled):active, .btn-outline-gray:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray.dropdown-toggle {
    color: #111111;
    background-color: #999999;
    border-color: #999999; }
    .btn-outline-gray:not(:disabled):not(.disabled):active:focus, .btn-outline-gray:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(153, 153, 153, 0.5); }

.btn-outline-gray-dark {
  color: #151515;
  border-color: #151515; }
  .btn-outline-gray-dark:hover {
    color: #fff;
    background-color: #151515;
    border-color: #151515; }
  .btn-outline-gray-dark:focus, .btn-outline-gray-dark.focus {
    box-shadow: 0 0 0 0 rgba(21, 21, 21, 0.5); }
  .btn-outline-gray-dark.disabled, .btn-outline-gray-dark:disabled {
    color: #151515;
    background-color: transparent; }
  .btn-outline-gray-dark:not(:disabled):not(.disabled):active, .btn-outline-gray-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-dark.dropdown-toggle {
    color: #fff;
    background-color: #151515;
    border-color: #151515; }
    .btn-outline-gray-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(21, 21, 21, 0.5); }

.btn-outline-gray-light {
  color: #f5f5f5;
  border-color: #f5f5f5; }
  .btn-outline-gray-light:hover {
    color: #111111;
    background-color: #f5f5f5;
    border-color: #f5f5f5; }
  .btn-outline-gray-light:focus, .btn-outline-gray-light.focus {
    box-shadow: 0 0 0 0 rgba(245, 245, 245, 0.5); }
  .btn-outline-gray-light.disabled, .btn-outline-gray-light:disabled {
    color: #f5f5f5;
    background-color: transparent; }
  .btn-outline-gray-light:not(:disabled):not(.disabled):active, .btn-outline-gray-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-gray-light.dropdown-toggle {
    color: #111111;
    background-color: #f5f5f5;
    border-color: #f5f5f5; }
    .btn-outline-gray-light:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-gray-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(245, 245, 245, 0.5); }

.btn-facebook {
  color: #fff;
  background-color: #3b5998;
  border-color: #3b5998; }
  .btn-facebook:hover {
    color: #fff;
    background-color: #30497c;
    border-color: #2d4373; }
  .btn-facebook:focus, .btn-facebook.focus {
    box-shadow: 0 0 0 0 rgba(88, 114, 167, 0.5); }
  .btn-facebook.disabled, .btn-facebook:disabled {
    color: #fff;
    background-color: #3b5998;
    border-color: #3b5998; }
  .btn-facebook:not(:disabled):not(.disabled):active, .btn-facebook:not(:disabled):not(.disabled).active,
  .show > .btn-facebook.dropdown-toggle {
    color: #fff;
    background-color: #2d4373;
    border-color: #293e6a; }
    .btn-facebook:not(:disabled):not(.disabled):active:focus, .btn-facebook:not(:disabled):not(.disabled).active:focus,
    .show > .btn-facebook.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(88, 114, 167, 0.5); }

.btn-google-plus {
  color: #fff;
  background-color: #dd4b39;
  border-color: #dd4b39; }
  .btn-google-plus:hover {
    color: #fff;
    background-color: #cd3623;
    border-color: #c23321; }
  .btn-google-plus:focus, .btn-google-plus.focus {
    box-shadow: 0 0 0 0 rgba(226, 102, 87, 0.5); }
  .btn-google-plus.disabled, .btn-google-plus:disabled {
    color: #fff;
    background-color: #dd4b39;
    border-color: #dd4b39; }
  .btn-google-plus:not(:disabled):not(.disabled):active, .btn-google-plus:not(:disabled):not(.disabled).active,
  .show > .btn-google-plus.dropdown-toggle {
    color: #fff;
    background-color: #c23321;
    border-color: #b7301f; }
    .btn-google-plus:not(:disabled):not(.disabled):active:focus, .btn-google-plus:not(:disabled):not(.disabled).active:focus,
    .show > .btn-google-plus.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(226, 102, 87, 0.5); }

.btn-twitter {
  color: #fff;
  background-color: #00aced;
  border-color: #00aced; }
  .btn-twitter:hover {
    color: #fff;
    background-color: #0090c7;
    border-color: #0087ba; }
  .btn-twitter:focus, .btn-twitter.focus {
    box-shadow: 0 0 0 0 rgba(38, 184, 240, 0.5); }
  .btn-twitter.disabled, .btn-twitter:disabled {
    color: #fff;
    background-color: #00aced;
    border-color: #00aced; }
  .btn-twitter:not(:disabled):not(.disabled):active, .btn-twitter:not(:disabled):not(.disabled).active,
  .show > .btn-twitter.dropdown-toggle {
    color: #fff;
    background-color: #0087ba;
    border-color: #007ead; }
    .btn-twitter:not(:disabled):not(.disabled):active:focus, .btn-twitter:not(:disabled):not(.disabled).active:focus,
    .show > .btn-twitter.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(38, 184, 240, 0.5); }

.btn-linkedin {
  color: #fff;
  background-color: #007bb6;
  border-color: #007bb6; }
  .btn-linkedin:hover {
    color: #fff;
    background-color: #006190;
    border-color: #005983; }
  .btn-linkedin:focus, .btn-linkedin.focus {
    box-shadow: 0 0 0 0 rgba(38, 143, 193, 0.5); }
  .btn-linkedin.disabled, .btn-linkedin:disabled {
    color: #fff;
    background-color: #007bb6;
    border-color: #007bb6; }
  .btn-linkedin:not(:disabled):not(.disabled):active, .btn-linkedin:not(:disabled):not(.disabled).active,
  .show > .btn-linkedin.dropdown-toggle {
    color: #fff;
    background-color: #005983;
    border-color: #005076; }
    .btn-linkedin:not(:disabled):not(.disabled):active:focus, .btn-linkedin:not(:disabled):not(.disabled).active:focus,
    .show > .btn-linkedin.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(38, 143, 193, 0.5); }

.btn-pinterest {
  color: #fff;
  background-color: #cb2027;
  border-color: #cb2027; }
  .btn-pinterest:hover {
    color: #fff;
    background-color: #aa1b21;
    border-color: #9f191f; }
  .btn-pinterest:focus, .btn-pinterest.focus {
    box-shadow: 0 0 0 0 rgba(211, 65, 71, 0.5); }
  .btn-pinterest.disabled, .btn-pinterest:disabled {
    color: #fff;
    background-color: #cb2027;
    border-color: #cb2027; }
  .btn-pinterest:not(:disabled):not(.disabled):active, .btn-pinterest:not(:disabled):not(.disabled).active,
  .show > .btn-pinterest.dropdown-toggle {
    color: #fff;
    background-color: #9f191f;
    border-color: #94171c; }
    .btn-pinterest:not(:disabled):not(.disabled):active:focus, .btn-pinterest:not(:disabled):not(.disabled).active:focus,
    .show > .btn-pinterest.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(211, 65, 71, 0.5); }

.btn-git {
  color: #fff;
  background-color: #666666;
  border-color: #666666; }
  .btn-git:hover {
    color: #fff;
    background-color: #535353;
    border-color: #4d4d4d; }
  .btn-git:focus, .btn-git.focus {
    box-shadow: 0 0 0 0 rgba(125, 125, 125, 0.5); }
  .btn-git.disabled, .btn-git:disabled {
    color: #fff;
    background-color: #666666;
    border-color: #666666; }
  .btn-git:not(:disabled):not(.disabled):active, .btn-git:not(:disabled):not(.disabled).active,
  .show > .btn-git.dropdown-toggle {
    color: #fff;
    background-color: #4d4d4d;
    border-color: #464646; }
    .btn-git:not(:disabled):not(.disabled):active:focus, .btn-git:not(:disabled):not(.disabled).active:focus,
    .show > .btn-git.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(125, 125, 125, 0.5); }

.btn-tumblr {
  color: #fff;
  background-color: #32506d;
  border-color: #32506d; }
  .btn-tumblr:hover {
    color: #fff;
    background-color: #263d53;
    border-color: #22364a; }
  .btn-tumblr:focus, .btn-tumblr.focus {
    box-shadow: 0 0 0 0 rgba(81, 106, 131, 0.5); }
  .btn-tumblr.disabled, .btn-tumblr:disabled {
    color: #fff;
    background-color: #32506d;
    border-color: #32506d; }
  .btn-tumblr:not(:disabled):not(.disabled):active, .btn-tumblr:not(:disabled):not(.disabled).active,
  .show > .btn-tumblr.dropdown-toggle {
    color: #fff;
    background-color: #22364a;
    border-color: #1e3041; }
    .btn-tumblr:not(:disabled):not(.disabled):active:focus, .btn-tumblr:not(:disabled):not(.disabled).active:focus,
    .show > .btn-tumblr.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(81, 106, 131, 0.5); }

.btn-vimeo {
  color: #111111;
  background-color: #aad450;
  border-color: #aad450; }
  .btn-vimeo:hover {
    color: #111111;
    background-color: #9bcc32;
    border-color: #93c130; }
  .btn-vimeo:focus, .btn-vimeo.focus {
    box-shadow: 0 0 0 0 rgba(147, 183, 71, 0.5); }
  .btn-vimeo.disabled, .btn-vimeo:disabled {
    color: #111111;
    background-color: #aad450;
    border-color: #aad450; }
  .btn-vimeo:not(:disabled):not(.disabled):active, .btn-vimeo:not(:disabled):not(.disabled).active,
  .show > .btn-vimeo.dropdown-toggle {
    color: #111111;
    background-color: #93c130;
    border-color: #8bb72d; }
    .btn-vimeo:not(:disabled):not(.disabled):active:focus, .btn-vimeo:not(:disabled):not(.disabled).active:focus,
    .show > .btn-vimeo.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(147, 183, 71, 0.5); }

.btn-flickr {
  color: #fff;
  background-color: #ff0084;
  border-color: #ff0084; }
  .btn-flickr:hover {
    color: #fff;
    background-color: #d90070;
    border-color: #cc006a; }
  .btn-flickr:focus, .btn-flickr.focus {
    box-shadow: 0 0 0 0 rgba(255, 38, 150, 0.5); }
  .btn-flickr.disabled, .btn-flickr:disabled {
    color: #fff;
    background-color: #ff0084;
    border-color: #ff0084; }
  .btn-flickr:not(:disabled):not(.disabled):active, .btn-flickr:not(:disabled):not(.disabled).active,
  .show > .btn-flickr.dropdown-toggle {
    color: #fff;
    background-color: #cc006a;
    border-color: #bf0063; }
    .btn-flickr:not(:disabled):not(.disabled):active:focus, .btn-flickr:not(:disabled):not(.disabled).active:focus,
    .show > .btn-flickr.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(255, 38, 150, 0.5); }

.btn-reddit {
  color: #fff;
  background-color: #ff4500;
  border-color: #ff4500; }
  .btn-reddit:hover {
    color: #fff;
    background-color: #d93b00;
    border-color: #cc3700; }
  .btn-reddit:focus, .btn-reddit.focus {
    box-shadow: 0 0 0 0 rgba(255, 97, 38, 0.5); }
  .btn-reddit.disabled, .btn-reddit:disabled {
    color: #fff;
    background-color: #ff4500;
    border-color: #ff4500; }
  .btn-reddit:not(:disabled):not(.disabled):active, .btn-reddit:not(:disabled):not(.disabled).active,
  .show > .btn-reddit.dropdown-toggle {
    color: #fff;
    background-color: #cc3700;
    border-color: #bf3400; }
    .btn-reddit:not(:disabled):not(.disabled):active:focus, .btn-reddit:not(:disabled):not(.disabled).active:focus,
    .show > .btn-reddit.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(255, 97, 38, 0.5); }

.btn-dribbble {
  color: #fff;
  background-color: #ea4c89;
  border-color: #ea4c89; }
  .btn-dribbble:hover {
    color: #fff;
    background-color: #e62a72;
    border-color: #e51e6b; }
  .btn-dribbble:focus, .btn-dribbble.focus {
    box-shadow: 0 0 0 0 rgba(237, 103, 155, 0.5); }
  .btn-dribbble.disabled, .btn-dribbble:disabled {
    color: #fff;
    background-color: #ea4c89;
    border-color: #ea4c89; }
  .btn-dribbble:not(:disabled):not(.disabled):active, .btn-dribbble:not(:disabled):not(.disabled).active,
  .show > .btn-dribbble.dropdown-toggle {
    color: #fff;
    background-color: #e51e6b;
    border-color: #dc1a65; }
    .btn-dribbble:not(:disabled):not(.disabled):active:focus, .btn-dribbble:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dribbble.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(237, 103, 155, 0.5); }

.btn-skype {
  color: #fff;
  background-color: #00aff0;
  border-color: #00aff0; }
  .btn-skype:hover {
    color: #fff;
    background-color: #0093ca;
    border-color: #008abd; }
  .btn-skype:focus, .btn-skype.focus {
    box-shadow: 0 0 0 0 rgba(38, 187, 242, 0.5); }
  .btn-skype.disabled, .btn-skype:disabled {
    color: #fff;
    background-color: #00aff0;
    border-color: #00aff0; }
  .btn-skype:not(:disabled):not(.disabled):active, .btn-skype:not(:disabled):not(.disabled).active,
  .show > .btn-skype.dropdown-toggle {
    color: #fff;
    background-color: #008abd;
    border-color: #0081b0; }
    .btn-skype:not(:disabled):not(.disabled):active:focus, .btn-skype:not(:disabled):not(.disabled).active:focus,
    .show > .btn-skype.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(38, 187, 242, 0.5); }

.btn-instagram {
  color: #fff;
  background-color: #517fa4;
  border-color: #517fa4; }
  .btn-instagram:hover {
    color: #fff;
    background-color: #446b8a;
    border-color: #406582; }
  .btn-instagram:focus, .btn-instagram.focus {
    box-shadow: 0 0 0 0 rgba(107, 146, 178, 0.5); }
  .btn-instagram.disabled, .btn-instagram:disabled {
    color: #fff;
    background-color: #517fa4;
    border-color: #517fa4; }
  .btn-instagram:not(:disabled):not(.disabled):active, .btn-instagram:not(:disabled):not(.disabled).active,
  .show > .btn-instagram.dropdown-toggle {
    color: #fff;
    background-color: #406582;
    border-color: #3c5e79; }
    .btn-instagram:not(:disabled):not(.disabled):active:focus, .btn-instagram:not(:disabled):not(.disabled).active:focus,
    .show > .btn-instagram.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(107, 146, 178, 0.5); }

.btn-behance {
  color: #fff;
  background-color: #1769ff;
  border-color: #1769ff; }
  .btn-behance:hover {
    color: #fff;
    background-color: #0055f0;
    border-color: #0050e3; }
  .btn-behance:focus, .btn-behance.focus {
    box-shadow: 0 0 0 0 rgba(58, 128, 255, 0.5); }
  .btn-behance.disabled, .btn-behance:disabled {
    color: #fff;
    background-color: #1769ff;
    border-color: #1769ff; }
  .btn-behance:not(:disabled):not(.disabled):active, .btn-behance:not(:disabled):not(.disabled).active,
  .show > .btn-behance.dropdown-toggle {
    color: #fff;
    background-color: #0050e3;
    border-color: #004cd6; }
    .btn-behance:not(:disabled):not(.disabled):active:focus, .btn-behance:not(:disabled):not(.disabled).active:focus,
    .show > .btn-behance.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(58, 128, 255, 0.5); }

.btn-rss {
  color: #fff;
  background-color: #f26522;
  border-color: #f26522; }
  .btn-rss:hover {
    color: #fff;
    background-color: #e1510d;
    border-color: #d54d0d; }
  .btn-rss:focus, .btn-rss.focus {
    box-shadow: 0 0 0 0 rgba(244, 124, 67, 0.5); }
  .btn-rss.disabled, .btn-rss:disabled {
    color: #fff;
    background-color: #f26522;
    border-color: #f26522; }
  .btn-rss:not(:disabled):not(.disabled):active, .btn-rss:not(:disabled):not(.disabled).active,
  .show > .btn-rss.dropdown-toggle {
    color: #fff;
    background-color: #d54d0d;
    border-color: #c8490c; }
    .btn-rss:not(:disabled):not(.disabled):active:focus, .btn-rss:not(:disabled):not(.disabled).active:focus,
    .show > .btn-rss.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(244, 124, 67, 0.5); }

.btn-outline-facebook {
  color: #3b5998;
  border-color: #3b5998; }
  .btn-outline-facebook:hover {
    color: #fff;
    background-color: #3b5998;
    border-color: #3b5998; }
  .btn-outline-facebook:focus, .btn-outline-facebook.focus {
    box-shadow: 0 0 0 0 rgba(59, 89, 152, 0.5); }
  .btn-outline-facebook.disabled, .btn-outline-facebook:disabled {
    color: #3b5998;
    background-color: transparent; }
  .btn-outline-facebook:not(:disabled):not(.disabled):active, .btn-outline-facebook:not(:disabled):not(.disabled).active,
  .show > .btn-outline-facebook.dropdown-toggle {
    color: #fff;
    background-color: #3b5998;
    border-color: #3b5998; }
    .btn-outline-facebook:not(:disabled):not(.disabled):active:focus, .btn-outline-facebook:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-facebook.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(59, 89, 152, 0.5); }

.btn-outline-google-plus {
  color: #dd4b39;
  border-color: #dd4b39; }
  .btn-outline-google-plus:hover {
    color: #fff;
    background-color: #dd4b39;
    border-color: #dd4b39; }
  .btn-outline-google-plus:focus, .btn-outline-google-plus.focus {
    box-shadow: 0 0 0 0 rgba(221, 75, 57, 0.5); }
  .btn-outline-google-plus.disabled, .btn-outline-google-plus:disabled {
    color: #dd4b39;
    background-color: transparent; }
  .btn-outline-google-plus:not(:disabled):not(.disabled):active, .btn-outline-google-plus:not(:disabled):not(.disabled).active,
  .show > .btn-outline-google-plus.dropdown-toggle {
    color: #fff;
    background-color: #dd4b39;
    border-color: #dd4b39; }
    .btn-outline-google-plus:not(:disabled):not(.disabled):active:focus, .btn-outline-google-plus:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-google-plus.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(221, 75, 57, 0.5); }

.btn-outline-twitter {
  color: #00aced;
  border-color: #00aced; }
  .btn-outline-twitter:hover {
    color: #fff;
    background-color: #00aced;
    border-color: #00aced; }
  .btn-outline-twitter:focus, .btn-outline-twitter.focus {
    box-shadow: 0 0 0 0 rgba(0, 172, 237, 0.5); }
  .btn-outline-twitter.disabled, .btn-outline-twitter:disabled {
    color: #00aced;
    background-color: transparent; }
  .btn-outline-twitter:not(:disabled):not(.disabled):active, .btn-outline-twitter:not(:disabled):not(.disabled).active,
  .show > .btn-outline-twitter.dropdown-toggle {
    color: #fff;
    background-color: #00aced;
    border-color: #00aced; }
    .btn-outline-twitter:not(:disabled):not(.disabled):active:focus, .btn-outline-twitter:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-twitter.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(0, 172, 237, 0.5); }

.btn-outline-linkedin {
  color: #007bb6;
  border-color: #007bb6; }
  .btn-outline-linkedin:hover {
    color: #fff;
    background-color: #007bb6;
    border-color: #007bb6; }
  .btn-outline-linkedin:focus, .btn-outline-linkedin.focus {
    box-shadow: 0 0 0 0 rgba(0, 123, 182, 0.5); }
  .btn-outline-linkedin.disabled, .btn-outline-linkedin:disabled {
    color: #007bb6;
    background-color: transparent; }
  .btn-outline-linkedin:not(:disabled):not(.disabled):active, .btn-outline-linkedin:not(:disabled):not(.disabled).active,
  .show > .btn-outline-linkedin.dropdown-toggle {
    color: #fff;
    background-color: #007bb6;
    border-color: #007bb6; }
    .btn-outline-linkedin:not(:disabled):not(.disabled):active:focus, .btn-outline-linkedin:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-linkedin.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(0, 123, 182, 0.5); }

.btn-outline-pinterest {
  color: #cb2027;
  border-color: #cb2027; }
  .btn-outline-pinterest:hover {
    color: #fff;
    background-color: #cb2027;
    border-color: #cb2027; }
  .btn-outline-pinterest:focus, .btn-outline-pinterest.focus {
    box-shadow: 0 0 0 0 rgba(203, 32, 39, 0.5); }
  .btn-outline-pinterest.disabled, .btn-outline-pinterest:disabled {
    color: #cb2027;
    background-color: transparent; }
  .btn-outline-pinterest:not(:disabled):not(.disabled):active, .btn-outline-pinterest:not(:disabled):not(.disabled).active,
  .show > .btn-outline-pinterest.dropdown-toggle {
    color: #fff;
    background-color: #cb2027;
    border-color: #cb2027; }
    .btn-outline-pinterest:not(:disabled):not(.disabled):active:focus, .btn-outline-pinterest:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-pinterest.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(203, 32, 39, 0.5); }

.btn-outline-git {
  color: #666666;
  border-color: #666666; }
  .btn-outline-git:hover {
    color: #fff;
    background-color: #666666;
    border-color: #666666; }
  .btn-outline-git:focus, .btn-outline-git.focus {
    box-shadow: 0 0 0 0 rgba(102, 102, 102, 0.5); }
  .btn-outline-git.disabled, .btn-outline-git:disabled {
    color: #666666;
    background-color: transparent; }
  .btn-outline-git:not(:disabled):not(.disabled):active, .btn-outline-git:not(:disabled):not(.disabled).active,
  .show > .btn-outline-git.dropdown-toggle {
    color: #fff;
    background-color: #666666;
    border-color: #666666; }
    .btn-outline-git:not(:disabled):not(.disabled):active:focus, .btn-outline-git:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-git.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(102, 102, 102, 0.5); }

.btn-outline-tumblr {
  color: #32506d;
  border-color: #32506d; }
  .btn-outline-tumblr:hover {
    color: #fff;
    background-color: #32506d;
    border-color: #32506d; }
  .btn-outline-tumblr:focus, .btn-outline-tumblr.focus {
    box-shadow: 0 0 0 0 rgba(50, 80, 109, 0.5); }
  .btn-outline-tumblr.disabled, .btn-outline-tumblr:disabled {
    color: #32506d;
    background-color: transparent; }
  .btn-outline-tumblr:not(:disabled):not(.disabled):active, .btn-outline-tumblr:not(:disabled):not(.disabled).active,
  .show > .btn-outline-tumblr.dropdown-toggle {
    color: #fff;
    background-color: #32506d;
    border-color: #32506d; }
    .btn-outline-tumblr:not(:disabled):not(.disabled):active:focus, .btn-outline-tumblr:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-tumblr.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(50, 80, 109, 0.5); }

.btn-outline-vimeo {
  color: #aad450;
  border-color: #aad450; }
  .btn-outline-vimeo:hover {
    color: #111111;
    background-color: #aad450;
    border-color: #aad450; }
  .btn-outline-vimeo:focus, .btn-outline-vimeo.focus {
    box-shadow: 0 0 0 0 rgba(170, 212, 80, 0.5); }
  .btn-outline-vimeo.disabled, .btn-outline-vimeo:disabled {
    color: #aad450;
    background-color: transparent; }
  .btn-outline-vimeo:not(:disabled):not(.disabled):active, .btn-outline-vimeo:not(:disabled):not(.disabled).active,
  .show > .btn-outline-vimeo.dropdown-toggle {
    color: #111111;
    background-color: #aad450;
    border-color: #aad450; }
    .btn-outline-vimeo:not(:disabled):not(.disabled):active:focus, .btn-outline-vimeo:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-vimeo.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(170, 212, 80, 0.5); }

.btn-outline-flickr {
  color: #ff0084;
  border-color: #ff0084; }
  .btn-outline-flickr:hover {
    color: #fff;
    background-color: #ff0084;
    border-color: #ff0084; }
  .btn-outline-flickr:focus, .btn-outline-flickr.focus {
    box-shadow: 0 0 0 0 rgba(255, 0, 132, 0.5); }
  .btn-outline-flickr.disabled, .btn-outline-flickr:disabled {
    color: #ff0084;
    background-color: transparent; }
  .btn-outline-flickr:not(:disabled):not(.disabled):active, .btn-outline-flickr:not(:disabled):not(.disabled).active,
  .show > .btn-outline-flickr.dropdown-toggle {
    color: #fff;
    background-color: #ff0084;
    border-color: #ff0084; }
    .btn-outline-flickr:not(:disabled):not(.disabled):active:focus, .btn-outline-flickr:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-flickr.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(255, 0, 132, 0.5); }

.btn-outline-reddit {
  color: #ff4500;
  border-color: #ff4500; }
  .btn-outline-reddit:hover {
    color: #fff;
    background-color: #ff4500;
    border-color: #ff4500; }
  .btn-outline-reddit:focus, .btn-outline-reddit.focus {
    box-shadow: 0 0 0 0 rgba(255, 69, 0, 0.5); }
  .btn-outline-reddit.disabled, .btn-outline-reddit:disabled {
    color: #ff4500;
    background-color: transparent; }
  .btn-outline-reddit:not(:disabled):not(.disabled):active, .btn-outline-reddit:not(:disabled):not(.disabled).active,
  .show > .btn-outline-reddit.dropdown-toggle {
    color: #fff;
    background-color: #ff4500;
    border-color: #ff4500; }
    .btn-outline-reddit:not(:disabled):not(.disabled):active:focus, .btn-outline-reddit:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-reddit.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(255, 69, 0, 0.5); }

.btn-outline-dribbble {
  color: #ea4c89;
  border-color: #ea4c89; }
  .btn-outline-dribbble:hover {
    color: #fff;
    background-color: #ea4c89;
    border-color: #ea4c89; }
  .btn-outline-dribbble:focus, .btn-outline-dribbble.focus {
    box-shadow: 0 0 0 0 rgba(234, 76, 137, 0.5); }
  .btn-outline-dribbble.disabled, .btn-outline-dribbble:disabled {
    color: #ea4c89;
    background-color: transparent; }
  .btn-outline-dribbble:not(:disabled):not(.disabled):active, .btn-outline-dribbble:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dribbble.dropdown-toggle {
    color: #fff;
    background-color: #ea4c89;
    border-color: #ea4c89; }
    .btn-outline-dribbble:not(:disabled):not(.disabled):active:focus, .btn-outline-dribbble:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dribbble.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(234, 76, 137, 0.5); }

.btn-outline-skype {
  color: #00aff0;
  border-color: #00aff0; }
  .btn-outline-skype:hover {
    color: #fff;
    background-color: #00aff0;
    border-color: #00aff0; }
  .btn-outline-skype:focus, .btn-outline-skype.focus {
    box-shadow: 0 0 0 0 rgba(0, 175, 240, 0.5); }
  .btn-outline-skype.disabled, .btn-outline-skype:disabled {
    color: #00aff0;
    background-color: transparent; }
  .btn-outline-skype:not(:disabled):not(.disabled):active, .btn-outline-skype:not(:disabled):not(.disabled).active,
  .show > .btn-outline-skype.dropdown-toggle {
    color: #fff;
    background-color: #00aff0;
    border-color: #00aff0; }
    .btn-outline-skype:not(:disabled):not(.disabled):active:focus, .btn-outline-skype:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-skype.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(0, 175, 240, 0.5); }

.btn-outline-instagram {
  color: #517fa4;
  border-color: #517fa4; }
  .btn-outline-instagram:hover {
    color: #fff;
    background-color: #517fa4;
    border-color: #517fa4; }
  .btn-outline-instagram:focus, .btn-outline-instagram.focus {
    box-shadow: 0 0 0 0 rgba(81, 127, 164, 0.5); }
  .btn-outline-instagram.disabled, .btn-outline-instagram:disabled {
    color: #517fa4;
    background-color: transparent; }
  .btn-outline-instagram:not(:disabled):not(.disabled):active, .btn-outline-instagram:not(:disabled):not(.disabled).active,
  .show > .btn-outline-instagram.dropdown-toggle {
    color: #fff;
    background-color: #517fa4;
    border-color: #517fa4; }
    .btn-outline-instagram:not(:disabled):not(.disabled):active:focus, .btn-outline-instagram:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-instagram.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(81, 127, 164, 0.5); }

.btn-outline-behance {
  color: #1769ff;
  border-color: #1769ff; }
  .btn-outline-behance:hover {
    color: #fff;
    background-color: #1769ff;
    border-color: #1769ff; }
  .btn-outline-behance:focus, .btn-outline-behance.focus {
    box-shadow: 0 0 0 0 rgba(23, 105, 255, 0.5); }
  .btn-outline-behance.disabled, .btn-outline-behance:disabled {
    color: #1769ff;
    background-color: transparent; }
  .btn-outline-behance:not(:disabled):not(.disabled):active, .btn-outline-behance:not(:disabled):not(.disabled).active,
  .show > .btn-outline-behance.dropdown-toggle {
    color: #fff;
    background-color: #1769ff;
    border-color: #1769ff; }
    .btn-outline-behance:not(:disabled):not(.disabled):active:focus, .btn-outline-behance:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-behance.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(23, 105, 255, 0.5); }

.btn-outline-rss {
  color: #f26522;
  border-color: #f26522; }
  .btn-outline-rss:hover {
    color: #fff;
    background-color: #f26522;
    border-color: #f26522; }
  .btn-outline-rss:focus, .btn-outline-rss.focus {
    box-shadow: 0 0 0 0 rgba(242, 101, 34, 0.5); }
  .btn-outline-rss.disabled, .btn-outline-rss:disabled {
    color: #f26522;
    background-color: transparent; }
  .btn-outline-rss:not(:disabled):not(.disabled):active, .btn-outline-rss:not(:disabled):not(.disabled).active,
  .show > .btn-outline-rss.dropdown-toggle {
    color: #fff;
    background-color: #f26522;
    border-color: #f26522; }
    .btn-outline-rss:not(:disabled):not(.disabled):active:focus, .btn-outline-rss:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-rss.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(242, 101, 34, 0.5); }

.btn-100 {
  color: #111111;
  background-color: #f8f8f8;
  border-color: #f8f8f8; }
  .btn-100:hover {
    color: #111111;
    background-color: #e5e5e5;
    border-color: #dfdfdf; }
  .btn-100:focus, .btn-100.focus {
    box-shadow: 0 0 0 0 rgba(213, 213, 213, 0.5); }
  .btn-100.disabled, .btn-100:disabled {
    color: #111111;
    background-color: #f8f8f8;
    border-color: #f8f8f8; }
  .btn-100:not(:disabled):not(.disabled):active, .btn-100:not(:disabled):not(.disabled).active,
  .show > .btn-100.dropdown-toggle {
    color: #111111;
    background-color: #dfdfdf;
    border-color: #d8d8d8; }
    .btn-100:not(:disabled):not(.disabled):active:focus, .btn-100:not(:disabled):not(.disabled).active:focus,
    .show > .btn-100.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(213, 213, 213, 0.5); }

.btn-200 {
  color: #111111;
  background-color: #f5f5f5;
  border-color: #f5f5f5; }
  .btn-200:hover {
    color: #111111;
    background-color: #e2e2e2;
    border-color: gainsboro; }
  .btn-200:focus, .btn-200.focus {
    box-shadow: 0 0 0 0 rgba(211, 211, 211, 0.5); }
  .btn-200.disabled, .btn-200:disabled {
    color: #111111;
    background-color: #f5f5f5;
    border-color: #f5f5f5; }
  .btn-200:not(:disabled):not(.disabled):active, .btn-200:not(:disabled):not(.disabled).active,
  .show > .btn-200.dropdown-toggle {
    color: #111111;
    background-color: gainsboro;
    border-color: #d5d5d5; }
    .btn-200:not(:disabled):not(.disabled):active:focus, .btn-200:not(:disabled):not(.disabled).active:focus,
    .show > .btn-200.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(211, 211, 211, 0.5); }

.btn-300 {
  color: #111111;
  background-color: #e5e5e5;
  border-color: #e5e5e5; }
  .btn-300:hover {
    color: #111111;
    background-color: #d2d2d2;
    border-color: #cccccc; }
  .btn-300:focus, .btn-300.focus {
    box-shadow: 0 0 0 0 rgba(197, 197, 197, 0.5); }
  .btn-300.disabled, .btn-300:disabled {
    color: #111111;
    background-color: #e5e5e5;
    border-color: #e5e5e5; }
  .btn-300:not(:disabled):not(.disabled):active, .btn-300:not(:disabled):not(.disabled).active,
  .show > .btn-300.dropdown-toggle {
    color: #111111;
    background-color: #cccccc;
    border-color: #c5c5c5; }
    .btn-300:not(:disabled):not(.disabled):active:focus, .btn-300:not(:disabled):not(.disabled).active:focus,
    .show > .btn-300.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(197, 197, 197, 0.5); }

.btn-400 {
  color: #111111;
  background-color: #ced4da;
  border-color: #ced4da; }
  .btn-400:hover {
    color: #111111;
    background-color: #b8c1ca;
    border-color: #b1bbc4; }
  .btn-400:focus, .btn-400.focus {
    box-shadow: 0 0 0 0 rgba(178, 183, 188, 0.5); }
  .btn-400.disabled, .btn-400:disabled {
    color: #111111;
    background-color: #ced4da;
    border-color: #ced4da; }
  .btn-400:not(:disabled):not(.disabled):active, .btn-400:not(:disabled):not(.disabled).active,
  .show > .btn-400.dropdown-toggle {
    color: #111111;
    background-color: #b1bbc4;
    border-color: #aab4bf; }
    .btn-400:not(:disabled):not(.disabled):active:focus, .btn-400:not(:disabled):not(.disabled).active:focus,
    .show > .btn-400.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(178, 183, 188, 0.5); }

.btn-500 {
  color: #111111;
  background-color: #adb5bd;
  border-color: #adb5bd; }
  .btn-500:hover {
    color: #111111;
    background-color: #98a2ac;
    border-color: #919ca6; }
  .btn-500:focus, .btn-500.focus {
    box-shadow: 0 0 0 0 rgba(150, 156, 163, 0.5); }
  .btn-500.disabled, .btn-500:disabled {
    color: #111111;
    background-color: #adb5bd;
    border-color: #adb5bd; }
  .btn-500:not(:disabled):not(.disabled):active, .btn-500:not(:disabled):not(.disabled).active,
  .show > .btn-500.dropdown-toggle {
    color: #111111;
    background-color: #919ca6;
    border-color: #8a95a1; }
    .btn-500:not(:disabled):not(.disabled):active:focus, .btn-500:not(:disabled):not(.disabled).active:focus,
    .show > .btn-500.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(150, 156, 163, 0.5); }

.btn-600 {
  color: #111111;
  background-color: #999999;
  border-color: #999999; }
  .btn-600:hover {
    color: #fff;
    background-color: #868686;
    border-color: gray; }
  .btn-600:focus, .btn-600.focus {
    box-shadow: 0 0 0 0 rgba(133, 133, 133, 0.5); }
  .btn-600.disabled, .btn-600:disabled {
    color: #111111;
    background-color: #999999;
    border-color: #999999; }
  .btn-600:not(:disabled):not(.disabled):active, .btn-600:not(:disabled):not(.disabled).active,
  .show > .btn-600.dropdown-toggle {
    color: #fff;
    background-color: gray;
    border-color: #797979; }
    .btn-600:not(:disabled):not(.disabled):active:focus, .btn-600:not(:disabled):not(.disabled).active:focus,
    .show > .btn-600.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(133, 133, 133, 0.5); }

.btn-700 {
  color: #fff;
  background-color: #555555;
  border-color: #555555; }
  .btn-700:hover {
    color: #fff;
    background-color: #424242;
    border-color: #3c3c3c; }
  .btn-700:focus, .btn-700.focus {
    box-shadow: 0 0 0 0 rgba(111, 111, 111, 0.5); }
  .btn-700.disabled, .btn-700:disabled {
    color: #fff;
    background-color: #555555;
    border-color: #555555; }
  .btn-700:not(:disabled):not(.disabled):active, .btn-700:not(:disabled):not(.disabled).active,
  .show > .btn-700.dropdown-toggle {
    color: #fff;
    background-color: #3c3c3c;
    border-color: #353535; }
    .btn-700:not(:disabled):not(.disabled):active:focus, .btn-700:not(:disabled):not(.disabled).active:focus,
    .show > .btn-700.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(111, 111, 111, 0.5); }

.btn-800 {
  color: #fff;
  background-color: #151515;
  border-color: #151515; }
  .btn-800:hover {
    color: #fff;
    background-color: #020202;
    border-color: black; }
  .btn-800:focus, .btn-800.focus {
    box-shadow: 0 0 0 0 rgba(56, 56, 56, 0.5); }
  .btn-800.disabled, .btn-800:disabled {
    color: #fff;
    background-color: #151515;
    border-color: #151515; }
  .btn-800:not(:disabled):not(.disabled):active, .btn-800:not(:disabled):not(.disabled).active,
  .show > .btn-800.dropdown-toggle {
    color: #fff;
    background-color: black;
    border-color: black; }
    .btn-800:not(:disabled):not(.disabled):active:focus, .btn-800:not(:disabled):not(.disabled).active:focus,
    .show > .btn-800.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(56, 56, 56, 0.5); }

.btn-900 {
  color: #fff;
  background-color: #111111;
  border-color: #111111; }
  .btn-900:hover {
    color: #fff;
    background-color: black;
    border-color: black; }
  .btn-900:focus, .btn-900.focus {
    box-shadow: 0 0 0 0 rgba(53, 53, 53, 0.5); }
  .btn-900.disabled, .btn-900:disabled {
    color: #fff;
    background-color: #111111;
    border-color: #111111; }
  .btn-900:not(:disabled):not(.disabled):active, .btn-900:not(:disabled):not(.disabled).active,
  .show > .btn-900.dropdown-toggle {
    color: #fff;
    background-color: black;
    border-color: black; }
    .btn-900:not(:disabled):not(.disabled):active:focus, .btn-900:not(:disabled):not(.disabled).active:focus,
    .show > .btn-900.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(53, 53, 53, 0.5); }

.btn-outline-100 {
  color: #f8f8f8;
  border-color: #f8f8f8; }
  .btn-outline-100:hover {
    color: #111111;
    background-color: #f8f8f8;
    border-color: #f8f8f8; }
  .btn-outline-100:focus, .btn-outline-100.focus {
    box-shadow: 0 0 0 0 rgba(248, 248, 248, 0.5); }
  .btn-outline-100.disabled, .btn-outline-100:disabled {
    color: #f8f8f8;
    background-color: transparent; }
  .btn-outline-100:not(:disabled):not(.disabled):active, .btn-outline-100:not(:disabled):not(.disabled).active,
  .show > .btn-outline-100.dropdown-toggle {
    color: #111111;
    background-color: #f8f8f8;
    border-color: #f8f8f8; }
    .btn-outline-100:not(:disabled):not(.disabled):active:focus, .btn-outline-100:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-100.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(248, 248, 248, 0.5); }

.btn-outline-200 {
  color: #f5f5f5;
  border-color: #f5f5f5; }
  .btn-outline-200:hover {
    color: #111111;
    background-color: #f5f5f5;
    border-color: #f5f5f5; }
  .btn-outline-200:focus, .btn-outline-200.focus {
    box-shadow: 0 0 0 0 rgba(245, 245, 245, 0.5); }
  .btn-outline-200.disabled, .btn-outline-200:disabled {
    color: #f5f5f5;
    background-color: transparent; }
  .btn-outline-200:not(:disabled):not(.disabled):active, .btn-outline-200:not(:disabled):not(.disabled).active,
  .show > .btn-outline-200.dropdown-toggle {
    color: #111111;
    background-color: #f5f5f5;
    border-color: #f5f5f5; }
    .btn-outline-200:not(:disabled):not(.disabled):active:focus, .btn-outline-200:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-200.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(245, 245, 245, 0.5); }

.btn-outline-300 {
  color: #e5e5e5;
  border-color: #e5e5e5; }
  .btn-outline-300:hover {
    color: #111111;
    background-color: #e5e5e5;
    border-color: #e5e5e5; }
  .btn-outline-300:focus, .btn-outline-300.focus {
    box-shadow: 0 0 0 0 rgba(229, 229, 229, 0.5); }
  .btn-outline-300.disabled, .btn-outline-300:disabled {
    color: #e5e5e5;
    background-color: transparent; }
  .btn-outline-300:not(:disabled):not(.disabled):active, .btn-outline-300:not(:disabled):not(.disabled).active,
  .show > .btn-outline-300.dropdown-toggle {
    color: #111111;
    background-color: #e5e5e5;
    border-color: #e5e5e5; }
    .btn-outline-300:not(:disabled):not(.disabled):active:focus, .btn-outline-300:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-300.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(229, 229, 229, 0.5); }

.btn-outline-400 {
  color: #ced4da;
  border-color: #ced4da; }
  .btn-outline-400:hover {
    color: #111111;
    background-color: #ced4da;
    border-color: #ced4da; }
  .btn-outline-400:focus, .btn-outline-400.focus {
    box-shadow: 0 0 0 0 rgba(206, 212, 218, 0.5); }
  .btn-outline-400.disabled, .btn-outline-400:disabled {
    color: #ced4da;
    background-color: transparent; }
  .btn-outline-400:not(:disabled):not(.disabled):active, .btn-outline-400:not(:disabled):not(.disabled).active,
  .show > .btn-outline-400.dropdown-toggle {
    color: #111111;
    background-color: #ced4da;
    border-color: #ced4da; }
    .btn-outline-400:not(:disabled):not(.disabled):active:focus, .btn-outline-400:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-400.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(206, 212, 218, 0.5); }

.btn-outline-500 {
  color: #adb5bd;
  border-color: #adb5bd; }
  .btn-outline-500:hover {
    color: #111111;
    background-color: #adb5bd;
    border-color: #adb5bd; }
  .btn-outline-500:focus, .btn-outline-500.focus {
    box-shadow: 0 0 0 0 rgba(173, 181, 189, 0.5); }
  .btn-outline-500.disabled, .btn-outline-500:disabled {
    color: #adb5bd;
    background-color: transparent; }
  .btn-outline-500:not(:disabled):not(.disabled):active, .btn-outline-500:not(:disabled):not(.disabled).active,
  .show > .btn-outline-500.dropdown-toggle {
    color: #111111;
    background-color: #adb5bd;
    border-color: #adb5bd; }
    .btn-outline-500:not(:disabled):not(.disabled):active:focus, .btn-outline-500:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-500.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(173, 181, 189, 0.5); }

.btn-outline-600 {
  color: #999999;
  border-color: #999999; }
  .btn-outline-600:hover {
    color: #111111;
    background-color: #999999;
    border-color: #999999; }
  .btn-outline-600:focus, .btn-outline-600.focus {
    box-shadow: 0 0 0 0 rgba(153, 153, 153, 0.5); }
  .btn-outline-600.disabled, .btn-outline-600:disabled {
    color: #999999;
    background-color: transparent; }
  .btn-outline-600:not(:disabled):not(.disabled):active, .btn-outline-600:not(:disabled):not(.disabled).active,
  .show > .btn-outline-600.dropdown-toggle {
    color: #111111;
    background-color: #999999;
    border-color: #999999; }
    .btn-outline-600:not(:disabled):not(.disabled):active:focus, .btn-outline-600:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-600.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(153, 153, 153, 0.5); }

.btn-outline-700 {
  color: #555555;
  border-color: #555555; }
  .btn-outline-700:hover {
    color: #fff;
    background-color: #555555;
    border-color: #555555; }
  .btn-outline-700:focus, .btn-outline-700.focus {
    box-shadow: 0 0 0 0 rgba(85, 85, 85, 0.5); }
  .btn-outline-700.disabled, .btn-outline-700:disabled {
    color: #555555;
    background-color: transparent; }
  .btn-outline-700:not(:disabled):not(.disabled):active, .btn-outline-700:not(:disabled):not(.disabled).active,
  .show > .btn-outline-700.dropdown-toggle {
    color: #fff;
    background-color: #555555;
    border-color: #555555; }
    .btn-outline-700:not(:disabled):not(.disabled):active:focus, .btn-outline-700:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-700.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(85, 85, 85, 0.5); }

.btn-outline-800 {
  color: #151515;
  border-color: #151515; }
  .btn-outline-800:hover {
    color: #fff;
    background-color: #151515;
    border-color: #151515; }
  .btn-outline-800:focus, .btn-outline-800.focus {
    box-shadow: 0 0 0 0 rgba(21, 21, 21, 0.5); }
  .btn-outline-800.disabled, .btn-outline-800:disabled {
    color: #151515;
    background-color: transparent; }
  .btn-outline-800:not(:disabled):not(.disabled):active, .btn-outline-800:not(:disabled):not(.disabled).active,
  .show > .btn-outline-800.dropdown-toggle {
    color: #fff;
    background-color: #151515;
    border-color: #151515; }
    .btn-outline-800:not(:disabled):not(.disabled):active:focus, .btn-outline-800:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-800.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(21, 21, 21, 0.5); }

.btn-outline-900 {
  color: #111111;
  border-color: #111111; }
  .btn-outline-900:hover {
    color: #fff;
    background-color: #111111;
    border-color: #111111; }
  .btn-outline-900:focus, .btn-outline-900.focus {
    box-shadow: 0 0 0 0 rgba(17, 17, 17, 0.5); }
  .btn-outline-900.disabled, .btn-outline-900:disabled {
    color: #111111;
    background-color: transparent; }
  .btn-outline-900:not(:disabled):not(.disabled):active, .btn-outline-900:not(:disabled):not(.disabled).active,
  .show > .btn-outline-900.dropdown-toggle {
    color: #fff;
    background-color: #111111;
    border-color: #111111; }
    .btn-outline-900:not(:disabled):not(.disabled):active:focus, .btn-outline-900:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-900.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(17, 17, 17, 0.5); }

.owl-carousel[data-dots="true"] {
  margin-bottom: 60px; }

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  position: absolute;
  border-radius: 50%;
  top: 50%;
  width: 4rem;
  height: 4rem;
  text-align: center;
  z-index: 20; }
  .owl-carousel .owl-nav button.owl-prev::before,
  .owl-carousel .owl-nav button.owl-next::before {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    line-height: 4rem;
    font-family: 'icomoon';
    font-size: 1.5rem;
    color: #000; }
  .owl-carousel .owl-nav button.owl-prev span,
  .owl-carousel .owl-nav button.owl-next span {
    display: none; }
  .owl-carousel .owl-nav button.owl-prev:hover,
  .owl-carousel .owl-nav button.owl-next:hover {
    border-color: #064CDB; }
    .owl-carousel .owl-nav button.owl-prev:hover::before,
    .owl-carousel .owl-nav button.owl-next:hover::before {
      color: #064CDB; }

.owl-carousel .owl-nav .owl-prev {
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  transform: translate(0, -50%);
  left: -2rem; }
  .owl-carousel .owl-nav .owl-prev::before {
    content: "\ebfb"; }
  .owl-carousel .owl-nav .owl-prev[class*="disabled"] {
    -webkit-transform: translate(-100%, -50%);
    -moz-transform: translate(-100%, -50%);
    transform: translate(-100%, -50%);
    opacity: 0;
    visibility: hidden;
    z-index: 10; }

.owl-carousel .owl-nav .owl-next {
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  transform: translate(0, -50%);
  right: -2rem; }
  .owl-carousel .owl-nav .owl-next::before {
    content: "\ebfc"; }
  .owl-carousel .owl-nav .owl-next[class*="disabled"] {
    -webkit-transform: translate(100%, -50%);
    -moz-transform: translate(100%, -50%);
    transform: translate(100%, -50%);
    opacity: 0;
    visibility: hidden;
    z-index: 10; }

.owl-dots {
  -webkit-transform: translate(0, 50%);
  -moz-transform: translate(0, 50%);
  transform: translate(0, 50%);
  position: absolute;
  bottom: -30px;
  width: 100%;
  text-align: center; }
  .owl-dots.disabled {
    display: none; }
  .owl-dots .owl-dot {
    display: inline-block;
    vertical-align: top; }
    .owl-dots .owl-dot span {
      display: block;
      width: 8px;
      height: 8px;
      margin: 10px;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.2);
      -webkit-transition: all 0.2s;
      -moz-transition: all 0.2s;
      transition: all 0.2s;
      -webkit-transition-delay: 0s;
      -moz-transition-delay: 0s;
      transition-delay: 0s; }
    .owl-dots .owl-dot:hover span {
      background: rgba(0, 0, 0, 0.4); }
    .owl-dots .owl-dot.active span {
      background: #000; }

.owl-carousel-library .owl-stage-outer {
  padding-top: 20px;
  padding-bottom: 20px; }

.owl-carousel-library .owl-nav {
  text-align: center; }
  .owl-carousel-library .owl-nav button.owl-prev,
  .owl-carousel-library .owl-nav button.owl-next {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    transform: translate(0, 0);
    position: static; }
    .owl-carousel-library .owl-nav button.owl-prev:last-child,
    .owl-carousel-library .owl-nav button.owl-next:last-child {
      margin-left: .5rem; }
  .owl-carousel-library .owl-nav .owl-prev[class*="disabled"],
  .owl-carousel-library .owl-nav .owl-next[class*="disabled"] {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    opacity: 1;
    visibility: visible;
    border-color: transparent;
    background: transparent;
    z-index: 10; }
    .owl-carousel-library .owl-nav .owl-prev[class*="disabled"]::before,
    .owl-carousel-library .owl-nav .owl-next[class*="disabled"]::before {
      color: #e5e5e5; }
    .owl-carousel-library .owl-nav .owl-prev[class*="disabled"]:hover,
    .owl-carousel-library .owl-nav .owl-next[class*="disabled"]:hover {
      border-color: transparent; }
      .owl-carousel-library .owl-nav .owl-prev[class*="disabled"]:hover::before,
      .owl-carousel-library .owl-nav .owl-next[class*="disabled"]:hover::before {
        color: #e5e5e5; }

.owl-carousel-fluid .owl-stage-outer {
  overflow: visible; }

.owl-carousel-fluid:hover .owl-nav .owl-prev {
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  transform: translate(0, -50%);
  left: -15px; }

.owl-carousel-fluid:hover .owl-nav .owl-next {
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  transform: translate(0, -50%);
  right: -15px; }

.owl-carousel-fluid .owl-nav button.owl-prev,
.owl-carousel-fluid .owl-nav button.owl-next {
  background: rgba(0, 0, 0, 0.25);
  border-radius: 0;
  border-color: transparent !important;
  width: 2.75rem;
  height: 5.5rem; }
  .owl-carousel-fluid .owl-nav button.owl-prev::before,
  .owl-carousel-fluid .owl-nav button.owl-next::before {
    line-height: 5.5rem;
    color: #fff !important; }
  .owl-carousel-fluid .owl-nav button.owl-prev:hover,
  .owl-carousel-fluid .owl-nav button.owl-next:hover {
    width: 5.5rem;
    background: rgba(0, 0, 0, 0.5); }

.owl-carousel-fluid .owl-nav .owl-prev {
  -webkit-transform: translate(-100%, -50%);
  -moz-transform: translate(-100%, -50%);
  transform: translate(-100%, -50%);
  left: -15px;
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.owl-carousel-fluid .owl-nav .owl-next {
  -webkit-transform: translate(100%, -50%);
  -moz-transform: translate(100%, -50%);
  transform: translate(100%, -50%);
  right: -15px;
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.owl-carousel .owl-stage-outer .owl-stage .owl-item {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  counter-increment: owlItem; }
  .owl-carousel .owl-stage-outer .owl-stage .owl-item img {
    width: auto;
    max-width: 100%; }

.owl-carousel.visible .owl-stage-outer {
  overflow: visible; }

.owl-carousel.accent .owl-item {
  opacity: .25; }
  .owl-carousel.accent .owl-item.active {
    opacity: 1; }

.section-tabs {
  margin-top: -6.25rem;
  margin-bottom: 3.125rem;
  border-bottom: 1px solid #e5e5e5; }
  .section-tabs .nav {
    margin-bottom: -1px; }
  .section-tabs .nav-item a {
    padding: 30px 60px; }

.user {
  display: inline-block;
  position: relative; }
  .user.status::after {
    content: "";
    position: absolute;
    right: 1px;
    bottom: 1px;
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #f5f5f5; }
  .user.status.online::after {
    background: #28a745; }

.avatar {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 50%;
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: 700; }
  .avatar > i {
    font-size: 1.125rem; }
  .avatar[class*="-sm"] {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 0.875rem; }
    .avatar[class*="-sm"] > i {
      font-size: 1rem; }
  .avatar[class*="-lg"] {
    width: 5rem;
    height: 5rem;
    font-size: 1.125rem; }
    .avatar[class*="-lg"] > i {
      font-size: 1.375rem; }

.error-type {
  display: inline-block;
  margin-bottom: 0;
  font-size: 15rem;
  font-weight: 700;
  line-height: .8em; }
  @media (max-width: 991.98px) {
    .error-type {
      font-size: 10rem; } }
  @media (max-width: 767.98px) {
    .error-type {
      font-size: 8rem; } }

.error-text {
  display: inline-block; }

.topic {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  position: relative;
  display: block;
  padding: 20px 30px 20px 60px;
  color: #222; }
  .topic:hover {
    color: inherit;
    background: #f8f8f8; }
  .topic:not(.topic-vertical):hover .topic-label {
    -webkit-transform: scale(1.5);
    -moz-transform: scale(1.5);
    transform: scale(1.5); }
  .topic + .topic {
    margin-top: .5rem; }
  .topic > .row {
    min-height: 2.5rem; }

.topic-label {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  position: absolute;
  top: 50%;
  left: 30px;
  display: block;
  width: 10px;
  height: 10px;
  margin-top: -5px;
  border-radius: 50%;
  background: #f8f8f8; }

.topic-title {
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  font-weight: 400; }

.topic-vertical {
  padding: 30px 30px 30px 38px; }
  .topic-vertical .topic-title {
    margin-bottom: 30px;
    font-size: 1.125rem; }
  .topic-vertical .topic-label {
    top: 4px;
    bottom: 4px;
    left: 4px;
    width: 8px;
    height: auto;
    margin-top: 0;
    border-radius: 0.25rem; }

.card .topic {
  margin-right: -30px;
  margin-left: -30px;
  padding-left: 30px; }
  .card .topic + .topic {
    margin-top: 0;
    border-top: 1px solid #e5e5e5; }

.activity {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  position: relative;
  display: block;
  color: #555555;
  margin-left: -30px;
  margin-right: -30px;
  padding: 15px 30px; }
  .activity + .activity {
    border-top: 1px solid #e5e5e5; }
  .activity:hover {
    color: inherit;
    background: #f8f8f8; }
  .activity > .row {
    min-height: 3.125rem; }

.activity-label {
  display: block;
  width: .625rem;
  height: .625rem;
  border-radius: 50%; }

.interpost {
  font-size: 1.125rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 400; }
  .interpost a {
    position: relative;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    transition-delay: 0s;
    display: block;
    color: #fff;
    padding: 3.125rem 0; }
    .interpost a:hover {
      background: rgba(255, 255, 255, 0.1); }
      .interpost a:hover::before {
        background: rgba(255, 255, 255, 0.1); }
    .interpost a::before {
      -webkit-transition: all 0.2s;
      -moz-transition: all 0.2s;
      transition: all 0.2s;
      -webkit-transition-delay: 0s;
      -moz-transition-delay: 0s;
      transition-delay: 0s;
      content: "";
      position: absolute;
      top: 0;
      display: block;
      width: 50vw;
      height: 100%; }
  .interpost[class*="-prev"] {
    border-right: 1px solid rgba(255, 255, 255, 0.1); }
    .interpost[class*="-prev"] a {
      padding-left: 60px;
      padding-right: 30px; }
      .interpost[class*="-prev"] a::before {
        right: 100%; }
      .interpost[class*="-prev"] a::after {
        position: absolute;
        left: 0;
        content: "\ebdc";
        width: 1em;
        line-height: 1em;
        top: 50%;
        font-family: 'icomoon' !important;
        font-size: 1.5rem;
        margin-top: -0.5em; }
  .interpost[class*="-next"] a {
    padding-left: 30px;
    padding-right: 60px; }
    .interpost[class*="-next"] a::before {
      left: 100%; }
    .interpost[class*="-next"] a::after {
      position: absolute;
      right: 0;
      content: "\ebde";
      width: 1em;
      line-height: 1em;
      top: 50%;
      font-family: 'icomoon' !important;
      font-size: 1.5rem;
      margin-top: -0.5em; }

.tags {
  margin: -.25rem;
  overflow: hidden; }
  .tags a {
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    transition-delay: 0s;
    -webkit-box-shadow: 0px 0px 0 1px #e5e5e5;
    -moz-box-shadow: 0px 0px 0 1px #e5e5e5;
    box-shadow: 0px 0px 0 1px #e5e5e5;
    float: left;
    display: block;
    margin: .25rem;
    padding: .5rem .75rem;
    font-size: 0.875rem;
    color: #555555;
    border-radius: 0.25rem; }
    .tags a:hover {
      border: 0; }

.number {
  text-align: center; }
  .number span {
    font-size: 3rem;
    text-transform: uppercase;
    color: #000; }
  .number p {
    font-size: 0.875rem; }
  @media (max-width: 767.98px) {
    .number {
      padding-top: 30px;
      padding-bottom: 30px; } }

.type > * {
  padding: 30px; }
  @media (max-width: 767.98px) {
    .type > * {
      padding: 20px; } }

.type .badge {
  position: absolute;
  top: 20px;
  right: -20px; }

.type-title {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  color: #555555; }

.type-example {
  position: absolute;
  left: 30px;
  bottom: 30px; }
  @media (max-width: 767.98px) {
    .type-example {
      left: 20px;
      bottom: 20px; } }
  .type-example span {
    font-size: 3.125rem;
    line-height: 1em;
    color: #000; }

.color {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 10rem; }
  .color .color-example {
    width: 80px;
    height: 80px;
    margin-right: 20px;
    border-radius: 50%; }
  .color .color-info {
    flex: 1; }
    .color .color-info span {
      font-size: 0.875rem;
      color: #999999;
      text-transform: uppercase; }
  .color .color-title {
    font-family: "Open Sans", sans-serif;
    font-size: 1.125rem;
    font-weight: 500;
    margin-bottom: 0; }

.page-meta-stat {
  display: flex;
  justify-content: center;
  align-items: center; }
  .page-meta-stat b {
    color: #fff;
    margin-right: .625rem;
    font-weight: 700; }
  .page-meta-stat span {
    font-size: 0.875rem;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    color: rgba(255, 255, 255, 0.75); }

.file-tree {
  margin-bottom: 0;
  padding-left: 20px;
  list-style: none;
  overflow: hidden; }
  .file-tree ul {
    list-style: none;
    display: none;
    padding-left: 30px; }
  .file-tree li {
    position: relative;
    padding: 5px 0;
    border-radius: 0.25rem;
    cursor: auto;
    white-space: nowrap;
    font-size: 0.875rem;
    font-weight: 600; }
    .file-tree li::before {
      content: "\f15b";
      display: inline-block;
      text-align: center;
      width: 30px;
      margin-right: .25rem;
      vertical-align: middle;
      font-family: 'icomoon' !important;
      font-size: 1rem;
      color: #999999; }
    .file-tree li span {
      color: #999999;
      font-weight: 400;
      font-size: .75rem; }
  .file-tree .file-tree-folder {
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    transition-delay: 0s;
    cursor: pointer; }
    .file-tree .file-tree-folder::before {
      content: "\f07b";
      font-size: 1.25rem; }
    .file-tree .file-tree-folder.open::after {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      transform: rotate(180deg); }
    .file-tree .file-tree-folder.open > ul {
      display: block; }
    .file-tree .file-tree-folder::after {
      -webkit-transition: all 0.2s;
      -moz-transition: all 0.2s;
      transition: all 0.2s;
      -webkit-transition-delay: 0s;
      -moz-transition-delay: 0s;
      transition-delay: 0s;
      position: absolute;
      top: .8rem;
      left: -1em;
      content: "\ebfa";
      display: block;
      text-align: center;
      width: 1em;
      line-height: 1em;
      font-family: 'icomoon' !important;
      font-size: 1rem;
      color: #999999; }
    .file-tree .file-tree-folder.open::before {
      content: "\f07c"; }
    .file-tree .file-tree-folder.empty::after {
      display: none; }

.toc .nav-link {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  position: relative;
  width: 100%;
  color: #999999;
  font-size: 0.875rem;
  border-left: 2px solid transparent;
  padding: 0.5rem 30px; }
  .toc .nav-link:hover {
    color: #000;
    border-color: #adb5bd; }
  .toc .nav-link.active {
    color: #064CDB;
    border-color: #064CDB; }
    .toc .nav-link.active + ul {
      display: block; }

.toc ul {
  border-left: 2px solid #064CDB;
  display: none;
  padding-left: 30px; }
  .toc ul .nav-link {
    font-size: 0.875rem;
    border-color: #e5e5e5; }

.step-list {
  list-style: none;
  margin-top: 40px;
  margin-bottom: 40px;
  padding-left: 55px; }
  .step-list li {
    position: relative;
    min-height: 40px;
    counter-increment: item; }
    .step-list li:not(:first-child) {
      margin-top: 20px; }
    .step-list li::before {
      -webkit-transform: translate(0, -50%);
      -moz-transform: translate(0, -50%);
      transform: translate(0, -50%);
      position: absolute;
      top: .9em;
      left: -55px;
      content: counter(item);
      color: #fff;
      font-weight: 600;
      line-height: 36px;
      text-align: center;
      display: block;
      width: 40px;
      height: 40px;
      background: #999999;
      border-radius: 50%;
      border: 2px solid #999999; }
    .step-list li li::before {
      color: #999999;
      background: transparent;
      border-color: #999999; }
    .step-list li li:not(:first-child) {
      margin-top: .5rem; }
  .step-list ul, .step-list ol {
    padding-left: 45px;
    margin-top: 20px;
    margin-bottom: 20px;
    list-style: none;
    counter-reset: item; }

.figure {
  position: relative; }
  .figure .figure-img {
    margin-bottom: 0; }
  .figure .figure-caption {
    -webkit-transform: translate(0, 50%);
    -moz-transform: translate(0, 50%);
    transform: translate(0, 50%);
    position: absolute;
    left: 15px;
    bottom: 0;
    max-width: 90%;
    padding: 5px 15px;
    font-size: 0.875rem; }
    .figure .figure-caption.text-right {
      left: auto;
      right: 15px; }

.cta {
  position: relative;
  overflow: hidden; }
  .cta .icon-background {
    top: -2.5%;
    right: -2.5%;
    opacity: .25;
    z-index: 5; }

.log-list {
  list-style: none;
  padding: 0; }

.log-info {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  position: relative;
  display: inline-block;
  border: 1px solid #e5e5e5;
  border-radius: 0.25rem;
  background: #f8f8f8;
  margin-bottom: 30px;
  padding: 15px 30px; }
  .log-info time {
    display: inline-block;
    vertical-align: middle; }
  .log-info .badge {
    margin-right: -45px;
    margin-left: 20px; }

.log {
  position: relative;
  text-align: center; }
  .log::before {
    position: absolute;
    content: "";
    display: block;
    width: 1px;
    height: 100%;
    left: 50%;
    background: #e5e5e5;
    z-index: -1; }
  .log:not(:first-child) {
    margin-top: 30px; }
    .log:not(:first-child)::before {
      height: calc(100% + $grid-gutter-width);
      top: -30px; }
  .log .accordion {
    text-align: left; }

code[class*="language-"],
pre[class*="language-"] {
  font-family: "Roboto Mono", monospace;
  text-shadow: none; }

div.code-toolbar:hover .toolbar {
  opacity: 1; }

div.code-toolbar > .toolbar {
  opacity: 0;
  top: 0;
  right: 0; }
  div.code-toolbar > .toolbar .toolbar-item {
    display: block; }
  div.code-toolbar > .toolbar a {
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    transition-delay: 0s;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    border-radius: 0.25rem;
    background: #064CDB;
    color: #fff;
    display: block;
    margin: 10px -10px 0 0;
    padding: .5rem 1rem;
    font-size: .75rem;
    text-transform: uppercase;
    font-weight: 600; }
    div.code-toolbar > .toolbar a:hover {
      color: #fff;
      background: #053fb6; }

pre[class*="language-"] {
  border-radius: 0.25rem;
  margin: 0 0 1rem 0;
  padding: 30px; }

.text-white pre[class*="language-"] {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  background: rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.1); }

.card pre[class*="language-"] {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  padding: 0;
  border: 0; }

.component > .tab-content {
  margin-top: 20px; }
  .component > .tab-content > .tab-pane:last-child pre[class*="language-"] {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    background: #e5e5e5;
    max-height: 50vh; }

.component-info {
  margin-top: 20px;
  background: #e5e5e5;
  border-radius: 0.25rem;
  overflow: hidden; }
  .component-info p {
    padding: 30px;
    font-size: 0.875rem; }
  .component-info table {
    margin: 0; }

.map > [class*="container"] {
  position: static;
  visibility: hidden; }
  .map > [class*="container"] [class*="col"] {
    visibility: visible;
    position: relative;
    z-index: 20; }

.map-area {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10; }

.map-box {
  position: relative; }

@media (max-width: 991.98px) {
  .map {
    padding-bottom: 50vh; } }

.video-js {
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  left: 0;
  top: 0;
  z-index: 50;
  background: transparent; }
  .video-js .vjs-poster {
    border-radius: 0.25rem; }
  .video-js iframe {
    border-radius: 0.25rem; }

.video-3-4 {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 42.6%; }

.video-16-9 {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; }

.youtube .vjs-control-bar {
  display: none; }

.video-js.vjs-waiting .vjs-big-play-button {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transform: scale(2);
  -moz-transform: scale(2);
  transform: scale(2);
  opacity: 0; }

.video-js .vjs-big-play-button {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  top: 50%;
  left: 50%;
  background: #fff;
  opacity: 1;
  width: 5rem;
  height: 5rem;
  margin-top: -2.5rem;
  margin-left: -2.5rem;
  border-radius: 50%;
  border: 0; }
  .video-js .vjs-big-play-button .vjs-icon-placeholder:before {
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    transition-delay: 0s;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    color: #000; }

.video-js:hover .vjs-big-play-button {
  background: #064CDB; }
  .video-js:hover .vjs-big-play-button .vjs-icon-placeholder:before {
    color: #fff; }

.doc-sidebar {
  padding: 30px;
  border-right: 1px solid #e5e5e5; }

.doc-content-header {
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 30px;
  padding: 15px;
  border-bottom: 1px solid #e5e5e5; }
  @media (min-width: 992px) {
    .doc-content-header {
      margin-bottom: 0;
      padding: 30px 3.125rem; } }
  @media (max-width: 991.98px) {
    .doc-content-header {
      border-top: 1px solid #e5e5e5;
      background: #fff; } }
  .doc-content-header .breadcrumb {
    margin: 0;
    padding: 0;
    border: 0; }

.doc-content-body {
  margin-top: 0 !important; }
  .doc-content-body > article {
    padding: 15px; }
  .doc-content-body > aside {
    padding: 15px;
    border-left: 1px solid #e5e5e5; }
  @media (min-width: 992px) {
    .doc-content-body > article {
      padding: 3.125rem; }
    .doc-content-body > aside {
      padding: 3.125rem 30px; } }
  .doc-content-body .toc {
    margin-left: -30px;
    margin-right: -30px; }

/*# sourceMappingURL=style.css.map */
